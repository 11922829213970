import * as React from 'react';
import PropTypes from 'prop-types';
import {useCacheStore} from "../../../contexts";
import {Autocomplete, Box, TextField} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Kdb from "../../Kbd/Kdb";
import {useEffect, useState} from "react";

function AutocompleteVersion(
    {
        selectedLogoId = 1,
        removeBrandSelection,
        setSelectedLogoVersion,
        brands,
        inputRef,
        filterOptions,
    }
) {
    const [options, setOptions] = useState([])

    useEffect(_ => {
        const selectedLogoOpt = brands.find(el => parseInt(el.id) === parseInt(selectedLogoId)) ?? {logoVersions: []}
        const labels = selectedLogoOpt.logoVersions.filter(
            logo => {
                if (filterOptions.length === 0) {
                    return true;
                } else {
                    return filterOptions.includes(logo.id)
                }
            }
        ).map((el, index) => {
            return {...el, index: index + 1}
        })
        labels.unshift({
            id: -1,
            index: 0,
            iconUrl: '',
        })
        setOptions(labels)
    }, [brands])


    return (
        <Autocomplete
            disablePortal
            openOnFocus
            options={options}
            onChange={(event, newValue) => {
                if (newValue.id === -1) removeBrandSelection()
                else setSelectedLogoVersion(newValue);
            }}
            getOptionLabel={
                (option) => {
                    return String(option.index)
                }}
            fullWidth={true}
            renderOption={
                (props, option) => (
                    <Box
                        component="li"
                        sx={{
                            textAlign: 'center',

                        }}
                        {...props}
                    >
                        <Kdb>
                            {option.index}
                        </Kdb>
                        {
                            option.index === 0
                                ?
                                <Box
                                    sx={{
                                        ml: 2,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Back to brand selection...
                                </Box>
                                :
                                <Box
                                    sx={{
                                        background: `url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
                                        ml: 2,
                                        p: 1,
                                        '& > img': {
                                            width: '25px',
                                        }
                                    }}
                                >
                                    <img src={option.iconUrl} alt=""/>
                                </Box>
                        }


                    </Box>
                )
            }
            renderInput={(params) =>
                <Box>
                    <TextField
                        {...params}
                        autoFocus={true}
                        className="class-selector-input"
                        variant="filled"
                        size="small"
                        hiddenLabel
                        placeholder={'Select Logo Version'}
                        inputRef={inputRef}
                    />
                </Box>

            }
        />
    )
}

AutocompleteVersion.propTypes = {}

AutocompleteVersion.defaultProps = {}

export default AutocompleteVersion;
