
import React, {useState, useEffect, createContext, useContext} from 'react';

export const QueueNameContext = createContext({});

export const QueueNameProvider = (props) => {

    const [queueName, setQueueName] = useState('');

    return <QueueNameContext.Provider
        value={
            {
                queueName, setQueueName,
            }
        }
        {...props}
    />;
};


