import React, {useState, useEffect, useRef} from 'react';
import Tooltip from '@mui/material/Tooltip';
import {Box, Skeleton, styled, tooltipClasses} from "@mui/material";
import {
    Stage,
    Layer,
    Shape,
    Image,
} from 'react-konva';
import useImage from "use-image";
import {BrokenImage} from "@mui/icons-material";
import PropTypes from 'prop-types';
import {useUserStore} from "../../../contexts";


const TOOLTIP_DELAY = 500;

const tooltipPosition = (i) => {
    switch (i) {
        case 0:
            return 'right-end';
        case 1:
            return 'bottom';
        case 2:
            return 'left';
        case 3:
            return 'right';
        case 4:
            return 'bottom';
        case 5:
            return 'left';
        case 6:
            return 'right';
        case 7:
            return 'top';
        case 8:
            return "left";
        default:
            return 'bottom';
    }
}

function AnnotationBBimage(
    {
        bgColor = '#f2f2f2',
        boxColor = '#D32F2F',
        boxCoordinates,
        index,
        media,
        openFullImage,
        setPreview,
    }
) {

    const [height, setHeight] = useState(null);
    const [image, status] = useImage(media.mediaAnnotateUrl)
    const [imageHasLoaded, setImageHasLoaded] = useState(false);
    const [scalar, setScalar] = useState(1);
    const [tooltipImgHeight, setTooltipImgHeight] = useState(0)
    const [tooltipImgWidth, setTooltipImgWidth] = useState(500)
    const [width, setWidth] = useState(null);
    const mediaElement = useRef(null);

    const {
        showBB,
    } = useUserStore();

    useEffect(_ => {
        if (
            (width && width !== 0)
            && (height && height !== 0)
        ) {
            if (width <= 500 && height <= 500) {
                setScalar(1);
                setTooltipImgHeight(height)
                setTooltipImgWidth(width);
                return;
            }
            const scalarResult = 500 / width;
            setScalar(scalarResult)
            setTooltipImgHeight(height * scalarResult)
            setTooltipImgWidth(500)
        }
    }, [width, height])

    const OFFSET_OUTLINE = 4;
    const drawLine = (context, shape, withScalar) => {
        const bb_cords = withScalar?
            boxCoordinates.map(cord => cord * scalar)
            : boxCoordinates.map((cord, index) => {
                return cord
                /**
                 * we have 8 coords, that represents the 4 points
                 * of this Box. I wanna remove the offset on the left points
                 * and make it bigger on the right. In this way we can make a bigger
                 * Box
                 */
                if (index <= 1 || index >= 6) {
                    return cord - OFFSET_OUTLINE
                } else {
                    return cord + OFFSET_OUTLINE
                }
            });

        context.beginPath();
        context.lineTo(bb_cords[0], bb_cords[1]);
        context.lineTo(bb_cords[2], bb_cords[3]);
        context.lineTo(bb_cords[4], bb_cords[5]);
        context.lineTo(bb_cords[6], bb_cords[7]);
        context.closePath();
        context.fillStrokeShape(shape);
    }

    let ratio = 1;
    const container = 180;
    const mediaLeft = Math.min(boxCoordinates[0], boxCoordinates[6]);
    const mediaRight = Math.max(boxCoordinates[2], boxCoordinates[4]);
    const originMediaWidth = Math.abs(mediaRight - mediaLeft);

    const mediaTop = Math.min(boxCoordinates[1], boxCoordinates[3]);
    const mediaBottom = Math.max(boxCoordinates[5], boxCoordinates[7]);
    const originMediaHeight = Math.abs(mediaBottom - mediaTop);

    if (originMediaHeight >= container || originMediaWidth >= container) {
        ratio = Math.min(container / originMediaHeight, container / originMediaWidth);
    }

    const stageImgTop = -(Math.min(mediaTop, mediaBottom) * ratio) + 14;
    const stageImgLeft = -(Math.min(mediaLeft, mediaRight) * ratio) + 14;

    return (
        <Tooltip
            arrow
            enterDelay={TOOLTIP_DELAY}
            enterNextDelay={TOOLTIP_DELAY}
            placement={tooltipPosition(index)}
            open={openFullImage === index}
            onOpen={_ => {
                if (setPreview) {
                    setPreview(index)}
                }
            }
            onClose={_ =>{
                if (setPreview) {
                    setPreview(-1)}
                }
            }
            sx={{
                zIndex: 1,
            }}
            title={
                status === 'loaded'
                && !!image
                && imageHasLoaded
                && (tooltipImgWidth > 0 && tooltipImgHeight > 0)
                    ?
                    (
                        <div
                            style={{
                                width: tooltipImgWidth,
                                height: tooltipImgHeight,
                                zIndex: 1,
                            }}
                        >
                            <Stage width={tooltipImgWidth} height={tooltipImgHeight}>
                                <Layer>
                                    <Image
                                        x={0}
                                        y={0}
                                        width={tooltipImgWidth}
                                        height={tooltipImgHeight}
                                        image={image}
                                    />
                                </Layer>
                                {
                                    showBB &&
                                    <>
                                        <Layer>
                                            <Shape
                                                sceneFunc={(context, shape) => drawLine(context, shape, true)}
                                                fill="transparent"
                                                stroke={'#000'}
                                                strokeWidth={6}
                                                shadowBlur={5}
                                            />
                                            <Shape
                                                sceneFunc={(context, shape) => drawLine(context, shape, true)}
                                                fill="transparent"
                                                stroke={boxColor}
                                                strokeWidth={2}
                                                shadowBlur={5}
                                            />
                                        </Layer>
                                    </>
                                }

                            </Stage>
                        </div>
                    ) : (
                        <p>
                            {
                                status === 'loading' ?
                                    "The preview is loading"
                                    : "The image failed to load"
                            }
                        </p>
                    )

            }
        >

            <Box
                sx={{
                    position: 'relative',
                    p: 1,
                    flex: 1,
                    overflow: 'hidden',
                    ...(
                        status === 'loaded'
                            ? {
                                background: bgColor,
                            } : {}
                    )

                }}
            >
                <img
                    className="canvas-stage-img"
                    src={media.mediaAnnotateUrl}
                    alt={media.mediaAnnotateUrl}
                    ref={mediaElement}
                    onLoad={() => {
                        setWidth(mediaElement.current.width);
                        setHeight(mediaElement.current.height);
                        setImageHasLoaded(true);
                    }}
                    width={width}
                    height={height}
                    style={{
                        position: "absolute",
                        transform: `scale(${ratio})`,
                    transformOrigin: 'top left',
                        left: stageImgLeft,
                        top: stageImgTop,
                        display: !imageHasLoaded ? 'none' : 'inline'
                    }}
                />
                {
                    showBB &&
                    <Stage
                        className="canvas-stage-annotations"

                        width={width}
                        height={height}
                        scaleX={1}
                        scaleY={1}
                        style={{
                            position: "absolute",
                            transform: `scale(${ratio})`,
                            transformOrigin: 'top left',
                            left: stageImgLeft,
                            top: stageImgTop,
                        }}
                    >
                        <Layer>
                            <Shape
                                sceneFunc={(context, shape) => drawLine(context, shape, false)}
                                stroke={'#000'}
                                strokeWidth={8}
                            />
                            <Shape
                                sceneFunc={(context, shape) => drawLine(context, shape, false)}
                                stroke={boxColor}
                                strokeWidth={4}
                            />
                        </Layer>
                    </Stage>
                }

                {
                    status === 'loading' &&
                    <Skeleton
                        height={200}
                        variant={'rectangular'}
                        sx={{
                            display: imageHasLoaded ? 'none' : 'block'
                        }}
                    />
                }
                {
                    status === 'failed' &&
                    <Box
                        sx={{
                            display: 'flex',
                            height: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                margin: 'auto',
                            }}
                        >
                            <BrokenImage
                                sx={{
                                    fontSize: '2.5rem',
                                }}
                            />
                            <p>
                                Fail to load the image
                            </p>
                        </Box>

                    </Box>
                }

            </Box>

        </Tooltip>
    )
}

AnnotationBBimage.propTypes = {
    media: PropTypes.object,
    boxCoordinates: PropTypes.array,
    index: PropTypes.number,
    bgColor: PropTypes.string,
    openImageInModal: PropTypes.func,
    boxColor: PropTypes.string,
}


export default AnnotationBBimage;
