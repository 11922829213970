import React, {useState, useEffect, createContext, useContext} from 'react';

import { getMe } from '../DataService';

export const DEFAULTS_CARDS_CONFIG = {
    BB_COLORS: [
        '#FF00FF',
        '#2FD32F'
    ],
    BG_COLORS: [
        `url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
        '#f2f2f2',
        '#565656'
    ]
};


export const UserContext = createContext({});

export const UserProvider = (props) => {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedQueues, setSelectedQueues] = useState([]);
    const [usedQueue, setUsedQueue] = useState([]);
    const [cardBackground, setCardBackground] = useState(DEFAULTS_CARDS_CONFIG.BG_COLORS[0]);
    const [boundingBoxColor, setBoundingBoxColor] = useState(DEFAULTS_CARDS_CONFIG.BB_COLORS[0]);
    const [showBB, setShowBB] = useState(true);


    useEffect(() => {
        if (!localStorage.getItem('usedQueue')) {
            localStorage.setItem('usedQueue', JSON.stringify([]));
        } else {
            setUsedQueue(
                [...new Set(
                    JSON.parse(localStorage.getItem('usedQueue'))
                )]
            );
        }

        getMe()
            .then((userData) => {
                const {
                    permission,
                } = userData;

                setUser(userData);
                if (!!permission) {
                    setIsAdmin(permission.includes('admin'))
                }
            });
    }, []);


    /**********
     * QUEUES *
     *********/

    const addUsedQueues = (queueNames) => {
        localStorage.setItem('usedQueue',
            JSON.stringify(
                [
                    ...new Set([
                        ...usedQueue,
                        ...queueNames
                    ])
                ]
            )
        )
        setUsedQueue(current => [
            ...new Set([
                ...current,
                ...queueNames
            ])
        ])
    }

    const removeUsedQueue = (queueName) => {
        const filteredQueues = usedQueue.filter(el => el !== queueName)
        setUsedQueue(filteredQueues)
    }

    /***
     * CARDS CONFIGURATIONS
     */

    const loopBgColors = () => {
        let color = DEFAULTS_CARDS_CONFIG.BG_COLORS[0];
        if (cardBackground === DEFAULTS_CARDS_CONFIG.BG_COLORS[0]) {
            color = DEFAULTS_CARDS_CONFIG.BG_COLORS[1];
        }
        if (cardBackground === DEFAULTS_CARDS_CONFIG.BG_COLORS[1]) {
            color = DEFAULTS_CARDS_CONFIG.BG_COLORS[2];
        }
        if (cardBackground === DEFAULTS_CARDS_CONFIG.BG_COLORS[2]) {
            color = DEFAULTS_CARDS_CONFIG.BG_COLORS[0];
        }

        setCardBackground(
            color
        );
    }


    return <UserContext.Provider
        value={
            {
                user,
                selectedQueues,
                setSelectedQueues,
                removeUsedQueue,
                usedQueue,
                addUsedQueues,
                isAdmin,
                boundingBoxColor,
                setBoundingBoxColor,
                cardBackground,
                setCardBackground,
                loopBgColors,
                showBB,
                setShowBB
            }
        }
        {...props}
    />;
};

export const useUserStore = () => useContext(UserContext);
