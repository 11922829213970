import React, {useState} from 'react';
import {
    Skeleton,
    Tooltip,
} from '@mui/material';

const DEFAULT_DARK_BG = '#565656';

function AnnoCardInfo(
    {
        detection = {},
        mode,
        bgColor,
    }
) {

    const [imageHasLoaded, setImageHasLoaded] = useState(false);

    const {
        logoVersion,
        visualClassId,
        detectionSettingId,
        visualStruct,
        mediaLabel,
    } = detection;
    let thumbImgUrl = '',
        tooltipFirstRow = '',
        tooltipSecondRow = '',
        tooltipThirdRow = '',
        mediaLabelContent;

    if (mode === 'ocr') {
        return <div className="side preview-side"/>;
    }

    if (!!detection) {
        if (!!logoVersion) {
            const {thumbUrl, brand} = logoVersion;
            thumbImgUrl = thumbUrl ?? ''
            if (brand) {
                const {name, industry} = brand;
                tooltipFirstRow = name ?? '';
                if (industry) {
                    const {description} = industry;
                    tooltipSecondRow = description ?? '';
                }
                if (!!visualClassId && !!detectionSettingId) {
                    tooltipThirdRow = `${visualClassId}, ${detectionSettingId}`;
                }
            }
        } else {
            if (visualStruct) thumbImgUrl = visualStruct.thumbUrl ?? ''
            if (mediaLabel) {
                const {
                    dataset,
                    datasetClassCode,
                    description,
                } = mediaLabel;

                mediaLabelContent = <div>
                    <p className={'infoMedia-par'}>
                        <b>Dataset</b> <br/>
                        {dataset}
                    </p>
                    <p className={'infoMedia-par'}>
                        <b>DatasetClassCode</b> <br/>
                        {datasetClassCode}
                    </p>
                    <p className={'infoMedia-par'}>
                        <b>Description</b> <br/>
                        {description}
                    </p>
                </div>
                tooltipFirstRow = mediaLabel.id ?? '';

            }
            if (detectionSettingId) tooltipSecondRow = detectionSettingId;
        }
    }

    return (
        <Tooltip
            enterDelay={1500}
            enterNextDelay={1500}
            title={
                <div>
                    {tooltipFirstRow.length > 0 && <div>{tooltipFirstRow}</div>}
                    {tooltipSecondRow.length > 0 && <div>{tooltipSecondRow}</div>}
                    {tooltipThirdRow.length > 0 && <div>{tooltipThirdRow}</div>}
                </div>
            }
        >
            <div style={{
                height: "200px",
                ...(imageHasLoaded ?
                    {
                        padding: '5px',
                        background: bgColor,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    } :
                    {})
            }}>
                {
                    !!logoVersion ?

                        <>
                            <Skeleton
                                height={200}
                                animation={'wave'}
                                variant={'rectangular'}
                                sx={{
                                    display: (imageHasLoaded || thumbImgUrl === '') ? 'none' : 'block',
                                }}
                            />
                            <img
                                id={detection.id}
                                src={thumbImgUrl}
                                alt={detection.id}
                                title={detection.id}
                                onLoad={() => {
                                    setImageHasLoaded(true);
                                }}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    visibility: (imageHasLoaded || thumbImgUrl === '') ? "initial" : "hidden"
                                }}
                            />


                        </>
                        :
                        <div
                            style={{
                                color: bgColor === DEFAULT_DARK_BG ? '#fff' : '#000'
                            }}
                        >
                            {mediaLabelContent}
                        </div>
                }


            </div>
        </Tooltip>
    )
}


export default AnnoCardInfo;
