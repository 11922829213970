import * as React from 'react';
import './Kdb.scss';
import {
    Box,
} from '@mui/material'

/**
 * The keyboard key component exists to show which key
 * or combination of keys performs a given action.
 *
 * ## Guideline
 *
 * All shortcuts should do their best to match what appears on the user’s keyboard.
 *
 * - All single letters A-Z are uppercase.
 * - For non-letter keys such as enter, esc and shift, stick to lowercase.
 * - Use the arrow symbol as opposed to spelling things out.
 *
 *
 * ## Credits
 * https://chakra-ui.com/docs/components/kbd/usage
 */
function Kdb(
    {
        children,
        sx = [],
    }
) {
    return (
        <Box
            sx={[
                {
                    display: 'inline',
                },
                // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}

        >
            <span className="kbd">
                {children}
            </span>
        </Box>
    )
}


export default Kdb;
