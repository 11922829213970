import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Box,
    IconButton, DialogContentText,
} from "@mui/material";
import './VideoModal.scss';
import {useRef} from "react";
import IconBrand from "../Svg/IconBrand";
import CloseIcon from '@mui/icons-material/Close';
import posterGif from './loadingPoster.gif'

function VideoModal(
    {
        open = false,
        handleClose,
        clipUrl,
        clipTimestamp,
        AnnotationBBimage,
        poster,
    }
) {
    let videoRef = useRef();

    const videoControls = (action) => {
        switch (action) {
            case 'play':
                return videoRef.current?.play();
            case 'pause':
                return videoRef.current?.pause();
            case 'detection':
                if (videoRef.current) {
                    videoRef.current?.pause();
                    videoRef.current.currentTime = clipTimestamp;

                }
                break;
            case 'reset':
                if (videoRef.current) {
                    videoRef.current.currentTime = 0;
                    videoRef.current?.pause();

                }
                break;
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={false}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <IconButton
                    onClick={(e) => handleClose(e)}
                >
                    <CloseIcon/>
                </IconButton>
            </Box>
            {
                !!clipUrl ?
                    <>
                        <DialogContent
                            sx={{
                                p: 1,
                                display: 'flex'
                            }}
                        >
                            <Box className="player">
                                <video
                                    controls
                                    ref={videoRef}
                                    muted={true}
                                    width={'700px'}
                                    poster={posterGif}
                                    onLoadedMetadata={
                                        () => {
                                            if (videoRef.current) {
                                                videoRef.current.currentTime = clipTimestamp;
                                            }
                                        }
                                    }
                                >
                                    <source src={clipUrl} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </Box>
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr",
                                    height: "200px",
                                    width: '300px',
                                    px: 1,
                                }}
                            >
                                {AnnotationBBimage}
                            </Box>

                        </DialogContent>
                        <DialogActions
                            sx={{
                                justifyContent: 'center',
                                mt: 0,
                                pt: 0,
                            }}
                        >
                            <Button

                                onClick={() => {
                                    return videoControls('detection')
                                }}
                                variant={'contained'}
                                startIcon={
                                    <IconBrand />
                                }
                            >
                                Jump to detection
                            </Button>
                        </DialogActions>

                    </>
                    : <DialogContent>
                    <DialogContentText>
                        No Video content available for this detection
                    </DialogContentText>
                    </DialogContent>
            }

        </Dialog>
    )
}

export default VideoModal;
