import React from 'react';
import { useRouteError } from 'react-router-dom';
import { Error } from '@mui/icons-material';

const ErrorPage = () => {
    const error = useRouteError();

    return (
        <div
            style={{
                background: 'repeating-linear-gradient(0deg,#0E0D0E 25%,#0E0D0E 50%,#171819 50%,#171819 75%)',
                backgroundSize: '10px 10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: "100vh",
            }}
        >
            <div
                style={{
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     justifyContent: 'center',
                 }}
            >
                <div
                    style={{
                        width: '66.666667%'
                    }}
                >
                    <h1 style={{ fontSize: '1.25rem', fontWeight: 500, color: '#1DB8E0', display: 'flex', alignItems: 'center' }}>
                        <Error fontSize="inherit" className="mr-2" />
                        Uh oh...
                    </h1>
                    <h6 style={{ marginTop: '0.75rem', fontSize: '1.5rem', fontWeight: '600', color: '#c85142',}}>
                        Sorry, an unexpected error has occurred.
                    </h6>
                    <p style={{ marginTop: '1rem', color: '#6B7280' }}>
                        <strong>Error: </strong>
                        {' '}
                        {error.statusText || error.message}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
