import * as React from 'react';
import PropTypes from 'prop-types';

function IconBrand(
    {
        fontSize = '1.5rem',
        fill = 'currentColor',
        style,
        stroke = "#E5503F",
    }
) {
    return (
        <svg
            style={{
                userSelect: "none",
                width: "1em",
                height: "1em",
                display: "inline-block",
                fill: fill,
                flexShrink: 0,
                transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                fontSize: fontSize,
                ...style,
            }}
            viewBox="0 0 15 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M6.61399049,12.2158028 L5.00191268,13.0633222 C4.32101197,13.4212929 3.4788403,13.1595064 3.12086961,12.4786057 C2.97832381,12.2074675 2.92913479,11.8969002 2.98091758,11.5949833 L3.28879705,9.79990831 C3.39473168,9.182262 3.18995937,8.55203762 2.74121306,8.11461808 L1.43701471,6.84333889 C0.886154462,6.30638286 0.874882869,5.42453352 1.4118389,4.87367328 C1.62565761,4.65431784 1.90582438,4.51156574 2.20896614,4.46751666 L4.01132394,4.20561874 C4.63147608,4.11550536 5.16757696,3.72600527 5.44491743,3.1640521 L6.25095634,1.53083889 C6.59140669,0.841011121 7.42661213,0.557784769 8.1164399,0.898235125 C8.39113293,1.03380424 8.61347454,1.25614585 8.74904366,1.53083889 L9.55508257,3.1640521 C9.83242304,3.72600527 10.3685239,4.11550536 10.9886761,4.20561874 L12.7910339,4.46751666 C13.552304,4.57813569 14.0797609,5.28494151 13.9691419,6.04621164 C13.9250928,6.34935341 13.7823407,6.62952018 13.5629853,6.84333889 L12.2587869,8.11461808 C11.8100406,8.55203762 11.6052683,9.182262 11.711203,9.79990831 L12.0190824,11.5949833 C12.1491229,12.3531774 11.6399038,13.0732338 10.8817097,13.2032743 C10.5797928,13.2550571 10.2692256,13.205868 9.99808732,13.0633222 L8.38600951,12.2158028 C7.83132856,11.9241897 7.16867144,11.9241897 6.61399049,12.2158028 Z"
                    stroke={stroke}
                    strokeWidth="1.7"
                />
            </g>
        </svg>
    )
}

IconBrand.propTypes = {

}

IconBrand.defaultProps = {

}

export default IconBrand;
