import React, {
    useEffect,
    useState,
    useImperativeHandle,
    createRef, useRef,
} from 'react';
import {
    Grid,
    Container, Box, Typography,
} from '@mui/material';
import YnpCard from "../../components/AnnotationCard/YnpCard/YnpCard";
import SingleCardGrid from "./SingleCardGrid";
import PlacementCard from '../../components/AnnotationCard/PlacementCard/PlacementCard';
import LogoCard from "../../components/AnnotationCard/LogoCard/LogoCard";
import OcrCard from "../../components/AnnotationCard/OcrCard/OcrCard";
import LogoGenericCard from "../../components/AnnotationCard/LogoCard/LogoGenericCard/LogoGenericCard";
import {useCacheStore} from "../../contexts";
import {
    FALSE_POSITIVE_LOGO_ID,
    FALSE_POSITIVE_PLACEMENT_ID,
    LOGO_FP_ID,
    MISSING_LABEL_ID,
    UNSURE_LABEL_ID
} from "../../helpers/data/constants";

const CardGrid = React.forwardRef(
    ({
         annotations = [],
         detections = [],
         layout = 9,
         showChangeDid = false,
         mode,
         placementOptions,
         refreshedSpeed = [],
     }, ref
    ) => {
        const {
            brands,
            logos
        } = useCacheStore();

        const filteredAnnotations = annotations
            .filter(annotation => annotation.mediaAnnotateUrl.length !== 0)
            .map(annotation => {
                if (mode !== 'placement') return annotation

                const preselectedPlacementId = annotation.class_id_placement_selected;
                const preselectedBrand = annotation.class_id_selected;
                if(!!preselectedPlacementId && !annotation.contexts) {
                    const preselected_option = placementOptions
                        .find(el => parseInt(el.id, 10) === parseInt(preselectedPlacementId, 10))

                    if (!!preselected_option) {
                        annotation['contexts'] = preselected_option.id;
                    } else {
                       if (preselectedPlacementId === FALSE_POSITIVE_PLACEMENT_ID) {
                           annotation['contexts'] = FALSE_POSITIVE_PLACEMENT_ID;
                       } else {
                           console.error('Placement not found')
                       }
                    }
                }

                if (!!preselectedBrand && !annotation.logo && !annotation.deletedLogo) {
                    if (preselectedBrand === UNSURE_LABEL_ID) {
                        annotation['logo'] =  {id: UNSURE_LABEL_ID}
                        return annotation;
                    }

                    if (preselectedBrand === MISSING_LABEL_ID) {
                        annotation['logo'] =  {id: MISSING_LABEL_ID}
                        return annotation;
                    }

                    if (preselectedBrand === FALSE_POSITIVE_LOGO_ID) {
                        annotation['logo'] =  {id: -1}
                        return annotation
                    }

                    const preselected_option = logos.find(el => parseInt(el.id, 10) === parseInt(preselectedBrand, 10))
                    if (!!preselected_option) {
                        annotation['logo'] = preselected_option;
                    } else {
                        console.log(preselectedBrand)
                        console.error('logo not found')
                    }
                }

                return annotation;
            });
        const [cardsData, setCardData] = useState(filteredAnnotations);
        const [selectedCard, setSelectedCard] = useState(0);
        const [tooltipIndex, setTooltipIndex] = useState(-1);

        const messagesEndRef = useRef(null);

        useImperativeHandle(ref, () => {
            return {
                getCardsState() {
                    return cardsData;
                }
            };
        }, [cardsData]);

        const updateCardData = (index, property, value, deleteProperty = false) => {
            const annotationsDataUpdate = [...cardsData];
            if (!!deleteProperty) {
                delete annotationsDataUpdate[index][property]
            } else {
                annotationsDataUpdate[index][property] = value;
            }


            if (property === 'logo') {
                if (deleteProperty) {
                    annotationsDataUpdate[index]['deletedLogo'] = true;
                } else {
                    if (value?.id === LOGO_FP_ID) {
                        scrollCards(false);
                    }
                }
            }


            if (property === 'contexts' && !deleteProperty) {
                scrollCards(false);
            }

            setCardData(annotationsDataUpdate);
        }


        const scrollCards = (scrollBack = false) => {
            const annotationsLength = cardsData.length;

            if (!scrollBack) {
                const newIndex = selectedCard < annotationsLength - 1
                    ? selectedCard + 1
                    : 0
                scrollTo(newIndex);
                setSelectedCard(newIndex)
                if (tooltipIndex !== -1) setTooltipIndex(newIndex)
            } else {
                const newIndex = selectedCard > 0
                    ? selectedCard - 1
                    : annotationsLength - 1;
                scrollTo(newIndex);
                setSelectedCard(newIndex)
                if (tooltipIndex !== -1) setTooltipIndex(newIndex)
            }
        }

        const scrollTo = (index) => {
            if (index <= 2) {
                window.scrollTo(0, 0)
            }

            if (index >= 6) {
                window.scrollTo(0, 2000)
            }
        }


        useEffect(() => {
            const handleKeyDown = (event) => {

                const {
                    code,
                    keyCode,
                    shiftKey,
                } = event;

                const isOCR = !!detections[0].ocr;
                const isPlacement = !!annotations[0].class_ids
                const isYnp = !isOCR && !isPlacement;

                if (code === 'Tab') {
                    event.preventDefault();
                    return scrollCards(shiftKey)
                }

                if (
                    document.activeElement.tagName.toLowerCase() === 'input'
                ) {
                    if (code === 'Space' && document.activeElement.value === ''){
                        event.preventDefault();
                        return setTooltipIndex(
                            selectedCard
                        );
                    }

                } else {
                    if (code === 'Space'){
                        event.preventDefault();
                        return setTooltipIndex(
                            selectedCard
                        );
                    }
                }

                /**********************
                 * YNP Cards Shortcut *
                 **********************/
                if (isYnp) {

                    if (code === 'ArrowLeft') {
                        scrollCards(true)
                    }

                    if (code === 'ArrowRight') {
                        scrollCards()
                    }

                    if (keyCode === 89) {
                        event.preventDefault();
                        updateCardData(selectedCard, 'approve', 1);
                        scrollCards();
                    }

                    if (keyCode === 80) {
                        event.preventDefault();
                        updateCardData(selectedCard, 'approve', 0.5);
                        scrollCards();
                    }

                    if (keyCode === 78) {
                        event.preventDefault();
                        updateCardData(selectedCard, 'approve', 0);
                        scrollCards();
                    }
                }


            }

            const handleKeyUp = (event) => {
                    if (event.code === 'Space'){
                        event.preventDefault();
                        return setTooltipIndex(
                            -1
                        );
                    }
            }

            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('keyup', handleKeyUp);

            };

        }, [selectedCard,tooltipIndex])


        const getRefreshedSpeedDials = (detectionId) => {
            if (detectionId && detectionId.length > 0) {
                const id = parseInt(detectionId.split('_')[1], 10);
                if (id) {
                    return refreshedSpeed.find(el => el.id === id);
                }
            }

            return null;

        }

        return (
            <div ref={messagesEndRef}>
                <Container maxWidth={'xl'} sx={{mb: '10rem'}} ref={ref}>
                    {
                        layout === 9 &&
                        <Grid
                            container={true}
                            spacing={3}
                            sx={{
                                mt: 1,
                                mb: '10rem',
                                pb: '10rem',
                                alignItems: 'center',
                            }}
                        >
                            {
                                cardsData.length === 0 && <Box
                                    mt={5}
                                    width={'100%'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                >
                                <Typography
                                    variant={'h4'}
                                    color={'error'}
                                >
                                    Empty MediaAnnotateUrl Error
                                </Typography>
                                <Typography>
                                    No cards with a valid <span style={{
                                        fontFamily: 'monospace',
                                        color: "orange",
                                    }}> mediaAnnotateUrl</span>

                                </Typography>

                                </Box>
                            }
                            {
                                cardsData.map((annotation, index) => (
                                    <Grid
                                        item={true}
                                        xs={4}
                                        key={index}
                                    >
                                        {
                                            mode === 'ynp'
                                                ? <YnpCard
                                                    annotation={annotation}
                                                    detection={detections[index] ?? {}}
                                                    index={index}
                                                    isSelected={index === selectedCard}
                                                    setSelectedCard={setSelectedCard}
                                                    showChangeDid={showChangeDid}
                                                    updateAnnotations={updateCardData}
                                                    setPreview={setTooltipIndex}
                                                    openFullImage={tooltipIndex}
                                                />
                                                : null
                                        }
                                        {
                                            mode === 'ocr'
                                                ? <OcrCard
                                                    index={index}
                                                    annotation={annotation}
                                                    isSelected={index === selectedCard}
                                                    detection={detections[index] ?? {}}
                                                    setSelectedCard={setSelectedCard}
                                                    updateAnnotations={updateCardData}
                                                    setPreview={setTooltipIndex}
                                                    openFullImage={tooltipIndex}
                                                />
                                                : null
                                        }
                                        {
                                            mode === 'placement'
                                                ?
                                                (

                                                    (!!detections[index].visualClassId
                                                    && detections[index].visualClassId === 'viscla_175474')
                                                    || !!annotation.class_id_selected
                                                ) ?
                                                    <LogoGenericCard
                                                        index={index}
                                                        annotation={annotation}
                                                        isSelected={index === selectedCard}
                                                        detection={detections[index] ?? {}}
                                                        setSelectedCard={setSelectedCard}
                                                        updateAnnotations={updateCardData}
                                                        brands={brands}
                                                        placementOptions={
                                                            placementOptions
                                                                .filter(
                                                                    el => annotation.class_ids.includes(parseInt(el.id, 10))
                                                                )
                                                        }
                                                        setPreview={setTooltipIndex}
                                                        openFullImage={tooltipIndex}
                                                        refreshedSpeedDial={getRefreshedSpeedDials(detections[index].id)}
                                                    />
                                                    :
                                                    <PlacementCard
                                                        index={index}
                                                        annotation={annotation}
                                                        isSelected={index === selectedCard}
                                                        detection={detections[index] ?? {}}
                                                        setSelectedCard={setSelectedCard}
                                                        updateAnnotations={updateCardData}
                                                        options={
                                                            placementOptions
                                                                .filter(
                                                                    el => annotation.class_ids.includes(parseInt(el.id, 10))
                                                                )
                                                        }
                                                        setPreview={setTooltipIndex}
                                                        openFullImage={tooltipIndex}
                                                        refreshedSpeedDial={getRefreshedSpeedDials(detections[index].id)}
                                                    />
                                                : null
                                        }
                                    </Grid>
                                ))
                            }
                        </Grid>
                    }
                    {
                        layout === 1 &&
                        <SingleCardGrid
                            index={selectedCard}
                            annotation={cardsData[selectedCard]}
                            detection={detections[selectedCard]}
                            mode={mode}
                            updateAnnotations={updateCardData}
                            datasetOptions={
                                placementOptions
                                    .filter(
                                        el => cardsData[selectedCard].class_ids.includes(parseInt(el.id, 10))
                                    )
                            }
                            totals={annotations.length}
                        />
                    }
                </Container>
            </div>
        )
    })


export default CardGrid
