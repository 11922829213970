import { QueueNameContext } from '../contexts/queueNameContext';
import {  useContext } from 'react';

/*
    This extract an argument from the query string
*/
export const useQueryString = (argName) => {

    const {queueName, setQueueName} = useContext(QueueNameContext)
    const searchQuery = new URLSearchParams(window.location.hash.substr(2));

    const originalQueueName = searchQuery.get(argName) ?? '';
    setQueueName(originalQueueName)

    const updateQueueName = queue => {
        window.location.hash = `/?${argName}=${queue}`;
        setQueueName(queueName)
    }

    return [queueName, updateQueueName]  
}
