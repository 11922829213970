import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

function PurgeQueueDialog(
    {
        open,
        setOpen,
        queueName,
        purgeQueue,
    }
) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-purge-title"
            aria-describedby="alert-purge-description"
        >
            <DialogTitle id="alert-purge-title">
                {"Irreversible operation"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-purge-description">
                    Are you sure you want to purge all the messages from the queue <b>{queueName}</b>?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={'info'} onClick={handleClose}>Cancel</Button>
                <Button onClick={_ => {
                    handleClose();
                    return purgeQueue();
                }} color={'error'}>
                    Purge!
                </Button>
            </DialogActions>
        </Dialog>
    )
}

PurgeQueueDialog.propTypes = {}

PurgeQueueDialog.defaultProps = {}

export default PurgeQueueDialog;
