import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { useLocalStorage } from 'usehooks-ts'
import { DataGridPro, useGridApiRef, gridFilteredSortedRowIdsSelector, gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid-pro';
import { useConfirm } from "material-ui-confirm";
import { ToastContainer, toast } from 'react-toastify'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';

import { useUserStore } from '../../contexts';
import { useQueryString, useLoadAnnotations, useToCSV, downloadFile } from '../../hooks'

import {
    SAMPLED_TITLE_ROW,
    REVIEW_ROW_WIDTH,
    CORRECTION_TITLE_ROW,
    INITIAL_COLUMNS_VISIBILITY_MODEL,
    INITIAL_FILTER_MODEL,
    INITIAL_SORT_MODEL, 
    INITIAL_PINNED_COLUMNS, 
    INITIAL_COLUMNS_WIDTH, 
    STATUS_TITLE_ROW,
    CORRECTION_STATUS_TITLE_ROW,
    CORRECTION_FILTER_UNTOUCHED,
    CORRECTION_FILTER_CORRECT,
    CORRECTION_FILTER_WRONG_PLACEMENT,
    CORRECTION_FILTER_WRONG_LOGO,
    CORRECTION_FILTER_BOTH,
    STATUS_LABEL_CHECKED,
    STATUS_LABEL_UNTOUCHED,
    STATUS_LABEL_CORRECTED, 
    QUEUE_NAME_URL_ARG,
    ANNOTATION_TYPE_ANNOTATION,
    TYPE_TITLE_ROW,
    ANNOTATION_TYPE_PROPAGATION,
    PLACEMENT_ROW_TITLE,
    ANNOTATION_TYPE_TOTAL,
    SNAPSHOT_TITLE_ROW,
    CLIP_TITLE_ROW,
    ERROR_CHECK_LABEL,
} from '../../helpers/data/constants'
import { Typography } from '@mui/material';
import { getReviewTaskInfo, postReviewCheckAggregate } from 'DataService';
import { postReviewComplete, postReviewDone } from '../../DataService';
import { computeAggregatedRows } from './ReviewAppErrorInsights';
import { debounce } from 'lodash';

import { getGridStringOperators } from '@mui/x-data-grid';
import { CorrectionToolbar } from './CorrectionToolbar';


const HistoryRenderer = ({ value }) => {
  return <div>{JSON.stringify(value)}</div> 
}

const DEBOUNCE_KEY_DELAY = 100;

/* 
    This extract the url from spreadsheet image =HYPERLINK syntax
*/ 
const extractHyperlink = input => {
  if(input) {
    let regex = /^=HYPERLINK\("(.*)"\)$/i;
    return input.match(regex)?.[1];
  } else {
    return 
  }
} 


export const useKey = (callback, keys, type = 'keydown', deps = []) => {
    useEffect(() => {
      const onKey = (event) => {
        const wasAnyKeyPressed = keys.some((key) => event.key === key);
        if (wasAnyKeyPressed) {
          event.preventDefault();
          callback(event);
        }
      };
      document.addEventListener(type, onKey);
      return () => {
        document.removeEventListener(type, onKey);
      };
    }, [callback, keys, type]);
  };

const LoadingScreen = () => {
  return <div
    style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}
  >
    <div>
      <CircularProgress  /> 
    </div>
    <h2 style={{ marginTop: '20px', fontWeight: 'bolder', fontSize: '1.5rem'}}>
      Loading annotations 
    </h2>
    <div style={{ marginTop: '20px'}}>
     This process can take up to a couple of minutes on large datasets and low-spec hardware. Thanks for your patience.
    </div>
  </div> 
}

const StatusBar = ({ value: status }) => 
    <div>{
        status === STATUS_LABEL_UNTOUCHED ? 
            <>Untouched</> :
        status === STATUS_LABEL_CHECKED ? 
            <>Correct</> :
        status === STATUS_LABEL_CORRECTED ? 
            <>Correction needed</> :
        <>Unknown</>
    }
    </div>



/*
    This is the review app accesible from /review
*/
const ReviewApp = () => {
    
    // Is the modal opened?
    const [modalOpened, setModalOpened] = useState(false);

    const [queueName] = useQueryString(QUEUE_NAME_URL_ARG)

    const apiRef = useGridApiRef();

    const user = useUserStore();
    const admin = user?.permission?.indexOf('admin') !== -1;

    const [adminMode, setAdminMode] = useState(false);
    const [taskInfo, setTaskInfo] = useState({});
    
    const [statusFilterOpen, setStatusFilterOpen] = useState(false);
    const [placementFilterOpen, setPlacementFilterOpen] = useState(false);
    const [annoTypeFilterOpen, setAnnoTypeFilterOpen] = useState(false);
  
    const { loading, error, rows, columnsHeads, onCorrect } = useLoadAnnotations(queueName);
  
    const confirm = useConfirm();
  
    const refresh = useCallback(async () => {
        if(queueName) {
          setTaskInfo(await getReviewTaskInfo(queueName))
        }
    }, [queueName]);
  
    const toCSV = useToCSV();

    useEffect( () => {
      (async () => {
        await refresh();
      })()
    }, [refresh])

    // TODO: Magical numbers
    const status = taskInfo?.iann?.task?.status;
    const completed = status !== -300 && status !== -309 && status !== -303;
    const editable = status === -300 || status === -309 || adminMode;
    const canAdmin = status === -303 && admin;
    const done = status !== -309 && status !== -300;
  
    const untouched = rows?.filter(r => r[SAMPLED_TITLE_ROW])?.filter(r => r[STATUS_TITLE_ROW] === STATUS_LABEL_UNTOUCHED)?.length;

    const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage(
        'reviewTableColumnVisibilityModel',
        INITIAL_COLUMNS_VISIBILITY_MODEL
    )
   
    const [filterModel, setFilterModel] = useLocalStorage(
        'reviewTableFilterModel',
        INITIAL_FILTER_MODEL 
    )

    const [sortModel, setSortModel] = useLocalStorage(
        'reviewTableSortModel',
        INITIAL_SORT_MODEL
    )

    const [pinnedColumns, setPinnedColumns] = useLocalStorage(
        'reviewTablePinnedColumns',
        INITIAL_PINNED_COLUMNS
    )

    const [columnsWidth, setColumnsWidth] = useLocalStorage(
        'reviewTableColumnsWidth',
        INITIAL_COLUMNS_WIDTH
    )
    
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
   
    /*
        This render an image using the =HYPERLINK syntax
                <div style={{height: `${REVIEW_ROW_HEIGHT}px`}}>
                    <img style={{height: `${REVIEW_ROW_HEIGHT}px`}} src={src} alt={params.value} loading="lazy" />
                </div>
    */
    const renderImage = params => {
        const src = extractHyperlink(params.value)
        const image = 
            src &&
                <div style={{ width: '100%'}}>
                    <img style={{width: '100%'}} src={src} alt={params.value} loading="lazy" />
                </div>

        return src ?  
            image : 
            <div>{params.value}</div>
    }
    
    const renderClip = params => {
      if(params) {
        const src = extractHyperlink(params.value)
        const link = 
            src &&
                <div style={{ width: '100%'}}>
                    <a href={src} alt={src} loading="lazy" target="_blank" rel="noreferrer">Clip</a>
                </div>

        return src ?  
            link : 
            <div>{params.value}</div>
      } else {
        return <em>No clip</em>
      }
    }
    

    /*
        This extract the columns heads
    */ 
    const columns = useMemo( () => {

        return columnsHeads && [
                {
                  field: "Placement",
                  headerName: "Placement",
                  filterOperators: [
                    ...getGridStringOperators(),
                    {
                      value: 'does not contain',
                      InputComponent: getGridStringOperators()[0].InputComponent,//(props) => <input {...props} />,
                      InputComponentProps: { type: 'string' },
                      label: 'not contains',
                      getApplyFilterFn: (filterItem, column) =>  {
                        
                        if (
                          !filterItem.field ||
                          !filterItem.value ||
                          !filterItem.operator
                        ) {
                          return null;
                        }
            
                        const filterRegex = new RegExp(filterItem.value, "i");
                        return (params) => {
                          const rowValue = column.valueGetter
                            ? column.valueGetter(params)
                            : params.value;
                          return !filterRegex.test(rowValue?.toString() || "");
                        };
                      }
                    }
                  ]
                },
                {
                  field: "Snapshot",
                  headerName: "Snapshot",
                  renderCell: renderImage,
                },
                {
                  field: "Name",
                  headerName: "Name",
                },
                { 
                    field: CLIP_TITLE_ROW, 
                    headerName: CLIP_TITLE_ROW,
                    renderCell: renderClip,
                },
                { 
                    field: SAMPLED_TITLE_ROW, 
                    headerName: SAMPLED_TITLE_ROW,
                    type: 'boolean'
                },
                { 
                    field: CORRECTION_TITLE_ROW, 
                    headerName: CORRECTION_TITLE_ROW, 
                    width: REVIEW_ROW_WIDTH, 
                    renderCell: ({value}) => <CorrectionToolbar {...{ value, editable, adminMode }} />,
                    filterable: false,
                    sortable: false 
                },
                { 
                    field: CORRECTION_STATUS_TITLE_ROW, 
                    headerName: CORRECTION_STATUS_TITLE_ROW, 
                    width: REVIEW_ROW_WIDTH, 
                    type: 'singleSelect',
                    valueOptions: [
                        CORRECTION_FILTER_UNTOUCHED,
                        CORRECTION_FILTER_CORRECT,
                        CORRECTION_FILTER_WRONG_PLACEMENT,
                        CORRECTION_FILTER_WRONG_LOGO,
                        CORRECTION_FILTER_BOTH
                    ]
                },
                { 
                    field: STATUS_TITLE_ROW, 
                    headerName: STATUS_TITLE_ROW, 
                    width: REVIEW_ROW_WIDTH, 
                    renderCell: StatusBar,
                    type: 'singleSelect',
                    valueOptions: [
                        STATUS_LABEL_CHECKED, 
                        STATUS_LABEL_CORRECTED, 
                        STATUS_LABEL_UNTOUCHED
                    ]
                }, 
                { 
                    field: TYPE_TITLE_ROW,
                    headerName: TYPE_TITLE_ROW,
                    type: 'singleSelect',
                    valueOptions: [
                        ANNOTATION_TYPE_ANNOTATION,
                        ANNOTATION_TYPE_PROPAGATION,
                    ]
                },
                    {
                      "field": "Session ID",
                      "headerName": "Session ID"
                    },
                    {
                      "field": "Track ID",
                      "headerName": "Track ID"
                    },
                    {
                      "field": "Exposure ID",
                      "headerName": "Exposure ID"
                    },
                    {
                      "field": "ID",
                      "headerName": "ID"
                    },
                    {
                      "field": "Class ID",
                      "headerName": "Class ID"
                    },
                    {
                      "field": "Ignored for sponsor clutter?",
                      "headerName": "Ignored for sponsor clutter?"
                    },
                    {
                      "field": "Placement ID",
                      "headerName": "Placement ID"
                    },
                    {
                      "field": "Exposure duration",
                      "headerName": "Exposure duration"
                    },
                    {
                      "field": "Exposure area",
                      "headerName": "Exposure area"
                    },
                    {
                      "field": "Exposure confidence",
                      "headerName": "Exposure confidence"
                    },
                    {
                      "field": "Exposure sponsor clutter",
                      "headerName": "Exposure sponsor clutter"
                    },
                    {
                      "field": "Exposure total clutter",
                      "headerName": "Exposure total clutter"
                    },
                    {
                      "field": "Exposure time in",
                      "headerName": "Exposure time in"
                    },
                    {
                      "field": "Exposure time out",
                      "headerName": "Exposure time out"
                    },
                    {
                      "field": "Exposure placement confidence",
                      "headerName": "Exposure placement confidence"
                    },
                    {
                      "field": "Track duration",
                      "headerName": "Track duration"
                    },
                    {
                      "field": "Track area",
                      "headerName": "Track area"
                    },
                    {
                      "field": "Track confidence",
                      "headerName": "Track confidence"
                    },
                    {
                      "field": "Track time in",
                      "headerName": "Track time in"
                    },
                    {
                      "field": "Track time out",
                      "headerName": "Track time out"
                    },
                    {
                      "field": "Track placement confidence",
                      "headerName": "Track placement confidence"
                    },
                    {
                      "field": "Sport/League ID",
                      "headerName": "Sport/League ID"
                    },
                    {
                      "field": "Season Stage",
                      "headerName": "Season Stage"
                    },
                    {
                      "field": "Fixture Date",
                      "headerName": "Fixture Date"
                    },
                    {
                      "field": "Fixture Time",
                      "headerName": "Fixture Time"
                    },
                    {
                      "field": "Timezone",
                      "headerName": "Timezone"
                    },
                    {
                      "field": "Fixture Location",
                      "headerName": "Fixture Location"
                    },
                    {
                      "field": "Home Team",
                      "headerName": "Home Team"
                    },
                    {
                      "field": "Visiting Team",
                      "headerName": "Visiting Team"
                    },
                    {
                      "field": "Broadcaster",
                      "headerName": "Broadcaster"
                    },
                    {
                      "field": "Fixture Name",
                      "headerName": "Fixture Name"
                    },
                    {
                      "field": "Fixture Type",
                      "headerName": "Fixture Type"
                    },
                    {
                      "field": "iann_id",
                      "headerName": "iann_id"
                    },
                    {
                      "field": "annotator_id",
                      "headerName": "annotator_id"
                    },
                    {
                      "field": "annotator_email",
                      "headerName": "annotator_email"
                    },
                    {
                      "field": "nn_id",
                      "headerName": "nn_id"
                    },
                    {
                      "field": "nn_score",
                      "headerName": "nn_score"
                    },
                    {
                      "field": "nn_snapshot",
                      "headerName": "nn_snapshot",
                      renderCell: renderImage,
                    },
                    {
                      "field": "History",
                      "headerName": "History",
                      renderCell: HistoryRenderer,
                    },
        
        ].map( row => columnsWidth[row.field] ? ({ ...row, width: columnsWidth[row.field] }) : row)
    }, [columnsHeads, editable, adminMode, columnsWidth]);

    const placements = rows && [...[...new Set(rows.map(row => row[PLACEMENT_ROW_TITLE]))].sort()]
    const placementFilter = filterModel?.items.find(item => 
      item.field === PLACEMENT_ROW_TITLE && item.operator === 'equals')?.value || '*';

    const handlePlacementFilter = event => {
        const otherFilters = filterModel?.items.filter( ({ field} ) => field !== PLACEMENT_ROW_TITLE)
        if(event.target.value && event.target.value !== '*') {
            setFilterModel({
                items: [
                    ...otherFilters,
                    { 
                        field: PLACEMENT_ROW_TITLE,
                        value: event.target.value,
                        operator: 'equals',
                    }
                ]
            })
        } else {
            setFilterModel({ items: otherFilters })
        }
    }

    const annotationTypeFilter = filterModel?.items.find(item => item.field === TYPE_TITLE_ROW)?.value || ANNOTATION_TYPE_TOTAL;

    const handleAnnotationTypeFilter = event => {
        const otherFilters = filterModel?.items.filter( ({ field} ) => field !== TYPE_TITLE_ROW)
        if(event.target.value && event.target.value !== ANNOTATION_TYPE_TOTAL) {
            setFilterModel({
                items: [
                    ...otherFilters,
                    { 
                        field: TYPE_TITLE_ROW,
                        value: event.target.value,
                    }
                ]
            })
        } else {
            setFilterModel({ items: otherFilters })
        }
    }


    const correctionStatusFilters = filterModel?.items.filter(item => item.field === CORRECTION_STATUS_TITLE_ROW);
    const correctionStatusFilter = correctionStatusFilters.length === 2? CORRECTION_FILTER_BOTH: correctionStatusFilters?.[0]?.value || '*';

    const handleCorrectionStatusFilter = event => {
        const otherFilters = filterModel?.items.filter( ({ field} ) => field !== CORRECTION_STATUS_TITLE_ROW)
        if(event.target.value !== '*') {
            if(event.target.value === CORRECTION_FILTER_CORRECT) {
                setFilterModel({
                    items: [
                        ...otherFilters,
                        { 
                            field: CORRECTION_STATUS_TITLE_ROW,
                            value: CORRECTION_FILTER_CORRECT,
                        }
                    ]
                })
            } else if(event.target.value === STATUS_LABEL_UNTOUCHED) {
                setFilterModel({
                    items: [
                        ...otherFilters,
                        { 
                            field: CORRECTION_STATUS_TITLE_ROW,
                            value: CORRECTION_FILTER_UNTOUCHED,
                        }
                    ]
                })
            } else  if(event.target.value === CORRECTION_FILTER_BOTH) {
                setFilterModel({
                    items: [
                        ...otherFilters,
                        { 
                            field: CORRECTION_STATUS_TITLE_ROW,
                            value: CORRECTION_FILTER_CORRECT,
                            operator: 'not'
                        },
                        { 
                            field: CORRECTION_STATUS_TITLE_ROW,
                            value: CORRECTION_FILTER_UNTOUCHED,
                            operator: 'not'
                        },
                    ],
                    logicOperator: 'and'
                })
            }
        } else {
            setFilterModel({ items: otherFilters })
        }
    }
    
    const handleClearFilters = () => {
        setFilterModel(INITIAL_FILTER_MODEL);
    }
    
  
    const arrowDown = 
          debounce( (event) => {

            setStatusFilterOpen(false);
            setPlacementFilterOpen(false);
            setAnnoTypeFilterOpen(false);

            document.activeElement?.blur();

            const filteredRowsId = apiRef?.current?.state 
              ? gridFilteredSortedRowIdsSelector(apiRef?.current?.state, apiRef?.current?.instanceId) 
              : [];

            const selectedIndex = filteredRowsId.indexOf(rowSelectionModel[0]) || 0;
            if(selectedIndex === filteredRowsId.length - 1) return;
            const nextIndex = selectedIndex + 1

            setTimeout(() => {
              apiRef?.current?.scrollToIndexes({ rowIndex: nextIndex + 1 })
              apiRef?.current?.scrollToIndexes({ rowIndex: nextIndex })
              document.activeElement?.blur();
            }
            , 10); 
            
            setRowSelectionModel([filteredRowsId[nextIndex]]);
            event.stopPropagation();
            event.preventDefault();

          }, DEBOUNCE_KEY_DELAY)

    const arrowUp = debounce( (event) => {

            setStatusFilterOpen(false);
            setPlacementFilterOpen(false);
            setAnnoTypeFilterOpen(false);

            document.activeElement?.blur();

            const filteredRowsId = apiRef?.current?.state 
              ? gridFilteredSortedRowIdsSelector(apiRef?.current?.state, apiRef?.current?.instanceId) 
              : [];

            const selectedIndex = filteredRowsId.indexOf(rowSelectionModel[0]) || -1;
            if(selectedIndex === 0) return;
            const nextIndex = selectedIndex === 0 ? filteredRowsId.length - 1 : selectedIndex - 1;

            setTimeout(() => {
              apiRef?.current?.scrollToIndexes({ rowIndex: nextIndex - 1 }); 
              apiRef?.current?.scrollToIndexes({ rowIndex: nextIndex }); 
              document.activeElement?.blur();
            }, 10); 

            setRowSelectionModel([filteredRowsId[nextIndex]]);
            event.stopPropagation();
            event.preventDefault();

          }, DEBOUNCE_KEY_DELAY)

    const keyLogoSwitch = debounce( async (event) => {

        try {
          const selected = rows?.find(({id}) => id === rowSelectionModel[0]);

          if(selected['Class ID'] === '104022') {
            return  
          }

          const correction = selected[CORRECTION_TITLE_ROW]?.correction;
          const invert = error => error === 0? 1: 0;

          await onCorrect({
            iann_id: selected.iann_id,
            logoError: correction ? invert(correction.logoError) : 1,
            placementError: correction ? correction.placementError : 0,
            reviewed: true,
            adminMode,
          })

          event.stopPropagation();
          event.preventDefault();

        }
        catch(error) {
          toast.error(error.message + '. Please check the few last annotated row.'); 
        }
   }, DEBOUNCE_KEY_DELAY)

   const keyPlacementSwitch = debounce( async (event) => {

      try {

          if(editable) {

            const selected = rows?.find(({id}) => id === rowSelectionModel[0]);
            const correction = selected[CORRECTION_TITLE_ROW]?.correction;
            const invert = error => error === 0? 1: 0;
            await onCorrect({
              iann_id: selected.iann_id,
              logoError: correction ? correction.logoError : 0,
              placementError: correction ? invert(correction.placementError) : 1,
              reviewed: true,
              adminMode
            })

            event.stopPropagation();
            event.preventDefault();

          }

      }
      catch(error) {
        toast.error(error.message + '. Please check the few last annotated row.'); 
      }
            
      }, DEBOUNCE_KEY_DELAY)

      const keyCorrect =  debounce(async (event) => {

        if(editable) {

          const selected = rows?.find(({id}) => id === rowSelectionModel[0]);
          const correction = selected[CORRECTION_TITLE_ROW]?.correction;
          await onCorrect({
            iann_id: selected.iann_id,
            logoError: 0,
            placementError: 0,
            adminMode,
            ...correction,
          })

          event.stopPropagation();
          event.preventDefault();

        }

      }, DEBOUNCE_KEY_DELAY)

     const keySpace = debounce((event) => {

      setModalOpened(!modalOpened);

      const filteredRowsId = apiRef?.current?.state 
        ? gridFilteredSortedRowIdsSelector(apiRef?.current?.state, apiRef?.current?.instanceId) 
        : [];

      setTimeout(() => {
        const selectedIndex = filteredRowsId.indexOf(rowSelectionModel[0]) || 0;
        apiRef?.current?.scrollToIndexes({ selectedIndex }); 
        document.activeElement?.blur();
      }, 10); 

      event.stopPropagation();
      event.preventDefault();
      
    }, DEBOUNCE_KEY_DELAY)

    useEffect(() => {
      const onKey = (event) => {
        if (event.key === 'ArrowDown') {
          arrowDown(event)
        } else if (event.key === 'ArrowUp') {
          arrowUp(event);          
        } else if(event.key === 'z') {
          keyLogoSwitch(event);
        } else if(event.key === 'x') {
          keyPlacementSwitch(event);
        } else if(event.key === 'c') {
          keyCorrect(event);
        } else if(event.key === ' ') {
          keySpace(event);
        }
      };
      document.addEventListener('keydown', onKey);
      return () => {
        document.removeEventListener('keydown', onKey);
      };

    }, [rowSelectionModel, setRowSelectionModel, apiRef, onCorrect, rows, editable, modalOpened, arrowDown, arrowUp, keyLogoSwitch, keyPlacementSwitch, keyCorrect, keySpace])
  
    const onDone = async () => {
      try {
        if(! taskInfo?.iann?.task?.taskHash) {
          throw Error('No task hash');
        }
        await postReviewDone(taskInfo?.iann?.task?.taskHash);
        await postReviewCheckAggregate(taskInfo?.iann?.task?.taskHash, computeAggregatedRows(ERROR_CHECK_LABEL, rows));
        await refresh();
      } catch(error) {
        console.error('Cannot mark task as done', error);
        toast.error(error.message );
      }
    } 

    const onComplete = async () => {
      try {

        await postReviewComplete(taskInfo?.iann?.task?.taskHash);
        await postReviewCheckAggregate(taskInfo?.iann?.task?.taskHash, computeAggregatedRows(ERROR_CHECK_LABEL, rows));
        //setCompleted(true);
        await refresh();

      } catch (error) {

        console.error('Cannot mark task as complete', error);
        toast.error(error.message );

      }
    } 
  
    const showMarkAllUntouched = filterModel.items.find( item => item.field === PLACEMENT_ROW_TITLE)
  
    const onMarkAllUntouched = async () => {

      await confirm({ description: "This action is irreversible. Are you sure you want to mark all untouched rows to correct?"})

      const rows = gridFilteredSortedRowEntriesSelector(
        apiRef.current.state, 
        apiRef.current.instanceId
      ).map(row => row.model).filter(row => row[STATUS_TITLE_ROW] === STATUS_LABEL_UNTOUCHED);

      await Promise.all(rows.map(
        async selected => {
          const correction = selected[CORRECTION_TITLE_ROW]?.correction;
          return onCorrect({
            iann_id: selected.iann_id,
            logoError: 0,
            placementError: 0,
            adminMode,
            ...correction,
          })
        }
      ))
    }

    const adminModeButtons = <div>
      <Checkbox
          value="check"
          selected={adminMode}
          onChange={() => {
            setAdminMode(!adminMode);
          }}
          variant="filled"
       />
          Admin mode
      </div>

    const adminButtons = <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        { canAdmin ? adminModeButtons : null}
        <Typography variant="caption">
          {
            [ taskInfo?.iann?.projet?.detectionSettingId,
              taskInfo?.iann?.project?.name,
              taskInfo?.iann?.media?.sessionId,
              taskInfo?.iann?.media?.id,
              taskInfo?.iann?.media?.meta?.developer_meta?.['Fixture Location'],
              status
            ].join(' - ')}
        </Typography>
        <div>
          <Button disabled={done || untouched} variant="contained" onClick={onDone}>Done</Button>
          {admin ? <Button disabled={completed || untouched} variant="contained" onClick={onComplete}>Complete</Button> : null}
          {admin && showMarkAllUntouched ? 
            <Button 
              disabled={!editable} 
              variant="outlined" 
              onClick={onMarkAllUntouched}>
                Mark all untouched as correct
              </Button> : null}
        </div>
        <div>{untouched? `There are some untouched row`: `All rows have been checked`}</div>
      </div>

    const ANNOTATOR_TYPE_ADMIN = 1;
    const isFalseNegative = (row) => {
      // Find the correction array
      const { history } = row?.[CORRECTION_TITLE_ROW] || {};
    
      if(!history) return false;
    
      const isAdmin = current => current?.annotatorType === ANNOTATOR_TYPE_ADMIN;
      const isLogoError = current => current?.logoVersionError !== 0 
      const isPlacementError = current => current?.placementError !== 0
      
      const lastNonAdmin = history.find(current => !isAdmin(current));
      const lastAdmin = history.find(current => isAdmin(current));
    
      if(lastNonAdmin && lastAdmin) {


        const falseNegativeLogo = !isLogoError(lastAdmin)  && isPlacementError(lastNonAdmin);
        const falseNegativePlacement = !isPlacementError(lastAdmin) && isPlacementError(lastNonAdmin);
        return falseNegativeLogo || falseNegativePlacement;
      } else {
        return false;
      }
    }
    

    const isFalsePositive = (row) => {
      const { history } = row?.[CORRECTION_TITLE_ROW] || {};

      if(!history) return false;

      const isAdmin = current => current?.annotatorType === ANNOTATOR_TYPE_ADMIN
      const isLogoError = current => current?.logoVersionError !== 0 
      const isPlacementError = current => current?.placementError !== 0
      
      const lastNonAdmin = history.find(current => !isAdmin(current));
      const lastAdmin = history.find(current => isAdmin(current));
      
      if(lastAdmin && lastNonAdmin) {
        const falsePositiveLogo = isLogoError(lastAdmin)  && !isPlacementError(lastNonAdmin)
        const falsePositivePlacement = isPlacementError(lastAdmin) && !isPlacementError(lastNonAdmin)
        return falsePositiveLogo || falsePositivePlacement;
      }
      else {
        return false;
      }
      
    } 

    const getRowClassName = ({row}) => {
      if(isFalsePositive(row) && adminMode) {
        return 'row-highlight-false-positive';
      }
      else if(isFalseNegative(row) && adminMode)  {
        return 'row-highlight-false-negative';
      }

    }

    const onColumnWidthChange = (col) => {
      const width = col?.colDef?.width;
      const field = col?.colDef?.field;
      setColumnsWidth({
        ...columnsWidth,
        [field]: width
      })
    }
  
    const selectedClipHyperlink = rows?.find( ({id}) => id === rowSelectionModel?.[0])?.[SNAPSHOT_TITLE_ROW];
    const selectedClipSrc = selectedClipHyperlink ? extractHyperlink(selectedClipHyperlink) : undefined;

    const placementsCount = useMemo(() => {
      return rows?.filter(row => row[SAMPLED_TITLE_ROW])?.reduce((acc, row) => ({
        ...acc,
        [row[PLACEMENT_ROW_TITLE]]: (acc[row[PLACEMENT_ROW_TITLE]] || 0) + 1
      }), {}); 
    }, [rows])
  
    const exportCsv = async () => {
      if(apiRef.current) {
        const rows = gridFilteredSortedRowEntriesSelector(
          apiRef.current.state, 
          apiRef.current.instanceId
        ).map(row => row.model)
        const csv = toCSV(rows, columns.map(col => col.field));
        downloadFile(csv, `${queueName}.csv`, 'text/csv');
      }
    }

    /*
        The library returns each rows as an object with number as properties
        This recreate a proper named object with an idea based on iann_id
    */
    return <div style={{ display: 'flex', flexDirection: 'column'}}>
      <ToastContainer />
        <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'space-evenly', margin: '24px'}}>
            <FormControl style={{ width: '25vw' }}>
              <InputLabel>Placement</InputLabel>
              <Select
                labelId="placement-filter-label"
                id="placement-filter-select"
                value={placementFilter}
                label="Placement"
                onChange={handlePlacementFilter}
                open={placementFilterOpen}
                onOpen={() => setPlacementFilterOpen(true)}
                onClose={() => setPlacementFilterOpen(false)}
              >
                <MenuItem value={'*'}>*</MenuItem>
                {placements?.map(placement => 
                    <MenuItem value={placement}>{placement} ({placementsCount[placement]})</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl style={{ width: '25vw' }}>
              <InputLabel>Annotation type</InputLabel>
              <Select
                labelId="annotation-type-filter-label"
                id="annotation-type-filter-select"
                value={annotationTypeFilter}
                label="Annotation type"
                onChange={handleAnnotationTypeFilter}
                open={annoTypeFilterOpen}
                onOpen={() => setAnnoTypeFilterOpen(true)}
                onClose={() => setAnnoTypeFilterOpen(false)}
              >
                <MenuItem value={ANNOTATION_TYPE_TOTAL}>Show everything</MenuItem>
                <MenuItem value={ANNOTATION_TYPE_ANNOTATION}>Annotation</MenuItem>
                <MenuItem value={ANNOTATION_TYPE_PROPAGATION}>Propagation</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: '25vw' }}>
              <InputLabel>Status</InputLabel>
              <Select
                labelId="correction-status-select-label"
                id="demo-simple-select"
                value={correctionStatusFilter}
                label="Status"
                onChange={handleCorrectionStatusFilter}
                open={statusFilterOpen}
                onOpen={() => setStatusFilterOpen(true)}
                onClose={() => setStatusFilterOpen(false)}
              >
                <MenuItem value={'*'}>Show everything</MenuItem>
                <MenuItem value={CORRECTION_FILTER_CORRECT}>Show correct rows</MenuItem>
                <MenuItem value={CORRECTION_FILTER_BOTH}>Show errors</MenuItem>
                <MenuItem value={CORRECTION_FILTER_UNTOUCHED}>Show untouched</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handleClearFilters}>Clear filters</Button>
            <Button onClick={exportCsv} variant='contained'>Export</Button>
        </div>
        <div style={{ 'height': 'calc(100vh-200px)' }}>
            {loading? <LoadingScreen /> : 
                error ? <em>{error}</em>:
                rows ? <Box sx={{height: 'calc(100vh - 200px)', width: '100%'}}>
                    { adminButtons }
                    <DataGridPro 
                    apiRef={apiRef}
                    columnVisibilityModel={columnVisibilityModel} 
                    onColumnVisibilityModelChange={setColumnVisibilityModel}
                    filterModel={filterModel}
                    onFilterModelChange={setFilterModel} 
                    sortModel={sortModel}
                    onSortModelChange={setSortModel} 
                    pinnedColumns={pinnedColumns}
                    onPinnedColumnsChange={setPinnedColumns} 
                    getRowHeight={() => 'auto'}
                    rows={rows} 
                    columns={columns} 
                    pagination={false} 
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                    }}
                     getRowClassName={getRowClassName}
                     onColumnWidthChange={onColumnWidthChange}
                    rowSelectionModel={rowSelectionModel}
                    pageSizeOptions={[100, 250, 500, 1000]}
                /></Box> : <em>Nothing to show</em>}
        </div>

        <Modal
          open={modalOpened}
          onClose={() => setModalOpened(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <img src={selectedClipSrc} alt={'Currently selected'} style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </Modal>
    </div>
};

export { ReviewApp }
