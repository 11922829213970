import React, {useState, useRef, useEffect} from 'react';
import {useUserStore} from "../../../contexts";
import {
    Card,
    Box,
    CardActions,
    IconButton,
    Tooltip,
    Button, Stack, ButtonGroup,
} from '@mui/material';
import SearchOffIcon from "@mui/icons-material/SearchOff";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import AnnotationCardInfo from "../AnnotationCardInfo/AnnotationCardInfo";
import AnnotationBBimage from '../AnnotationBBimage/AnnotationBBimage'
import PlacementSelector from "../../Annotations/PlacementSelector";
import ThreeDotsMenu from "../ThreeDotsMenu/ThreeDotsMenu";
import LayersClearSharpIcon from '@mui/icons-material/LayersClearSharp';
import Kdb from "../../Kbd/Kdb";
import VideoModal from "../../VideoModal/VideoModal";
import {
    FALSE_POSITIVE_PLACEMENT_ID, MISSING_PLACEMENT_OBJ, UNSURE_PLACEMENT_OBJ
} from "../../../helpers/data/constants";

function PlacementCard(
    {
        annotation = {},
        detection = {},
        index = -1,
        isSelected = false,
        options,
        setSelectedCard,
        updateAnnotations,
        setPreview,
        openFullImage,
        refreshedSpeedDial,
    }
) {

    const isIann = !!annotation.qdrant_id;
    const placementInput = useRef();
    let initialInputValue = '';
    let optionInitialValue = {};

    if (!!annotation.contexts && annotation.contexts !== -1) {
        const optionSelected = options.find(el => el.id === annotation.contexts);
        if (!!optionSelected) {
            initialInputValue = annotation.contexts.title;
            optionInitialValue = optionSelected;
        }
    }


    const [placementText, setPlacementText] = useState(initialInputValue);
    const [placementValue, setPlacementValue] = useState(optionInitialValue);
    const [rejectedAnnotation, setRejectedAnno] = useState(annotation.contexts === FALSE_POSITIVE_PLACEMENT_ID);
    const [openPlayer, setOpenPlayer] = useState(false);

    const {
        boundingBoxColor,
        cardBackground,
    } = useUserStore();

    const openMediaPlayer = () => {
        setOpenPlayer(true)
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            const {
                code,
                ctrlKey,
                altKey
            } = event;

            if (ctrlKey) {
                if (code === 'KeyZ') {
                    event.preventDefault();
                    if (!!rejectedAnnotation) {
                        return unRejectPlacement()
                    } else {
                        return rejectPlacement();
                    }
                }

                if (code === 'KeyP') {
                    event.preventDefault();
                    setOpenPlayer(true)
                }
            }
            if (altKey) {
                if (code === 'KeyU') {
                    event.preventDefault();
                    unsurePlacement()

                }
                if (code === 'KeyM') {
                    event.preventDefault();
                    missingPlacement()
                }
            }

        }


        if (isSelected && isIann) {
            if (placementInput.current) {
                if (!!annotation.contexts) {
                    placementInput.current?.blur()
                } else {
                    placementInput.current?.focus();
                }
            }
            window.addEventListener('keydown', handleKeyDown);

        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };

    }, [isSelected, rejectedAnnotation, options, placementValue])


    const rejectPlacement = () => {
        setRejectedAnno(true);
        updateAnnotations(index, 'contexts', FALSE_POSITIVE_PLACEMENT_ID);
    }

    const unsurePlacement = () => {
        const unsureLabel = options.find(el => el.title === 'unsure')
        if (unsureLabel) {
            setPlacementValue(unsureLabel)
            updateAnnotations(index, 'contexts', unsureLabel.id);
        }
    }

    const missingPlacement = () => {
        const missingLabel = options.find(el => el.title === 'missing')
        if (missingLabel) {
            setPlacementValue(missingLabel)
            updateAnnotations(index, 'contexts', missingLabel.id);
        }
    }


    const unRejectPlacement = () => {
        setRejectedAnno(false);
        updateAnnotations(index, 'logo', null, true);
        updateAnnotations(index, 'contexts', null, true);
    }


    const getSuggestedPlacement = () => {
        if (!!refreshedSpeedDial &&
            refreshedSpeedDial?.data
                ?.classIdsSpeeddialPlacement?.length > 0
        ) {
            return refreshedSpeedDial.data.classIdsSpeeddialPlacement;
        }

        if(!!annotation.classIdsSpeeddialPlacement){
            return annotation.classIdsSpeeddialPlacement;
        }

        return []
    }

    return (
        <>
            <Card
                raised={true}
                sx={{
                    border: "2px solid",
                    borderColor: isSelected ? 'primary.main' : 'transparent',
                }}
                elevation={isSelected ? 20 : 1}
                onClick={_ => {
                    setSelectedCard(index);
                }}
            >

                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        textAlign: "center",
                        height: "200px",
                        p: '8px',
                    }}
                >
                    <AnnotationCardInfo
                        detection={detection}
                        bgColor={cardBackground}
                    />
                    <AnnotationBBimage
                        bgColor={cardBackground}
                        boxColor={boundingBoxColor}
                        boxCoordinates={detection.meta.dets_bb || detection.meta.dets_bb_src}
                        index={index}
                        media={annotation}
                        openFullImage={openPlayer ? -2 : openFullImage}
                        setPreview={openPlayer ? undefined : setPreview}
                    />
                </Box>
                <CardActions
                    sx={{
                        pt: 2,
                        display: 'flex',
                        borderTop: '1px solid #ccc'
                    }}
                >
                    {
                        rejectedAnnotation
                            ? <Button
                                fullWidth={true}
                                color={'error'}
                                sx={{
                                    "> .hideOnHover": {
                                        display: 'flex',
                                    },
                                    "> .showOnHover": {
                                        display: 'none',
                                    },
                                    "&:hover": {
                                        "> .hideOnHover": {
                                            display: 'none',
                                        },
                                        "> .showOnHover": {
                                            display: 'flex',
                                        }
                                    }
                                }}
                                onClick={_ => {
                                    unRejectPlacement()
                                }}

                            >
                                <Box className="showOnHover"
                                     sx={{
                                         textAlign: 'center',
                                         justifyContent: 'center',
                                         alignItems: 'center',
                                     }}
                                >
                                    Undo Marking as False Positive (<Kdb>Ctrl</Kdb>+<Kdb>Z</Kdb>)
                                </Box>
                                <Box
                                    className="hideOnHover"
                                    sx={{
                                         textAlign: 'center',
                                         justifyContent: 'center',
                                         alignItems: 'center',
                                     }}
                                >
                                    <LayersClearSharpIcon color={'error'}/>
                                    False Positive
                                </Box>
                            </Button>
                            :
                            <Box
                                sx={{
                                    display: "flex",
                                    width: '100%',
                                }}
                            >

                                <Box
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    <PlacementSelector
                                        index={index}
                                        value={placementValue}
                                        onChange={(e, value) => {
                                            setPlacementValue(value);
                                            updateAnnotations(index, 'contexts', value.id);
                                        }}
                                        options={options.filter(el => el.title !== 'missing' && el.title !== 'unsure')}
                                        placementRef={placementInput}
                                        inputText={placementText}
                                        setInputText={setPlacementText}
                                        suggestedPlacements={getSuggestedPlacement()}
                                    />
                                </Box>

                                <ButtonGroup>
                                    <Tooltip
                                        title={
                                            <>
                                                Mark this placement as missing (not in the
                                                list) <br/>(<Kdb>Alt</Kdb>+<Kdb>M</Kdb>)
                                            </>
                                        }
                                    >
                                    <IconButton
                                        size={'small'}
                                        color={'warning'}
                                        onClick={_ => {
                                            missingPlacement()
                                        }}
                                    >
                                        <SearchOffIcon/>
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={
                                            <>
                                                Mark this placement as unsure (not in the
                                                list) <br/>(<Kdb>Alt</Kdb>+<Kdb>U</Kdb>)
                                            </>
                                        }
                                    >
                                    <IconButton
                                        size={'small'}
                                        color={'warning'}
                                        onClick={_ => {
                                            unsurePlacement()
                                        }}
                                    >
                                        <PsychologyAltIcon/>
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={
                                            <>
                                                {!!rejectedAnnotation ? <Box>Undo Marking as false Negative</Box>
                                                    :
                                                    <Box>Mark this card as false
                                                        positive <br/>(<Kdb>Ctrl</Kdb>+<Kdb>Z</Kdb>)</Box>
                                                }
                                            </>
                                        }
                                    >
                                        <IconButton
                                            size={'small'}
                                            color={'error'}
                                            sx={{
                                                pt: 1,
                                            }}
                                            onClick={_ => {
                                                if (!rejectedAnnotation) {
                                                    rejectPlacement()
                                                } else {
                                                    unRejectPlacement()
                                                }
                                            }}
                                        >
                                            <LayersClearSharpIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </ButtonGroup>
                            </Box>
                    }

                    <Box>
                        <ThreeDotsMenu
                            cardId={detection.id}
                            openMediaPlayer={openMediaPlayer}
                            {...annotation}
                        />
                    </Box>
                </CardActions>
            </Card>
            <VideoModal
                clipTimestamp={annotation?.clip_timestamp ?? 0}
                clipUrl={annotation?.clip_url}
                handleClose={_ => setOpenPlayer(false)}
                poster={annotation?.mediaAnnotateUrl}
                open={openPlayer}
                AnnotationBBimage={
                    <AnnotationBBimage
                        bgColor={cardBackground}
                        boxColor={boundingBoxColor}
                        boxCoordinates={detection.meta.dets_bb || detection.meta.dets_bb_src}
                        index={1}
                        media={annotation}
                        openFullImage={openFullImage === index ? 1 : -1}
                        setPreview={setPreview}
                    />
                }
            />
        </>
    )
}

export default PlacementCard;
