import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Grid, Stack,
    Typography,
} from "@mui/material";
import GestureIcon from "@mui/icons-material/Gesture";
import ErrorIcon from "@mui/icons-material/Error";
import "./NoDataContainer.scss";
import EditQueueModal from "../../components/EditQueueModal/EditQueueModal";
import {Queue} from "@mui/icons-material";
import {useState} from "react";
import ListAltIcon from '@mui/icons-material/ListAlt';
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PurgeQueue from "../../components/AppActions/PurgeQueue";
import {useUserStore} from "../../contexts";
import TimerIcon from '@mui/icons-material/Timer';
function NoDataContainer(
    {
        changeQueue,
        getAnnotations,
        purgeQueue,
        queueData,
        queueName,
        state,
    }
) {

    const [showQueueModal, setShowQueueModal] = useState(false);
    const [showPurgeModal, setShowPurgeModal] = useState(false);
    const { isAdmin, user } = useUserStore();
    const isCogito = !!user && user.developer?.companyId === 3429;
    const moreThanOneQueue = queueName.split(',').length >= 2;

    const NODATA_STATES = {
        INTRO: {
            Icon: GestureIcon,
            title: <>Welcome in the Annotation tool</>,
            subtitle: <>
                To get started, select a
                <Button
                    startIcon={<ListAltIcon/>}
                    onClick={_ => setShowQueueModal(true)}
                    sx={{
                        textTransform: 'Capitalize',
                        mx: 1,
                        p: 1,
                        fontSize: '1rem',
                        ":hover": {
                            textDecoration: 'underline',
                        }
                    }}
                    variant={'contained'}
                >
                    queue
                </Button>
            </>,
        },
        CACHE: {
            title: <>
                <span>Please, wait</span>
                <div className={'stage'}>
                    <div className={'dot-falling'}/>
                </div>
            </>,
            subtitle: "We are loading the new Brand library",
        },
        LOADING: {
            title: <>
                <span>Please, wait</span>
                <div className={'stage'}>
                    <div className={'dot-falling'}/>
                </div>
            </>,
            subtitle: "We are fetching new content",
        },
        ERROR: {
            Icon: ErrorIcon,
            title: 'Something went wrong',
            subtitle: 'Sorry! We cannot load any information',
        },
        EMPTY: {
            Icon: ErrorIcon,
            title:moreThanOneQueue? 'Queues are empty': 'Queue is empty',
            subtitle:
                <>
                    It seems there are no more cards on {moreThanOneQueue? 'queues': 'queue'} <span style={{color: '#1DB8E0', marginRight: '.1rem'}}>{queueName}</span><br/>
                    But click "Try now" a few times to make sure 🙂
                    <Stack
                        direction={'row'}
                        spacing={2}
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            my: 1,
                        }}
                    >
                        <Button
                            startIcon={<SearchIcon/>}
                            onClick={_ => getAnnotations(queueName)}
                            sx={{
                                textTransform: 'none',
                                mx: 1,
                                p: 1,
                                fontSize: '1rem',
                                ":hover": {
                                    textDecoration: 'underline',
                                }
                            }}
                            color={'warning'}
                            variant={'contained'}
                        >
                            Try now
                        </Button>
                        <Button
                            startIcon={<ListAltIcon/>}
                            onClick={_ => setShowQueueModal(true)}
                            sx={{
                                textTransform: 'none',
                                mx: 1,
                                p: 1,
                                fontSize: '1rem',
                                ":hover": {
                                    textDecoration: 'underline',
                                }
                            }}
                            variant={'contained'}
                        >
                            Try on a different queue
                        </Button>
                        {(isAdmin || isCogito) &&
                            <Button
                                startIcon={<DeleteForeverIcon/>}
                                onClick={_ => setShowPurgeModal(true)}
                                sx={{
                                    textTransform: 'none',
                                    mx: 1,
                                    p: 1,
                                    fontSize: '1rem',
                                    ":hover": {
                                        textDecoration: 'underline',
                                    }
                                }}
                                disabled={moreThanOneQueue}
                                color={'error'}
                                variant={'contained'}
                            >
                               Purge queue
                            </Button>
                        }
                    </Stack>
                </>
            ,
        },
        FLIGHT: {
            Icon: TimerIcon,
            title: 'Cards are delayed',
            subtitle:
                <>
                    There might still be cards on {moreThanOneQueue? 'queues': 'queue'} <span style={{color: '#1DB8E0', marginRight: '.1rem'}}>{queueName}</span> but they are not available at the moment.<br/>
                    Click "Try now" every minute until they appear (or until this message changes).
                    <Stack
                        direction={'row'}
                        spacing={2}
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            my: 1,
                        }}
                    >
                        <Button
                            startIcon={<SearchIcon/>}
                            onClick={_ => getAnnotations(queueName)}
                            sx={{
                                textTransform: 'none',
                                mx: 1,
                                p: 1,
                                fontSize: '1rem',
                                ":hover": {
                                    textDecoration: 'underline',
                                }
                            }}
                            color={'warning'}
                            variant={'contained'}
                        >
                            Try now
                        </Button>
                        <Button
                            startIcon={<ListAltIcon/>}
                            onClick={_ => setShowQueueModal(true)}
                            sx={{
                                textTransform: 'none',
                                mx: 1,
                                p: 1,
                                fontSize: '1rem',
                                ":hover": {
                                    textDecoration: 'underline',
                                },
                            }}
                            variant={'contained'}
                        >
                            Try on a different queue
                        </Button>
                        {(isAdmin || isCogito) &&
                            <Button
                                startIcon={<DeleteForeverIcon/>}
                                onClick={_ => setShowPurgeModal(true)}
                                sx={{
                                    textTransform: 'none',
                                    mx: 1,
                                    p: 1,
                                    fontSize: '1rem',
                                    ":hover": {
                                        textDecoration: 'underline',
                                    }
                                }}
                                disabled={moreThanOneQueue}
                                color={'error'}
                                variant={'contained'}
                            >
                                Purge queue
                            </Button>
                        }
                    </Stack>
                </>
            ,
        }
    }

    let selectedState = NODATA_STATES['ERROR'];

    if (!!state && !!NODATA_STATES[state.toUpperCase()]) {
        selectedState = NODATA_STATES[state.toUpperCase()]
    } else {
        console.warn('No state selected')
    }

    return (
        <>
            <PurgeQueue
                open={showPurgeModal}
                setOpen={setShowPurgeModal}
                queueName={queueName}
                purgeQueue={purgeQueue}
            />
            <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    minHeight: '60vh',
                    textAlign: 'center'
                }}
            >
                <Grid item xs={8} sx={{mt: 4}}>
                    <Typography
                        variant={'h3'}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {/*   <Icon fontSize={'large'} sx={{mr: 1}}/> */}
                        {!!selectedState.Icon &&
                            <selectedState.Icon
                                fontSize={'large'}
                                sx={{mr: 1}}
                            />
                        }
                        {
                            selectedState.title
                        }
                    </Typography>
                    <Typography
                        variant={'h6'}
                    >
                        {selectedState.subtitle}
                    </Typography>
                </Grid>
            </Grid>
            <EditQueueModal
                open={showQueueModal}
                handleClose={_ => setShowQueueModal(false)}
                queueName={queueName}
                changeQueue={changeQueue}
            />
        </>
    )
}

NoDataContainer.propTypes = {
    state: PropTypes.oneOf([
        'intro',
        'loading',
        'empty',
        'error',
        'cache',
        'flight'
    ]),
}

NoDataContainer.defaultProps = {}

export default NoDataContainer;
