import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Popper,
    Button,
} from "@mui/material";
import {useCacheStore} from "../../../contexts";
import Kdb from "../../Kbd/Kdb";
import LogosImageList from "./LogosImageList/LogosImageList";
import {useEffect, useRef, useState} from "react";

function PopperLogoVersion(
    {
        index = 0,
        isSelected,
        selectedBrand,
        setLogo,
        toBrandSelection,
    }
) {
    const cardHTML = document.getElementById(`LogoGenericCard-${index}`);
    const [anchorEl, setAnchorEl] = useState(isSelected ? cardHTML : null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            const {
                key,
            } = event;
            const open = Boolean(anchorEl);
            if (open) {
                const codeNumber = parseInt(key, 10)

                if (!!key) {
                    if (!(isNaN(codeNumber) || key === ' ')) {
                        event.preventDefault();
                        if (key === "0") {
                            toBrandSelection();
                        }
                        if (codeNumber <= selectedBrand.logoVersions.length) {
                            setLogo(selectedBrand.logoVersions[codeNumber - 1])
                        }
                    }
                }
            }
        }
        if (isSelected) {
            setAnchorEl(cardHTML)
            window.addEventListener('keydown', handleKeyDown);
        } else {
            handleClose()
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isSelected, anchorEl, selectedBrand, selectedBrand.logoVersions])

    const handleClick = (event) => {
        setAnchorEl(cardHTML);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box>
            <Button
                aria-describedby={id}
                onClick={handleClick}
                fullWidth={true}
                variant={open ? 'contained' : 'text'}
            >
                {selectedBrand?.name} Logos
            </Button>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                placement={'right-start'}
            >
                <Box
                    sx={{
                        background: 'white',
                        border: '1px solid #1DB8E0',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        ml: ([2, 5, 8].includes(index)) ? 0 : 1,
                        mr: !([2, 5, 8].includes(index)) ? 0 : 1,
                        position: "relative",
                        ":before": {
                            content: '""',
                            position: "absolute",
                            width: 0,
                            height: 0,
                            borderTop: "10px solid transparent",
                            borderBottom: "10px solid transparent",
                            borderRight: !([2, 5, 8].includes(index)) ? "10px solid #1DB8E0" : null,
                            borderLeft: ([2, 5, 8].includes(index)) ? "10px solid #1DB8E0" : null,
                            top: '50%',
                            left: !([2, 5, 8].includes(index)) ? "-10px" : null,
                            right: ([2, 5, 8].includes(index)) ? "-10px" : null,
                        }
                    }}
                >
                    <Button
                        aria-describedby={id}
                        onClick={toBrandSelection}
                        variant={'outlined'}
                        sx={{m: 2}}
                    >
                        <Box> <Kdb>0</Kdb> Back to brand selection</Box>
                    </Button>
                    <Box
                        sx={{
                            mx: 2,
                        }}
                    >
                        <LogosImageList
                            logos={selectedBrand?.logoVersions}
                            onLogoClick={(e, id) => {
                                setLogo(id)
                            }}
                        />
                    </Box>
                </Box>
            </Popper>

        </Box>
    )
}

PopperLogoVersion.propTypes = {}

PopperLogoVersion.defaultProps = {}

export default PopperLogoVersion;
