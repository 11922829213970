
// Takes an array of arrays as input
// and returns an array of unique elements
export function getUniqueElements(arr) {
    if (arr.length === 0) return []
    // Create an empty Set
    const uniqueElements = new Set();

    // Iterate over the input array
    for (let i = 0; i < arr.length; i++) {
        // Iterate over the subarray
        for (let j = 0; j < arr[i].length; j++) {
            // Add the element to the Set
            uniqueElements.add(arr[i][j]);
        }
    }

    // Return an array of the unique elements
    return Array.from(uniqueElements);
}
