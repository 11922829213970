import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Autocomplete,
    TextField,
    Box,
} from "@mui/material";
import {useState} from "react";
import Kdb from "../Kbd/Kdb";
import {
    useUserStore
} from "../../contexts";
import InfoIcon from '@mui/icons-material/Info';

function EditQueueModal(
    {
        open = false,
        handleClose,
        changeQueue,
        queueName = '',
    }
) {
    const [queues, setQueues] = useState(
        queueName.length === 0 ? [] : queueName.split(',')
    );
    const [txtValue, setTxtValue] = useState('');
    const {
        usedQueue,
        addUsedQueues,
    } = useUserStore();


    const handleKeyDown = event => {
        const {
            code,
            ctrlKey,
        } = event;

        switch (event.key) {
            case ",":
            case " ": {
                event.preventDefault();
                event.stopPropagation();
                const text =  event.target.value
                if (text.length > 0) {
                    if (!queues.includes(text)) {
                        setQueues([...queues, txtValue]);
                        setTxtValue('');
                    }
                }
                break;
            }
            case 'Enter':
                if (ctrlKey) {
                    const text =  event.target.value
                    if (text.length > 0) {
                        if (!queues.includes(text)) {
                            setQueues([...queues, txtValue]);
                            setTxtValue('');
                        }
                    }
                    addUsedQueues(queues)
                    changeQueue(queues.join())
                    handleClose();
                }
                break;
            default:
                break;
        }
    };

    const parsePastedData = (text) => {
        const textSplitted = text.split(/[\s,]+/);
        setQueues([...queues, ...textSplitted]);
        setTxtValue('')
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Change current queues
            </DialogTitle>
            <DialogContent
                sx={
                    {
                        m: 1,
                        p: 3,
                    }
                }
            >

                <DialogContentText>
                    To change the queues in this application, please enter the queue names here. <br/>
                </DialogContentText>

                <Autocomplete
                    id={'changeQueueInput'}
                    sx={{
                        my: 1,
                    }}
                    multiple
                    freeSolo
                    onChange={(event, newValue) => {
                        console.log('enter')
                        setQueues(newValue)
                    }}
                    options={usedQueue}
                    value={queues}
                    inputValue={txtValue}
                    onInputChange={(event, newInputValue) => {
                        setTxtValue(newInputValue);
                    }}
                    onBlur={_ => {
                        if (txtValue.length > 0) {
                            if (!queues.includes(txtValue)) {
                                setQueues([...queues, txtValue]);
                            }
                            setTxtValue('');
                        }
                    }}
                    renderInput={(params) => {
                        params.inputProps.onKeyDown = handleKeyDown;
                        return(
                            <TextField
                                {...params}
                                onPaste={(event) => {
                                    event.preventDefault();
                                    const pastedText = event.clipboardData.getData('Text')
                                    parsePastedData(pastedText);
                                }}
                                onChange={event => {
                                    setTxtValue(event.target.value)
                                }}
                                label="Queues"
                                fullWidth
                            />
                        )
                    }
                }
                />
                <Box
                    sx={{
                        ml: 1,
                        fontSize: '.875em',
                        display: 'grid',
                        gridTemplateColumns: "min-content auto",
                        gridTemplateRows: "1fr 1fr",
                        background: 'rgb(244, 246, 247)',
                        py: 1,
                        px: 2,
                        gridTemplateAreas: `"logo Advice1 Advice1"
                                            "logo Advice2 Advice2"`,
                    }}
                >
                    <Box
                        sx={{
                            gridArea: 'logo',
                        }}
                    >
                        <InfoIcon
                            sx={{
                                mr: 1,
                            }}
                            fontSize={'small'}
                        />
                    </Box>

                    <Box
                        sx={{
                            gridArea: "Advice1",
                            mb: 1,
                        }}
                    >
                        Press <Kdb>Enter</Kdb>,<Kdb>Space</Kdb> or <Kdb>,</Kdb> to add new elements. <br/>
                    </Box>
                    <Box
                        sx={{
                            gridArea: "Advice2"
                        }}
                    >
                        Press <Kdb>Ctrl</Kdb>+<Kdb>Enter</Kdb> to save this queues
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color={'info'} onClick={handleClose}>
                    Undo
                </Button>
                <Button
                    onClick={_ => {
                        addUsedQueues(queues)
                        changeQueue(queues.join())
                        handleClose();
                    }}
                    color={'primary'}
                    disabled={queues.length === 0}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

EditQueueModal.propTypes = {
    show: PropTypes.bool,
}

EditQueueModal.defaultProps = {}

export default EditQueueModal;
