import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Box,
    CardActions,
    Button,
    IconButton,
} from '@mui/material';
import AnnotationCardInfo from "../AnnotationCardInfo/AnnotationCardInfo";
import AnnotationBBimage from '../AnnotationBBimage/AnnotationBBimage'
import Kdb from "../../Kbd/Kdb";
import ThreeDotsMenu from "../ThreeDotsMenu/ThreeDotsMenu";
import './AnnotationCard.scss'
import {useUserStore} from "../../../contexts";

function YnpCard(
    {
        annotation,
        detection,
        index,
        isSelected,
        setSelectedCard,
        showChangeDid,
        updateAnnotations,
        setPreview,
        openFullImage,
    }
) {
    const {
        boundingBoxColor,
        cardBackground,
    } = useUserStore();


    return (
        <Card
            raised={true}
            sx={{
                border: "2px solid",
                borderColor: isSelected ? 'primary.main' : 'transparent',
            }}
            onClick={_ => {
                setSelectedCard(index);
            }}
        >
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    textAlign: "center",
                    height: "200px",
                    p: '8px',
                }}
            >
                <AnnotationCardInfo
                    detection={detection}
                    bgColor={cardBackground}
                />
                <AnnotationBBimage
                    media={annotation}
                    boxCoordinates={detection.meta.dets_bb || detection.meta.dets_bb_src}
                    index={index}
                    bgColor={cardBackground}
                    boxColor={boundingBoxColor}
                    setPreview={setPreview}
                    openFullImage={openFullImage}
                />
            </Box>
            <CardActions>
                <Box
                    className={'ynpCardAction__Grid'}
                    sx={{
                        textAlign: 'center',
                        px: 1,
                        pt: 1,
                    }}
                >
                    <Box>
                        <Button
                            fullWidth
                            onClick={() => updateAnnotations(index, 'approve', 1)}
                            color="success"
                            variant={annotation.approve === 1 ? "contained" : "outlined"}
                            className={'actionButton'}
                            disableRipple={true}
                        >
                            <Box className={'showOnHover'}>
                                <Kdb>
                                    Y
                                </Kdb>
                            </Box>
                            <Box>
                                Yes
                            </Box>
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            fullWidth
                            onClick={() => updateAnnotations(index, 'approve', 0.5)}
                            color="warning"
                            variant={annotation.approve === 0.5 ? "contained" : "outlined"}
                            className={'actionButton'}
                            disableRipple={true}
                        >
                            <Box className={'showOnHover'}>
                                <Kdb>
                                    P
                                </Kdb>
                            </Box>
                            <Box>
                                Perhaps
                            </Box>
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            fullWidth
                            onClick={() => updateAnnotations(index, 'approve', 0)}
                            color="error"
                            variant={annotation.approve === 0 ? "contained" : "outlined"}
                            className={'actionButton'}
                            disableRipple={true}
                        >
                            <Box className={'showOnHover'}>
                                <Kdb>
                                    N
                                </Kdb>
                            </Box>
                            <Box>
                                No
                            </Box>
                        </Button>
                    </Box>
                    <Box>
                        <ThreeDotsMenu
                            cardId={ detection.id }
                            info={ annotation.info }
                            showDeveloperId={showChangeDid}
                        />
                    </Box>
                </Box>
            </CardActions>
        </Card>
    )
}

YnpCard.propTypes = {
    /**
     * Select current card
     */
    setSelectedCard: PropTypes.func,
    /**
     * The current card is selected?
     */
    isSelected: PropTypes.bool,
}

export default YnpCard;
