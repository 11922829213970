import {http} from "../http-common";
import {toast} from "react-toastify";

const mainApiUrl = process.env.REACT_APP_MAIN_URL;
const apiUrl = process.env.REACT_APP_API_URL;


const version = 2;

export const getQueueSizes = (queuesNames) => {
    const params = new URLSearchParams();
    queuesNames.split(',').forEach(queue => params.append("queueName", queue))
    const request = {
        params: params
    };
    return http
        .get(`${mainApiUrl}/annotations/size`, request)
        .then(res => res.data)
        .then(data => data.data)
}
export const getAnnotations = (queueName, limit) => {
    const params = new URLSearchParams({limit, version});
    queueName.split(',').forEach((value) => params.append('queueName', value))

    return http
        .get(
        `${apiUrl}/annotations?`,
            {
                params: params,
            },
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            toast.error(
                "XHR Failed for getAnnotations",
                {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                }
            );
            return Promise.reject(error)
        });
}
export const EmptyQueue = (queueName, params) => {

    return http
        .post(
            `${mainApiUrl}/annotations/queues/${queueName}/purge`,
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            toast.error(
                "XHR Failed for EmptyQueue",
                {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                }
            );
            return Promise.reject(error)
        });
}

export const getSpeedDial = (id) => {
    return http
        .get(`${mainApiUrl}/annotations/mediaDetections/${id}/speeddial`)
        .catch(() => toast.error(`failed to get SpeedDial for ${id}`))
}
