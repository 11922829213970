import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Box,
    CardActions,
    Button,
    IconButton, Tooltip,
} from '@mui/material';
import AnnotationBBimage from '../AnnotationBBimage/AnnotationBBimage'
import Kdb from "../../Kbd/Kdb";
import AutocompleteLogo from "./AutocompleteLogo";
import AutocompleteVersion from "./AutocompleteVersion";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useState, useRef} from "react";
import {useCacheStore, useUserStore} from "../../../contexts";
import UndoIcon from '@mui/icons-material/Undo';
import PlacementSelector from "../../Annotations/PlacementSelector";
import "../YnpCard/AnnotationCard.scss"
import {useEffect} from "react";
import LayersClearSharpIcon from '@mui/icons-material/LayersClearSharp';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import LayersIcon from '@mui/icons-material/Layers';
import ThreeDotsMenu from "../ThreeDotsMenu/ThreeDotsMenu";
const MISSING_LABEL_ID = 195747;
const UNSURE_LABEL_ID = 195746;

function LogoCard(
    {
        annotation,
        detection,
        index,
        isSelected,
        setSelectedCard,
        updateAnnotations,
        placementOptions,
        setPreview,
        openFullImage,
    }
) {

    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedLogoVersion, setSelectedLogoVersion] = useState(null);
    const [rejected, setReject] = useState(annotation.logo?.id === -1);
    const {
        brands,
    } = useCacheStore();


    let noLogoInit =  {
        value: false,
        type: null,
    };
    if (annotation.logo?.id === UNSURE_LABEL_ID) {
        noLogoInit =  {
            value: true,
            type: 1,
        };
    }
    if (annotation.logo?.id === MISSING_LABEL_ID) {
        noLogoInit =  {
            value: true,
            type: 2,
        };
    }

    const [noLogo, setNoLogo] = useState(noLogoInit)
    const placementInput = useRef();
    const logoInput = useRef();
    const versionInput = useRef();

    let initialInputValue = '';
    let optionInitialValue = {};

    if (!!annotation.contexts) {
        const optionSelected = placementOptions.find(el => el.id === annotation.contexts);
        if (!!optionSelected) {
            initialInputValue = annotation.contexts.title;
            optionInitialValue = optionSelected;
        }
    }

    const [placementText, setPlacementText] = useState(initialInputValue);
    const [placementValue, setPlacementValue] = useState(optionInitialValue);

    const {
        boundingBoxColor,
        cardBackground,
    } = useUserStore();

    const backToBrandSelection = () => {
        setSelectedBrand(null);
        setSelectedLogoVersion(null);
    }

    const updateLogoCard = (value) => {
        setSelectedLogoVersion(value);
        updateAnnotations(index, 'logo', value);
        if (placementInput.current) placementInput.current.focus();
    }

    const rejectLogo = () => {
        setReject(true);
        updateAnnotations(index, 'logo', {id: -1});
    }

    const unRejectLogo = () => {
        setReject(false);
        updateAnnotations(index, 'logo', null, true);
    }
    const setNoLabelLogo = (type) => {
        setNoLogo({
            value: true,
            type: type,
        })
        updateAnnotations(index, 'logo', {id: type === 1? MISSING_LABEL_ID : UNSURE_LABEL_ID});
    }

    useEffect(_ => {
        const handleKeyDown = (event) => {
            const {
                code,
                ctrlKey,
            } = event;

            if (ctrlKey) {
                event.preventDefault();
                if (code === 'KeyZ') {
                    if (!!rejected) {
                        return unRejectLogo()
                    } else {
                        return rejectLogo();
                    }
                }
                if (code === 'KeyU') {
                    if (!!noLogo.value) {
                        return setNoLogo({
                            type: null,
                            value: false,
                        })
                    } else {
                        return setNoLabelLogo(2)
                    }
                }
                if (code === 'KeyM') {
                    if (!!noLogo.value) {
                        return setNoLogo({
                            type: null,
                            value: false,
                        })
                    } else {
                        return setNoLabelLogo(1)
                    }
                }
            }
        }
        if (isSelected) {
            if (logoInput.current) logoInput.current.focus();
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isSelected, rejected, noLogo])


    return (
        <Card
            raised={true}
            sx={{
                border: "2px solid",
                borderColor: isSelected ? 'primary.main' : 'transparent',
            }}
            onClick={_ => {
                setSelectedCard(index);
            }}
            id={`LogoGenericCard-${index}`}
        >
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    height: "200px",
                }}
            >
                <AnnotationBBimage
                    media={annotation}
                    boxCoordinates={detection.meta.dets_bb || detection.meta.dets_bb_src}
                    index={index}
                    bgColor={cardBackground}
                    boxColor={boundingBoxColor}
                    setPreview={setPreview}
                    openFullImage={openFullImage}
                />
            </Box>
            <CardActions
            >
                <Box className={`LogoCardAction-container ${rejected && 'rejectedLogoActions'}`}>

                    {
                        rejected
                            ?
                            <Button
                                className={'input-logo-ctn'}
                                    color={'error'}
                                    sx={{
                                        "> .hideOnHover": {
                                            display: 'flex',
                                        },
                                        "> .showOnHover": {
                                            display: 'none',
                                        },
                                        "&:hover": {
                                            "> .hideOnHover": {
                                                display: 'none',
                                            },
                                            "> .showOnHover": {
                                                display: 'flex',
                                            }
                                        }
                                    }}
                                    onClick={_ => {
                                        unRejectLogo()
                                    }}

                            >
                                <Box className="showOnHover"
                                     sx={{
                                         textAlign: 'center',
                                         justifyContent: 'center',
                                         alignItems: 'center',
                                     }}
                                >
                                    Undo Marking as False Positive (<Kdb>CTRL</Kdb>+<Kdb>Z</Kdb>)
                                </Box>
                                <Box className="hideOnHover"
                                     sx={{
                                         textAlign: 'center',
                                         justifyContent: 'center',
                                         alignItems: 'center',
                                     }}
                                >
                                    <LayersClearSharpIcon color={'error'}/>
                                    False Positive
                                </Box>
                            </Button>
                            :
                            <>
                                <Box
                                    className={'input-logo-ctn'}
                                    id={`input-logo-${index}`}
                                >
                                    {
                                        !!noLogo.value
                                            ? <Box className={'input-logo'}>
                                                {
                                                    noLogo.type === 1?
                                                        <p className={'fillAllSpace'}>Missing</p>
                                                        : <p className={'fillAllSpace'}>Unsure</p>
                                                }
                                            </Box>
                                            :
                                            <Box className={'input-logo'}>
                                                {
                                                    !selectedBrand ?
                                                        <AutocompleteLogo
                                                            selectedBrand={selectedBrand}
                                                            setSelectedBrand={setSelectedBrand}
                                                            brands={
                                                                brands.filter(el => {
                                                                    if(!!annotation.logo_version_ids && !!annotation.logo_version_ids.length > 0) {
                                                                        const logos = el.logoVersions.map(l => parseInt(l.id, 10));
                                                                        return logos.filter(l => annotation.logo_version_ids.includes(l)).length > 0
                                                                    }
                                                                    return true;

                                                                })
                                                            }
                                                            inputRef={logoInput}
                                                            index={index}
                                                        />
                                                        : null
                                                }

                                                {
                                                    !!selectedBrand && !selectedLogoVersion ?
                                                        <AutocompleteVersion
                                                            selectedLogoId={selectedBrand.id}
                                                            setSelectedLogoVersion={updateLogoCard}
                                                            removeBrandSelection={backToBrandSelection}
                                                            brands={brands}
                                                            inputRef={versionInput}
                                                            filterOptions={
                                                                (!!annotation.logo_version_ids && !!annotation.logo_version_ids.length > 0)
                                                                ?   annotation.logo_version_ids
                                                                    : []
                                                            }

                                                        />
                                                        : null
                                                }
                                                {
                                                    !!selectedLogoVersion && !!selectedBrand ?
                                                        <Box
                                                            sx={{
                                                                "> .showOnHover": {
                                                                    display: 'none',
                                                                },
                                                                "&:hover": {
                                                                    "> .hideOnHover": {
                                                                        display: 'none',
                                                                    },
                                                                    "> .showOnHover": {
                                                                        display: 'flex',
                                                                    }
                                                                }
                                                            }}
                                                            onClick={() => setSelectedLogoVersion(null)}
                                                        >
                                                            <Box
                                                                className={'hideOnHover'}
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <b>
                                                                    {selectedBrand.name}
                                                                </b>
                                                                <Box
                                                                    sx={{
                                                                        background: `url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
                                                                        ml: 2,
                                                                        p: 1,
                                                                        '& > img': {
                                                                            width: '25px',
                                                                        }
                                                                    }}

                                                                >
                                                                    <img src={selectedLogoVersion.iconUrl} alt=""/>
                                                                </Box>
                                                            </Box>
                                                            <Box
                                                                className={'showOnHover'}
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <UndoIcon
                                                                    sx={{
                                                                        mx: 2,
                                                                    }}
                                                                />
                                                                Change Logo Version (<Kdb>Ctrl</Kdb>+<Kdb>Z</Kdb>)
                                                            </Box>
                                                        </Box>
                                                        : null
                                                }
                                            </Box>
                                    }
                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        {
                                            !!noLogo.value ?
                                                <>
                                                    <Tooltip title={'Undo'}>
                                                        <IconButton
                                                            size={'small'}
                                                            onClick={_ => {
                                                                setNoLogo({
                                                                    type: null,
                                                                    value: false,
                                                                })
                                                            }}
                                                        >
                                                            <UndoIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                                :
                                                <>
                                                    <Tooltip
                                                        title={
                                                            <>
                                                                Mark this brand as missing (not in the list) <br/>(<Kdb>Ctrl</Kdb>+<Kdb>M</Kdb>)
                                                            </>
                                                        }
                                                    >
                                                        <IconButton
                                                            color={'warning'}
                                                            size={'small'}
                                                            onClick={_ => {
                                                                setNoLabelLogo(1)
                                                            }}
                                                        >
                                                            <SearchOffIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={
                                                            <>
                                                                Mark as unsure (cannot tell which brand this is) <br/>(<Kdb>Ctrl</Kdb>+<Kdb>U</Kdb>)
                                                            </>
                                                        }
                                                    >
                                                        <IconButton
                                                            color={'warning'}
                                                            size={'small'}
                                                            onClick={_ => {
                                                                setNoLabelLogo(2)
                                                            }}
                                                        >
                                                            <PsychologyAltIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                        }

                                    </Box>
                                </Box>
                                <Box className={'input-placement'}>
                                    <PlacementSelector
                                        index={index}
                                        value={placementValue}
                                        onChange={(e, value) => {
                                            setPlacementValue(value);
                                            updateAnnotations(index, 'contexts', value.id);
                                        }}
                                        options={placementOptions}
                                        placementRef={placementInput}
                                        inputText={placementText}
                                        setInputText={setPlacementText}
                                    />
                                </Box>
                            </>

                    }
                    <Box
                        sx={{
                            textAlign: 'center',
                            mr: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        className={'Options'}
                    >
                        <Box className={'input-logo-reject'} sx={{ml: 1,}}>
                            <Tooltip
                                title={
                                    <>
                                        {!!rejected ? <Box>Undo Marking as false Negative</Box>
                                            : <Box>Mark this card as false positive <br/>(<Kdb>Ctrl</Kdb>+<Kdb>Z</Kdb>)</Box>
                                        }
                                    </>
                                }
                            >

                                <IconButton
                                    color={!!rejected ? 'warning' : 'error'}
                                    size={'small'}
                                    onClick={_ => {
                                        if (!rejected) {
                                            rejectLogo()
                                        } else {
                                            setReject(false);
                                            updateAnnotations(index, 'logo', null, true);
                                        }

                                    }}
                                >
                                    {
                                        !!rejected
                                            ? <LayersIcon/>
                                            : <LayersClearSharpIcon/>

                                    }
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box>
                           <ThreeDotsMenu
                               cardId={detection.id}
                               info={annotation.info}
                               />
                        </Box>
                    </Box>
                </Box>

            </CardActions>
        </Card>
    )
}

LogoCard.propTypes = {}

LogoCard.defaultProps = {}

export default LogoCard;
