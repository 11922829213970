import React, {useEffect, useState, useCallback} from 'react';
import {ToastContainer} from 'react-toastify';
import { ThemeProvider,} from '@mui/material/styles';

import {
    getAnnotations,
    putNotSubmittedAnnotations
} from '../DataService';
import { useUserStore, CacheProvider } from '../contexts';
import visuaTheme from '../theme';
import { useQueryString } from '../hooks';

import MainContainer from "../containers/MainContainer/MainContainer";

import Header from "../containers/Header/Header";
import Footer from "../containers/Footer/Footer"


import {
    Snackbar,
    Alert,
} from '@mui/material';
import { QueueNameProvider } from '../contexts/queueNameContext';

const headerIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAETklEQVR4nO2Ya4hVVRTHf/PKKTMcMwt7MJlmGZUg2ECKWh+K3g8fWVpUmqRRURRFD8zIhCzsQw8yKij6YCQ9HI2EoCC0B2lavjJLxtHBwh5a1kzNjQX/DYvNOfeeGb1zu3D+sOHsvdY6Z6+991rrfzbkyJGj0ugH3AgsBEZRpRgJbAEKah1ALVWGRmCDcyK0U6hCHAUsAv5xjgygSjAE+AK40o2NBj4DvqNKUAesdqv/GtDkZC1UCRbIgYNAl573AFfzP0U9cBYwXTGwCmh3O3GTO05h7KVKT7oOmADcBbwCfAn8lZCNQns5st3pZO8CwyvhxDHA+pQJtwGt2pE2jZnukc7+Go3/4Y6aHbuHgCP60pH5+vheYClwJzARGOR0rncTPCPajW8kexA4HXjHLYTJzu+rOGjXR8el6JwA/Cyd+yLZDBfs/d24Bf4uyf4FXgQGltEPrtXHvi6is1w6a7UDAXa8fpRsXoJd/6hQdoiPlQUf6iO3p8inuyN1ZiR7RLLtJWKhJaIxrUBzEZZwNnByT5wYAXQD+xXwSSsaAvyBSHYicEAyX93T0KAY+lM2ZnsvcB7wKvAJsNs5+0RPHFlcIvc/Lvm3mojH65LZjnoC+VaRWDOcFrGCfSnZcllWJxpdAI9JkDe71TOHbwMu0861aCe7VDADFrnAtyNSDDOVJQvu6A4DJrkUnwkzZWDELwnLUlbKAvcXPX8AHC39sS6oL884h2OBR4FO2Vl2HOxqUk2Wl3wqg1kJsgnR5H8C3lbAdkeyLr1rR0LFz4ptsh2vfjhuJ5UyPFeKv0W5H63C526i32vlAuz5KmAJsE41Iuju6OV/SKvsb1F/rfoXlDJ8QYrPJcimRiv+cIl3NekoPX0IFXyJvvVklEjmFDOyIva7FM+JZA1umwOjtY+UG3foW3Z8fX2yxUnFRCnZsYgxV7KNyiB/iwHPKvNlwkURu7hO/feLGU1KoSRNohAmu9StVLerJdPK5NCwKFONUd9uZlJhwf2rFBeoLoxQZbWxj5xuTfTTFHZrymF2qE67XxA1GaDnTpHaVExNSKOFBCpyj8baRfY2Or0N+g/JlOszYHOUqQJdsUUuyXq/Uvpsi0jdCh2jTskvlE2txjc53fVKx4fq0HtRpvpY/Ut6+iKbyOwE7mNXoDHMoRuArU7PFuWKHjhUp6w5W0V0T5Splqp/d1YHxmqy4df1eOBNvWRNAln0qNcFxHbn0Dr9mwx3TtWKu00GntJqB+YcN9sZw/3qP5/VkYWuglsaDLgYODXjO8zZW4Efokkd0A4HLhW3Dl1U2L/9zRrbpqz1rPrGlDPB05GCdsN2pTdoUEZb6YhlaPvFyZ5RnWhOuM33dCc0u50piXrRZ294sDcBloAaXatajTguo81OUXs70m8Aj4mll8TQyIl9KpiVQmNvDUc5J9Zo9aoSi1XlZxzGwlYRbHH/ADly5MiRI0eOHPQt/gO2u5D+xDWAiAAAAABJRU5ErkJggg==";

const MainAppRaw = () => {
    const [queueName, setQueueName] = useQueryString('queueName')
    const [annotations, setAnnotations] = useState([]);
    const [queueUrl, setQueueUrl] = useState(null);
    const [queueLimit, setQueueLimit] = useState(9);
    const [username, setUsername] = useState('Not Logged');
    const [isLoading, setIsLoading] = useState(true);
    const [openSnack, setOpenSnack] = useState(false);

    const {user} = useUserStore();

    useEffect(_ => {
        if (user) {
            const {developer} = user;
            if (developer && developer.name) {
                setUsername(developer.name)
            }
        }
    }, [user])


    const getAnnotationsList = useCallback(
        (queueName, queueLimit = 9) => {
            if (!user) return;
            setAnnotations([]);
            setIsLoading(true);

            getAnnotations(queueName, queueLimit)
                .then((annotationsData) => {
                    setQueueUrl(annotationsData.queueUrl);
                    const items = annotationsData.annotation.map(annotation => {
                            return {
                                ...annotation,
                                ...(
                                    annotation.approved !== null
                                    && {approve: annotation.approved}
                                )
                            }
                        }
                    );

                    const filteredItems = items.filter(
                        (annotation) => {
                            return annotation.mediaAnnotateUrl.length !== 0
                        })
                    if (filteredItems.length === 0 && items.length > 0) {
                        setOpenSnack(true);
                    }
                    setAnnotations(filteredItems);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(_ => {
                    setIsLoading(false);
                });
        }, [user]);


    useEffect(() => {
        if (queueName && queueName.length > 0) {
            getAnnotationsList(queueName, queueLimit)
        } else {
            setIsLoading(false);
        }
    }, [queueLimit, queueName, user, getAnnotationsList]);


    const loadMoreAnnotations = () => {
        const notSubmittedAnnotations = {
            queueUrl,
            annotations: annotations
                .filter( ({isSubmitted}) => !isSubmitted)
                .map(({ recieptHandle, id, mediaAnnotateUrl, detection_setting_id }) => ({
                    recieptHandle,
                    id,
                    mediaAnnotateUrl,
                    detectionSettingId: detection_setting_id,
                    detections: []
                }))
        };

        putNotSubmittedAnnotations(notSubmittedAnnotations)
            .then(_ => {
                return getAnnotationsList(queueName, queueLimit)
            });
    };

    return (
        <CacheProvider>
            <ThemeProvider theme={visuaTheme}>
                <Snackbar
                    open={openSnack}  onClose={_ => setOpenSnack(false)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <Alert
                        onClose={_ => setOpenSnack(false)}
                        severity="error"
                        sx={{ width: '100%' }}>
                        No annotation found with a valid mediaAnnotateUrl
                    </Alert>
                </Snackbar>
                <ToastContainer
                    position='top-right'
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <Header
                    username={username}
                    icon={headerIcon}
                />
                <MainContainer
                    {...{
                        isLoading,
                        queueName,
                        setQueueName,
                        annotations,
                        queueUrl,
                        setAnnotations,
                        setQueueLimit,
                        queueLimit,
                        loadMoreAnnotations
                    }}
                    getAnnotations={getAnnotationsList}
                />
                <Footer />

            </ThemeProvider>
        </CacheProvider>
    );
};

const MainApp = (...props) =>
    <QueueNameProvider>
        <MainAppRaw {...props}></MainAppRaw>
    </QueueNameProvider>

export { MainApp };
