import React, {useState, useEffect, createContext, useContext} from 'react';

export const ReviewTableContext = createContext({});

export const ReviewTableProvider = (props) => {

    const [rows, setRows] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [columnsHeads, setColumnsHeads] = useState(null);


    return <ReviewTableContext.Provider
        value={
            {
                rows, setRows,
                loading, setLoading,
                error, setError,
                columnsHeads, setColumnsHeads
            }
        }
        {...props}
    />;
};


