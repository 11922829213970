import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Box,
} from '@mui/material';
import AnnotationBBimage from "../../AnnotationBBimage/AnnotationBBimage";
import {useUserStore} from "../../../../contexts";
import {useEffect, useRef, useState} from "react";
import LogoGenericActions from "./LogoGenericActions";
import {
    LOGO_FP_ID,
    MISSING_LABEL_ID, MISSING_PLACEMENT_OBJ,
    UNSURE_LABEL_ID, UNSURE_PLACEMENT_OBJ,
} from "../../../../helpers/data/constants";
import VideoModal from "../../../VideoModal/VideoModal";


function LogoGenericCard(
    {
        annotation,
        detection,
        index,
        isSelected,
        setSelectedCard,
        updateAnnotations,
        placementOptions,
        setPreview,
        openFullImage,
        refreshedSpeedDial,
    }
) {
    const {
        boundingBoxColor,
        cardBackground,
    } = useUserStore();

    const setInitialPhase = () => {
        const hasSpeedDial = (!!annotation.classIdsSpeeddial
            && annotation.classIdsSpeeddial.length > 0) || (!!refreshedSpeedDial &&
            refreshedSpeedDial?.data
                ?.classIdsSpeeddial?.length > 0
        )
        const hasLogoSet = !!annotation.logo?.id

        if (hasLogoSet) {
            return 'logoDone'
        }
        return hasSpeedDial? 'speedDial' : 'brandPicking'
    }


    const brandsInput = useRef();
    const placementInput = useRef();
    const [phase, setPhase] = useState('speedDial');
    const [openPlayer, setOpenPlayer] = useState(false)

    useEffect(() => {
        setPhase(setInitialPhase())
    }, [annotation.logo])

    const reset = () => {
        updateAnnotations(index, 'logo', null, true);
        setPhase(setInitialPhase())
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            const {
                code,
                ctrlKey,
                altKey,
            } = event;

            if (ctrlKey) {
                if (code === 'KeyZ') {
                    event.preventDefault();
                    if (!!annotation.logo) {
                        reset()
                    } else {
                        updateAnnotations(index, 'logo', {id: LOGO_FP_ID});
                    }
                }
                if (code === 'KeyU') {
                    event.preventDefault();
                    updateAnnotations(index, 'logo', {id: UNSURE_LABEL_ID});
                    setPhase('logoDone');

                }
                if (code === 'KeyM') {
                    event.preventDefault();
                    updateAnnotations(index, 'logo', {id: MISSING_LABEL_ID});
                    setPhase('logoDone');
                }

                if (code === 'KeyP') {
                    event.preventDefault();
                    setOpenPlayer(true)
                }
            }

            if (altKey) {
                if (code === 'KeyU') {
                    event.preventDefault();
                    const unsureLabel = placementOptions.find(el => el.title === 'unsure')
                    if (unsureLabel) {
                        updateAnnotations(index, 'contexts', unsureLabel.id);
                    }
                }
                if (code === 'KeyM') {
                    event.preventDefault();
                    const missingLabel = placementOptions.find(el => el.title === 'missing')
                    if (missingLabel) {
                        updateAnnotations(index, 'contexts', missingLabel.id);
                    }                }
            }

        }

        if (isSelected) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };

    }, [index, isSelected, phase])

    useEffect(() => {
        const isFalsePositive = !!annotation.logo && annotation.logo?.id === LOGO_FP_ID;
        if (phase === 'brandPicking' && isSelected) {
            if (brandsInput.current) return  brandsInput.current?.focus();
        }

        if(phase === 'logoDone' && isSelected) {
            if (isFalsePositive) {
                if (placementInput.current) return placementInput.current?.blur()
            }
            if (placementInput.current) {
                if (!annotation.contexts) {
                    return placementInput.current?.focus();
                }
            }
        }

        if (!isSelected) {
            brandsInput.current?.blur();
            return placementInput.current?.blur()
        }

        /*
        if (!!annotation.logo) {
            setPhase('logoDone');
        } else {
            setPhase(setInitialPhase())
        }
         */



    }, [annotation, annotation.logo, phase, isSelected])

    return (
        <>
            <Card
                raised={true}
                sx={{
                    border: "2px solid",
                    borderColor: isSelected ? 'primary.main' : 'transparent',
                }}
                elevation={isSelected? 20 : 1}
                onClick={() => {
                    setSelectedCard(index);
                }}
                id={`LogoGenericCard-${index}`}
            >
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        height: "200px",
                    }}
                >
                    <AnnotationBBimage
                        bgColor={cardBackground}
                        boxColor={boundingBoxColor}
                        boxCoordinates={detection.meta.dets_bb || detection.meta.dets_bb_src}
                        index={index}
                        media={annotation}
                        openFullImage={openPlayer? -2 : openFullImage}
                        setPreview={openPlayer? undefined: setPreview}
                    />
                </Box>
                    <LogoGenericActions
                        annotation={annotation}
                        brandsInput={brandsInput}
                        detection={detection}
                        index={index}
                        isSelected={isSelected}
                        phase={phase}
                        placementInput={placementInput}
                        placementOptions={placementOptions}
                        resetLogo={reset}
                        setPhase={setPhase}
                        updateAnnotations={updateAnnotations}
                        openPlayer={openPlayer}
                        openMediaPlayer={_ => setOpenPlayer(true)}
                        refreshedSpeedDial={refreshedSpeedDial}
                    />
            </Card>

            <VideoModal
                clipTimestamp={annotation?.clip_timestamp ?? 0}
                clipUrl={annotation?.clip_url}
                handleClose={_ => setOpenPlayer(false)}
                poster={annotation?.mediaAnnotateUrl}
                open={openPlayer}
                AnnotationBBimage={
                    <AnnotationBBimage
                        bgColor={cardBackground}
                        boxColor={boundingBoxColor}
                        boxCoordinates={detection.meta.dets_bb || detection.meta.dets_bb_src}
                        index={1}
                        media={annotation}
                        openFullImage={openFullImage === index? 1 : -1}
                        setPreview={setPreview}
                    />
                }
            />
        </>

    )
}

LogoGenericCard.propTypes = {}

LogoGenericCard.defaultProps = {}

export default LogoGenericCard;
