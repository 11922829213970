import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Stage,
    Layer,
    Image,
    Shape,
} from 'react-konva';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import {useState, useRef, useEffect} from "react";
import useImage from "use-image";
import {Box, Skeleton, Stack, Typography} from "@mui/material";
import {useUserStore} from "../../../contexts";


const scaleBy = 1.5;


function FullSizeImage(
    {
        src,
        coordinates,
        activeDetection = true,
        scalar = 1,
        boxColor = 'black',
        control,
        parentRef,
        rotation = 0,
        setRotation,
        background,
    }
) {
    const mediaElement = useRef(null);
    const stageElem = useRef(null);
    const [width, setWidth] = useState(1);
    const [height, setHeight] = useState(1);
    const [widthImg, setWidthImg] = useState(1);
    const [heightImg, setHeightImg] = useState(1);
    const [supportImgLoaded, setSupportingImgLoaded] = useState(false);
    const [movePosition, setMovePosition] = useState({});
    const [initialStagePosition, setInitialStagePosition] = useState({x: 0, y: 0});
    const [image, status] = useImage(src);
    const [zoomLevel, setZoomLevel] = useState({x: 1, y: 1})
    const [imagePosition, setImagePosition] = useState({x: 0, y: 0});
    const {
        showBB,
    } = useUserStore();


    useEffect(_ => {
        if (parentRef) {
            setHeight(parentRef.current.clientHeight);
            setWidth(parentRef.current.clientWidth);
        }
    }, [width, height])


    const cropSectionFunction = (context) => {
        if (coordinates) {
            context.beginPath();
            context.moveTo(coordinates[0] * scalar, coordinates[1] * scalar);
            context.lineTo(coordinates[2] * scalar, coordinates[3] * scalar);
            context.lineTo(coordinates[4] * scalar, coordinates[5] * scalar);
            context.lineTo(coordinates[6] * scalar, coordinates[7] * scalar);
            context.closePath();
        }
    }

    const zoom = (type) => {
        const stage = stageElem.current;

        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        // how to scale? Zoom in? Or zoom out?
        let direction = type === 'in' ? 1 : -1;
        const newScale = direction > 0 ? oldScale * 1.5 : oldScale / 1.5;
        stage.scale({x: newScale, y: newScale});
        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };
        setImagePosition(newPos);
        stage.position(newPos);
    }

    const resetToDefault = () => {
        const stage = stageElem.current;
        setRotation(0);

        stage.scale(
            {
                x: 1,
                y: 1
            }
        );
        const newPos = {
            x: 0,
            y: 0,
        };
        setImagePosition(newPos);
        stage.position(newPos);
    }

    const rotate = () => {
        const stage = stageElem.current;

        const newZoom = {
            x: 1,
            y: 1,
        };
        let newPosition = {
            x: 0,
            y: 0,
        }

        if (rotation === 0) {
            setRotation(-90);
            newPosition = {
                x: 0,
                y: widthImg,
            };
        }
        if (rotation === -90) {
            setRotation(-180);
            newPosition = {
                x: widthImg,
                y: heightImg,
            };
        }
        if (rotation === -180) {
            setRotation(-270);
            newPosition = {
                x: heightImg,
                y: 0,
            };
        }
        if (rotation === -270) setRotation(0);

        setZoomLevel(newZoom);
        setImagePosition(newPosition);
        stage.scale(newZoom);
        stage.position(newPosition);
    }

    const onWheel = (e) => {
        // stop default scrolling
        e.evt.preventDefault();
        const stage = stageElem.current;

        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        // how to scale? Zoom in? Or zoom out?
        let direction = e.evt.deltaY > 0 ? 1 : -1;

        // when we zoom on trackpad, e.evt.ctrlKey is true
        // in that case lets revert direction
        if (e.evt.ctrlKey) {
            direction = -direction;
        }

        const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
        setZoomLevel({x: newScale, y: newScale});
        stage.scale({x: newScale, y: newScale});

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };
        setImagePosition(newPos);
        stage.position(newPos);
    }

    const onDoubleClick = (e) => {
        const {evt} = e;
        const isRightClick = !!e && !!evt && evt.which === 3
        if (
            !isRightClick
            && (control !== 'zoomIn' && control !== 'zoomOut')
        ) resetToDefault();
    }

    const onRightClick = (e) => {
        if (!!e && !!e.evt) e.evt.preventDefault();
        rotate()
    }

    const onLeftClick = (e) => {
        if (!!e && !!e.evt) e.evt.preventDefault();
        if (control === 'reset') {
            resetToDefault();
        }
        if (control === 'zoomIn') {
            zoom('in')
        }

        if (control === 'zoomOut') {
            zoom('out')
        }
        if (control === 'rotate') {
            rotate()
        }
    }
    const onMouseUp = (e) => {
        if (!!e && !!e.evt) e.evt.preventDefault();
        setMovePosition({});
    }
    const onMouseDown = (e) => {
            e.evt.preventDefault();
            if (control === 'move') {
                const stage = stageElem.current;
                console.log({stage})
                const currentPosition = stage.getPosition();
                const pointer = stage.getPointerPosition();
                setMovePosition(pointer);
                setInitialStagePosition(currentPosition);
            }
    }

    const onMouseMove = (e) => {
        const isMovePositionEmpty = movePosition // 👈 null and undefined check
        && Object.keys(movePosition).length === 0
        && Object.getPrototypeOf(movePosition) === Object.prototype;

        if (control === 'move' && !isMovePositionEmpty) {
            const stage = stageElem.current;
            const pointer = stage.getPointerPosition();
            const diff = {
                x: -(movePosition.x - pointer.x),
                y: -(movePosition.y - pointer.y),
            }

            const mousePointTo = {
                x: (diff.x + stage.x()),
                y: (diff.y + stage.y()),
            };

            setImagePosition(mousePointTo);
            stage.position(
                {
                    x: initialStagePosition.x + diff.x,
                    y: initialStagePosition.y + diff.y,
                }
            )
        }
    }

    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '1px',
                    outline: '1px solid',
                    background: '#FFF',
                    width: '100%',
                    zIndex: 10,
                    pointerEvents: 'none',
                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
            >
                <Stack
                    direction={'row'}
                    sx={{
                        justifyContent: 'space-around',
                    }}
                >
                    <Box>
                        <Typography variant={'body1'}>
                            <strong>
                                Zoom
                            </strong>:
                            <span>
                                {zoomLevel.x.toFixed(2)}
                                </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant={'body1'}>
                            <strong>
                                Rotation
                            </strong>:
                            <span>
                                {Math.abs(rotation)}°
                                </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant={'body1'}>
                            <strong>
                                Position
                            </strong>:
                            <span>
                                ({imagePosition.x.toFixed(0)}, {imagePosition.y.toFixed(0)})
                                </span>
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            <img
                src={src}
                alt=""
                ref={mediaElement}
                onLoad={_ => {
                    const {
                        current
                    } = mediaElement;
                    if (current && current.height) setHeightImg(current.height);
                    if (current && current.width) setWidthImg(current.width);
                    setSupportingImgLoaded(true);
                }}
                style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    zIndex: 100,
                    display: supportImgLoaded ? 'none' : 'inline'
                }}
            />
            {
                status === 'failed'
                && <h3>
                    <BrokenImageIcon fontSize={'large'}/> <br/>
                    Failed to load the image
                </h3>
            }
            {
                status === 'loading'
                && <Skeleton
                    width={width}
                    height={height}
                />
            }
            {
                status === 'loaded' &&
                <Stage
                    rotation={rotation}
                    style={{border: '1px solid black'}}
                    width={width}
                    height={height}
                    ref={stageElem}
                    onWheel={onWheel}
                    onContextMenu={onRightClick}
                    onClick={onLeftClick}
                    onDblClick={onDoubleClick}
                    onDblTap={onDoubleClick}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    onMouseMove={onMouseMove}
                    onMouseLeave={onMouseUp}
                >
                    <Layer>
                        <Image
                            x={0}
                            y={0}
                            width={widthImg}
                            height={heightImg}
                            image={image}
                            opacity={activeDetection ? 0.2 : 1}
                        />
                    </Layer>

                    <Layer clipFunc={(context) => {
                        cropSectionFunction(context);
                    }}>
                        <Image
                            x={0}
                            y={0}
                            width={widthImg}
                            height={heightImg}
                            image={image}
                        />
                    </Layer>

                    {
                        showBB &&
                        <Layer>
                            <Shape
                                sceneFunc={(context, shape) => {
                                    if (coordinates) {
                                        cropSectionFunction(context)
                                        context.fillStrokeShape(shape);
                                    }
                                }}
                                fill="transparent"
                                stroke={'#000'}
                                strokeWidth={6}
                                shadowBlur={5}
                            />
                            <Shape
                                sceneFunc={(context, shape) => {
                                    if (coordinates) {
                                        cropSectionFunction(context)
                                        context.fillStrokeShape(shape);
                                    }
                                }}
                                fill="transparent"
                                stroke={boxColor}
                                strokeWidth={2}
                                shadowBlur={5}
                            />
                        </Layer>
                    }

                </Stage>


            }

        </Box>
    )
}

FullSizeImage.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string,
    activeDetection: PropTypes.bool,
    scalar: PropTypes.number,
}

FullSizeImage.defaultProps = {
    width: 500,
    height: 500,
    scalar: 1,
}

export default FullSizeImage;
