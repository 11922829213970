import React, {useEffect, useState, Suspense, lazy} from 'react';
import {
    LinearProgress,
} from '@mui/material';
import {
    EmptyQueue as PurgeMessage,
    getQueueSizes,
} from '../../service/serviceAPI';
import NoDataContainer from "../NoDataContainer/NoDataContainer";
import {toast} from 'react-toastify';
import {
    useCacheStore,
} from "../../contexts";

import GridContainer from "../Annotations/GridContainer";
const DrawingMode = lazy(_ => import("../Detections/DrawingMode"))

const BOX_COLORS = ['#D32F2F', '#2FD32F'];
const BG_COLORS = [
    `url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
    '#f2f2f2',
    '#565656'
];



function MainContainer(
    {
        isLoading = false,
        queueName = '',
        setQueueName,
        annotations = [],
        queueUrl,
        setAnnotations,
        getAnnotations,
        setQueueLimit,
        loadMoreAnnotations,
        queueLimit,
    }
) {
    const [queueData, setQueueData] = useState([]);
    const [mode, setMode] = useState('intro');
    const {isCacheLoading} = useCacheStore();
    const [bgColor, setBgColor] = useState(BG_COLORS[0]);
    const [boxColor, setBoxColor] = useState(BOX_COLORS[0]);

    const purgeQueue = () => {
        PurgeMessage(queueName)
            .then(_ => {
                setAnnotations([]);
                toast.success('Purge successful')
            })
            .catch(_ => {
                toast.error('Purge operation failed')
            })
    }


    useEffect(_ => {
        const setModality = () => {
            if (!!isCacheLoading) {
                return setMode('cache')
            }
            if (queueName === '') {
                setMode('intro');
            } else {

                if (annotations.length === 0) {
                    if (queueData.reduce((acc, curr) => acc + curr?.messagesInFlight, 0) === 0){
                        setMode('empty');
                    } else {
                        setMode('flight');
                    }
                } else {
                    const firstAnnotation = annotations[0];
                    const {
                        id,
                        class_ids,
                        ocr,
                    } = firstAnnotation;

                    if (typeof id !== "undefined") {

                        if (id.includes('med_')) {
                            setMode('BondingBox')
                        } else {
                            if (!!ocr) {
                                setMode('ocr');
                            } else if (!!class_ids) {
                                setMode('dataset');
                            } else {
                                setMode('ynp');
                            }
                        }
                    } else {
                        setMode('Error')
                    }
                }
            }
        }

        setModality();
    }, [queueName, annotations, isCacheLoading,queueData])


    useEffect(() => {
        getQueueSizes(queueName)
            .then(resp => {
                const {
                    queues
                } = resp;
                if (!!queues && queues.length > 0) {
                    setQueueData(queues)
                }
            })
            .catch(err => {
                console.error(err);
                return toast.error(
                    "Get Queue sizes: Operation failed",
                    {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                    }
                );
            })
    }, [queueName, annotations])

    return (
        <main
            style={{
                minHeight: 'calc(100vh - 135px)',
            }}
        >
            <Suspense fallback={<LinearProgress/>}>
            {isLoading && <LinearProgress/>}
            {
                (
                    mode === 'intro' ||
                    mode === 'empty' ||
                    mode === 'error' ||
                    mode === 'cache' ||
                    mode === 'flight' ||
                    isLoading
                )
                &&
                <NoDataContainer
                    state={isLoading ? 'loading' : mode}
                    queueData={queueData}
                    queueName={queueName}
                    getAnnotations={getAnnotations}
                    changeQueue={setQueueName}
                    purgeQueue={purgeQueue}
                />
            }
            {
                mode === 'BondingBox' &&
                <DrawingMode
                    annotations={annotations}
                    setAnnotations={setAnnotations}
                    queueUrl={queueUrl}
                    handleLoadMoreAnnotations={loadMoreAnnotations}
                    changeQueueLimit={setQueueLimit}
                    purgeQueue={purgeQueue}
                    queueInfos={
                        {
                            data: queueData,
                            limit: queueLimit,
                            name: queueName,
                            setQueue: setQueueName,
                            setQueueData: setQueueData,
                        }
                    }
                    mainMode={mode}

                />
            }
            {
                (
                    mode === 'ocr' ||
                    mode === 'dataset' ||
                    mode === 'ynp'
                ) &&
                <GridContainer
                    annotations={annotations}
                    queueUrl={queueUrl}
                    getAnnotations={getAnnotations}
                    queueName={queueName}
                    purgeQueue={purgeQueue}
                    queueInfos={
                        {
                            data: queueData,
                            name: queueName,
                            setQueue: setQueueName,
                            setQueueData:setQueueData,
                        }
                    }
                    bgColor={bgColor}
                    setBgColor={setBgColor}
                    boxColor={boxColor}
                    setBoxColor={setBoxColor}
                />
            }
            </Suspense>
        </main>
    )
}

MainContainer.propTypes = {}


export default MainContainer;
