import * as React from 'react';
import PropTypes from "prop-types";
import {
    Box,
    Grid,
    Chip,
    Toolbar,
    Button,
    Tooltip,
    IconButton,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import {
    getQueueSizes
} from "../../service/serviceAPI";
import {useState} from "react";
import EditQueueModal from "../EditQueueModal/EditQueueModal";
import {keyframes} from "@emotion/react";
import {useUserStore} from "../../contexts";

import ListAltIcon from '@mui/icons-material/ListAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

const textEffect = keyframes`
  to {
    background-position: 200% center;
  }
`;


function PurgeDialog(
    {
        open,
        setOpen,
        queueName,
        purgeQueue,
    }
) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-purge-title"
                aria-describedby="alert-purge-description"
            >
                <DialogTitle id="alert-purge-title">
                    {"Irreversible operation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-purge-description">
                        Are you sure you want to purge all the messages from the queue <b>{queueName}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color={'info'} onClick={handleClose}>Cancel</Button>
                    <Button onClick={_ => {
                        handleClose();
                        return purgeQueue();
                    }} color={'error'}>
                        Purge!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function AppToolbar(
    {
        Actions,
        purgeQueue,
        queueData,
        queueName = '',
        setQueueName,
        setQueueData,
    }
) {
    const [showPurgeModal, setShowPurgeModal] = useState(false);
    const [showEditQueueModal, setShowEditQueueModal] = useState(false);
    const [hoverQueueIcon, setHoverQueueIcon] = useState(false);
    const [isUploadingQueueSize, setIsUploadingQueueSize] = useState(false);
    const { user } = useUserStore();
    const isAdmin = user.permission.includes('admin');
    const isCogito = user.developer?.companyId === 3429;

    const showPurgeBtn =
        queueName.split(',').length === 1
        && (isAdmin || isCogito);



    return (
        <Box
            sx={{
                position: 'sticky',
                top: 0,
                zIndex: 20,
            }}
        >
            <Toolbar sx={{
                background: '#DEE3E8',
            }}>
                <Grid
                    className={'queue-data'}
                    container={true}
                    spacing={3}
                    alignItems={'center'}
                    sx={{
                        color: '#71849d',
                    }}
                >
                    <Grid item={true}>
                        <div
                            className="queue-info"
                        >
                            <IconButton
                                sx={{
                                    mr: 1,
                                }}
                                onMouseEnter={_ => setHoverQueueIcon(true)}
                                onMouseLeave={_ => setHoverQueueIcon(false)}
                                onClick={_ => setShowEditQueueModal(true)}
                            >
                                {hoverQueueIcon ?
                                    <EditIcon
                                        sx={{
                                            color: 'primary.main',
                                        }}
                                    />
                                    :
                                    <ListAltIcon
                                        sx={{
                                            color: 'primary.main',
                                        }}
                                    />
                                }
                            </IconButton>
                            <Chip
                                sx={{
                                    ".MuiChip-label": {
                                        display: 'flex',
                                        alignItems: 'center',
                                    }
                                }}
                                label={
                                    <>
                                        <Tooltip title={<div style={{display: 'table', borderCollapse: "separate", borderSpacing: "5px"}}>
                                            <div style={{display: 'table-row', borderBottom: '1px solid #1DB8E0'}}>
                                                <div style={{display: 'table-cell', paddingRight: '10px', fontWeight: 'bold', borderBottom: '1px solid #1DB8E0'}}>
                                                    <b>
                                                        queueName
                                                    </b>
                                                </div>

                                                <div style={{display: 'table-cell', paddingRight: '10px', textAlign: 'right', borderBottom: '1px solid #1DB8E0'}}>
                                                    Available
                                                </div>
                                                <div style={{display: 'table-cell', paddingRight: '10px', textAlign: 'right', borderBottom: '1px solid #1DB8E0' }}>
                                                    In flight
                                                </div>
                                            </div>
                                            {
                                                queueData.map((queue, index) => (
                                                    <div key={index} style={{display: 'table-row', marginTop: '5px'}}>
                                                       <div style={{display: 'table-cell', paddingRight: '10px', fontWeight: 'bold'}}>
                                                               {queue.queueName.replace(/^production-annotate_/, "")}
                                                       </div>
                                                        <div style={{display: 'table-cell',  paddingRight: '10px', textAlign: 'right'}}>
                                                            {queue.messagesAvailable?? "-"}
                                                        </div>
                                                        <div style={{display: 'table-cell',  paddingRight: '10px', textAlign: 'right'}}>
                                                            {queue.messagesInFlight?? "-"}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>}>
                                        <Typography
                                            variant={'body1'}
                                            sx={{
                                                color: "secondary.main",
                                                fontWeight: "bold",
                                                fontSize: "0.8rem",
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            onClick={_ => {
                                                setIsUploadingQueueSize(true);

                                                getQueueSizes(queueName)
                                                    .then(resp => {
                                                        const {
                                                            queues
                                                        } = resp;
                                                        setQueueData(queues)

                                                    })
                                                    .finally(_ => {
                                                        setIsUploadingQueueSize(false);
                                                    })
                                            }}
                                        >
                                            <span style={{
                                                maxWidth: "250px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: 'inline-block',
                                                color: '#02264D'
                                            }}>
                                                {queueName}
                                            </span>
                                            (
                                            {
                                                !!isUploadingQueueSize
                                                    ? <Box
                                                        sx={{
                                                            "textTransform": "uppercase",
                                                            backgroundImage: `linear-gradient(
                                                                -225deg,
                                                                #0088AE 0%,
                                                                #1DB8E0 29%,
                                                                #D2F1F9 67%,
                                                                #BCC5CF 100%
                                                            )`,
                                                            backgroundSize: "200% auto",
                                                            backgroundClip: "text",
                                                            color: "#fff",
                                                            textFillColor: "transparent",
                                                            WebkitBackgroundClip: "text",
                                                            WebkitTextFillColor: "transparent",
                                                            animation: `${textEffect} 1s linear infinite`,
                                                            display: 'inline-block',
                                                        }}
                                                        as={'span'}
                                                    >
                                                        ???
                                                    </Box>
                                                    : <span style={{color: '#c85142', fontSize: '1rem', textDecoration: 'underline'}}>
                                                        {queueData.reduce((acc, curr) => acc + curr?.messagesAvailable, 0) + queueData.reduce((acc, curr) => acc + curr?.messagesInFlight, 0)}
                                                    </span>
                                            }
                                            )
                                        </Typography>

                                        </Tooltip>
                                        {
                                            showPurgeBtn &&
                                            <Tooltip title={'Clear current queue'}>
                                                <IconButton
                                                    size={'small'}
                                                    color={'error'}
                                                    onClick={_ => {
                                                        setShowPurgeModal(true)
                                                    }}
                                                >
                                                    <DeleteForeverIcon fontSize={'small'}/>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </>
                                }
                            />


                        </div>
                    </Grid>
                    {Actions}
                </Grid>
            </Toolbar>
            <PurgeDialog
                open={showPurgeModal}
                setOpen={setShowPurgeModal}
                queueName={queueName}
                purgeQueue={purgeQueue}
            />
            <EditQueueModal
                open={showEditQueueModal}
                handleClose={_ => setShowEditQueueModal(false)}
                changeQueue={setQueueName}
                queueName={queueName}
            />
        </Box>
    )
}

AppToolbar.propTypes = {
    /**
     * Name of the queue
     */
    queueName: PropTypes.string,
    /**
     * Number of messages
     */
    queueSize: PropTypes.number,
    /**
     * Purge Queue server call
     */
    purgeQueue: PropTypes.func,
    /**
     * Is the current user an admin?
     */
    isAdmin: PropTypes.bool,
    /**
     * Expected a MUI Grid Item (or a group of them)
     */
    Actions: PropTypes.node,
}

export default AppToolbar;
