import * as React from 'react';
import PropTypes from 'prop-types';
import {Box, Divider, IconButton, Stack, Tooltip} from "@mui/material";
import DrawingToolAction from "../../Detections/DrawingMode/DrawingTools/DrawingToolAction";
import PanToolIcon from "@mui/icons-material/PanTool";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
function FullSizeImageTools(
    {
        control,
        setControl,
        setHighlight,
        highlight,
    }
) {
    return (
        <Stack
            sx={{
                background: '#f2f2f2',
                position: 'sticky',
                top: 0,
                width: '100%',
                height: '100%',
                px: 1,
                pt: 2,
            }}
            spacing={1}
        >
            <DrawingToolAction
                tooltipText={'Move the image'}
                control={control}
                setControl={setControl}
                actionName={'move'}
                Icon={PanToolIcon}
            />
            <DrawingToolAction
                tooltipText={'Reset image to default settings [DbClick]'}
                control={control}
                setControl={setControl}
                actionName={'reset'}
                Icon={RestartAltIcon}
            />
            <DrawingToolAction
                tooltipText={'Zoom In the image'}
                control={control}
                setControl={setControl}
                actionName={'zoomIn'}
                Icon={ZoomInIcon}
            />
            <DrawingToolAction
                tooltipText={'Zoom Out the image'}
                control={control}
                setControl={setControl}
                actionName={'zoomOut'}
                Icon={ZoomOutIcon}
            />
            <DrawingToolAction
                tooltipText={'Rotate 90° the image'}
                control={control}
                setControl={setControl}
                actionName={'rotate'}
                Icon={Rotate90DegreesCcwIcon}
            />
            <Divider
                sx={{
                    background: '#fff'
                }}
            />
            <Tooltip
                title={'Highlight Detection'}
                placement={'right'}
            >
                <Box
                    sx={{
                        color: '#fff',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        p: 1,
                    }}
                >
                    <IconButton
                        onClick={_ => {
                            setHighlight(!highlight)
                        }}
                        sx={{
                            color: highlight ? '#1DB8E0' : '#8092A6'

                        }}
                        aria-label={'Highlight Detection'}
                    >
                        {
                            highlight
                                ? <VisibilityIcon/>
                                : <VisibilityOffIcon/>
                        }
                    </IconButton>
                </Box>
            </Tooltip>
        </Stack>
    )
}

FullSizeImageTools.propTypes = {

}

FullSizeImageTools.defaultProps = {

}

export default FullSizeImageTools;
