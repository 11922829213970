import React, {
    useState,
    useEffect,
    createContext,
    useContext,
    useMemo
} from 'react';

import {
    brandsUrl,
    getVisualClassData,
    getBrandsCache
} from '../DataService';

export const CacheContext = createContext({});

export const CacheProvider = (props) => {
    const [isCacheLoading, setIsCacheLoading] = useState(false);
    const [brands, setBrands] = useState([]);
    const [objects, setObjects] = useState([]);

    useEffect(() => {
        if (brandsUrl.length === 0) {
            return;
        }
        const brandUrlId = brandsUrl.split('.json')[0];
        if (!brandUrlId) return;

        window
            .caches
            .match(brandUrlId)
            .then((res) => {
                    if (!!res) {
                        return cacheBrands(res);
                    }
                    setIsCacheLoading(true);
                    return getBrandsCache()
                }
            )
            .then((res) => {
                if (!res) return;
                let responseClone = res.clone();
                caches.open('brands')
                    .then(cache => {

                        return cache
                    })
                    .then(
                    (cache) =>
                        cache.put(brandUrlId, responseClone)
                    );
                return cacheBrands(res);
            })
            .then(_ => {
                setIsCacheLoading(false);
            })

            .catch(err => {
                console.error('cache error', err)
            })
            .finally(_ => {
                //setIsCacheLoading(false);
            });

        if (!localStorage.getItem('objects')) {
            localStorage.setItem('objects', JSON.stringify(objects));
        } else {
            setObjects(JSON.parse(localStorage.getItem('objects')));
        }
    }, [brandsUrl]);

    const cacheBrands = (res) => {
        let jsonRes = res.json();
        return jsonRes
            .then(
                data => {
                    setBrands(data)
                    return Promise.resolve()
                }
            );
    }


    const cacheVisualClassData = (ids) => getVisualClassData(ids)
        .then(({mediaLabel}) => {
            if (!!mediaLabel) {
                const objectsCacheUpdate = [...objects];

                mediaLabel.forEach((mediaLabel) =>
                    !objectsCacheUpdate.some((object) => mediaLabel.id === object.id) &&
                    objectsCacheUpdate.push(mediaLabel)
                );

                localStorage.setItem('objects', JSON.stringify(objectsCacheUpdate));
            }
        })
        .then(() => setObjects(JSON.parse(localStorage.getItem('objects'))))

    const memoizedBrandsOptions = useMemo(
        () => brands.map(brand => ({
            label: brand.name,
            id: brand.id
        })),
        [brands]
    );

    const memoizedLogoVersions = useMemo(
        () => brands.map(
            brand => {
                return brand.logoVersions.map(
                    logo => {
                        return {
                            label: brand.name,
                            ...logo
                        }
                    }
                )
            }
        ).flat(),
        [brands]
    )

    const memoizedObjectsOptions = useMemo(
        () => objects.map(object => ({
            label: object.description ? object.description : object.datasetClassCode,
            id: object.id
        })),
        [objects]
    );

    return (
        <CacheContext.Provider
            value={{
                brands,
                logos: memoizedLogoVersions,
                objects,
                brandsOptions: memoizedBrandsOptions,
                objectsOptions: memoizedObjectsOptions,
                isCacheLoading,
                cacheVisualClassData
            }}
            {...props}
        />
    );
};

export const useCacheStore = () => useContext(CacheContext);
