import React from 'react';
import ReactDom from 'react-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { UserProvider } from 'contexts';

import './styles/reset.scss';
import './styles/styles.scss';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';

import { ConfirmProvider } from "material-ui-confirm";

LicenseInfo.setLicenseKey('a52e73158be3f0873db0d4fdf8b7f37fTz03MjQyMyxFPTE3MjMyMDIyMDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const app = (
    <UserProvider>
        <ConfirmProvider>
            <App />
        </ConfirmProvider>
    </UserProvider>
);

const root = document.getElementById('app');

ReactDom.render(app, root);
