import React, { useMemo } from 'react';

import {  CORRECTION_FILTER_WRONG_PLACEMENT, STATUS_LABEL_CHECKED, STATUS_TITLE_ROW } from 'helpers/data/constants';
import { useQueryString, useLoadAnnotations } from '../../hooks';


import { DataGridPro } from '@mui/x-data-grid-pro';

import {
    Box,
} from "@mui/material";


import {
   CORRECTION_STATUS_TITLE_ROW,
   STATUS_LABEL_CORRECTED,
   CORRECTION_FILTER_BOTH,
   INSIGHT_TABLE_ROW_HEIGHT,
   TYPE_TITLE_ROW,
   ANNOTATION_TYPE_ANNOTATION,
   ANNOTATION_TYPE_PROPAGATION,
   ANNOTATION_TYPE_TOTAL ,
   CORRECTION_FILTER_WRONG_LOGO,
   QUEUE_NAME_URL_ARG,
} from '../../helpers/data/constants'



const ORANGE_THRESHOLD = 0.1;
const RED_THRESHOLD = 0.8;

export const RateRenderer = ({ value, color }) => {
   const style = {
      color: 
         value > RED_THRESHOLD ? 'red' : value > ORANGE_THRESHOLD ? 'orange' : 'inherit'
   } 
   return <span style={style}>{`${value * 100}`.slice(0, 5)}%</span>
}

const AnnotationTypeRenderer = ({ value }) => {
    if(value === ANNOTATION_TYPE_TOTAL) {
        return <strong style={{ fontWeight: 'bold'}}>{ANNOTATION_TYPE_TOTAL}</strong>
    } else {
        return <>{value}</>
    }
}

const initialRows = annoType => ({
      annoType: annoType,
      placementTotal: 0,
      checked: 0,
      errorCount: 0,
      checkedPercentage: 0,
      errorPercentage: 0,
   })

const types = [ANNOTATION_TYPE_ANNOTATION, ANNOTATION_TYPE_PROPAGATION, ANNOTATION_TYPE_TOTAL];

export const addRowToStatRow = (row, statRow) => {
      // Check if the annoType matches the row type or is the total annotation type
      if (statRow.annoType === row[TYPE_TITLE_ROW] || statRow.annoType === ANNOTATION_TYPE_TOTAL) {
         return {
            ...statRow,
            placementTotal: statRow.placementTotal + 1,
            checked: row[STATUS_TITLE_ROW] === STATUS_LABEL_CHECKED || row[STATUS_TITLE_ROW] === STATUS_LABEL_CORRECTED ?
               statRow.checked + 1 :
               statRow.checked,
            errorCount:
               row[CORRECTION_STATUS_TITLE_ROW] === CORRECTION_FILTER_WRONG_LOGO ||
               row[CORRECTION_STATUS_TITLE_ROW] === CORRECTION_FILTER_WRONG_PLACEMENT ||
               row[CORRECTION_STATUS_TITLE_ROW] === CORRECTION_FILTER_BOTH ?
                  statRow.errorCount + 1 :
                  statRow.errorCount
         };
      } else {
         return statRow;
      }
   };

export const computeAggregatedRows = (rows) => { 
   const result = rows.reduce(
      (acc, row) => {
         return acc.map(
            statRow => addRowToStatRow(row, statRow),
         )
      },
      types.map(initialRows)
   )   
   .map(statRow => ({
         ...statRow,
         checkedPercentage: statRow.checked / statRow.placementTotal, 
         errorPercentage: statRow.errorCount / statRow.placementTotal,
         id: statRow.annoType,
      })
   )
   return result;
}

export const ReviewAppTypeCheck = () => {

   const [queueName] = useQueryString(QUEUE_NAME_URL_ARG)
   const { loading, error, rows } = useLoadAnnotations(queueName);

   const centered = {
      align: 'center',
      headerAlign: 'center' 
   }
   const columns = [
      { field: 'annoType', headerName: 'Anno type', width: 150, renderCell: AnnotationTypeRenderer, },
      { field: 'placementTotal', headerName: 'Placement Total', width: 150,  ...centered},
      { field: 'checked', headerName: 'Checked', width: 150, ...centered },
      { field: 'errorCount', headerName: 'Error count', width: 150, ...centered },
      { field: 'checkedPercentage', headerName: 'Checked (%)', width: 150, renderCell: RateRenderer, ...centered },
      { field: 'errorPercentage', headerName: 'Error (%)', width: 150, renderCell: RateRenderer, ...centered },
   ];

   const aggregatedRows = useMemo(() => computeAggregatedRows(rows), [rows]);


   return <div>
      {loading? <em>Loading</em> : 
            error ? <em>{error}</em>:
            rows ? <Box sx={{ width: '100%' , height: 'calc(100vh - 64px)'}}>
               <DataGridPro 
               rows={aggregatedRows} 
               columns={columns} 
               pagination={false} 
               rowHeight={INSIGHT_TABLE_ROW_HEIGHT} 
            /></Box> : <em>Nothing to show</em>}

   </div> 
}