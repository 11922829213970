import * as React from 'react';
import PropTypes from 'prop-types';
import {
    MenuItem,
    Menu,
    IconButton,
    ListItemIcon,
    Box,
    Snackbar,
    Alert,
    Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useState} from "react";
import ContentCopy from '@mui/icons-material/ContentCopy';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import InfoIcon from '@mui/icons-material/Info';
import CodeIcon from "@mui/icons-material/Code";
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import VideoModal from "../../VideoModal/VideoModal";

function ThreeDotsMenu(
    {
        cardId,
        clip_timestamp,
        clip_url,
        info,
        openMediaPlayer,
        showDeveloperId = false,
    }
) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [toast, setToast] = useState({
        open: false,
        type: 'error',
        message: '',
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const closeToast = _ => {
        setToast({
            open: false,
            type: 'error',
            message: '',
        })
    }

    const copyToClipboard = (data) => {
        setAnchorEl(null);
        navigator.clipboard.writeText(data)
            .then(_ => {
                setToast({
                    open: true,
                    message: 'Id copied to clipboard!',
                    type: 'success',
                })
            })
            .catch(err => {
                setToast({
                    open: true,
                    message: 'Id NOT copied to clipboard!',
                    type: 'error',
                })
            })
    }

    return (
        <div>
            <Snackbar
                open={toast.open}
                autoHideDuration={3000}
                onClose={closeToast}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Alert
                    onClose={closeToast}
                    severity={toast.type}
                    sx={{width: '100%'}}
                >
                    {toast.message}
                </Alert>
            </Snackbar>

            <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={
                    _ => {
                        copyToClipboard(cardId.split('_')[1])
                    }
                }>
                    <ListItemIcon>
                        <ContentCopy color={'primary'}/>
                    </ListItemIcon>
                    <Box
                        sx={{
                            fontWeight: 'bold',
                            display: 'inline',
                            mr: 1,
                        }}
                    >
                        ID
                    </Box>
                    {cardId.split('_')[1]}
                </MenuItem>
                {
                    info
                        ?
                        <Tooltip
                            title={
                                <Box
                                    dangerouslySetInnerHTML={{__html: info}}
                                    sx={{
                                        textAlign: 'center',
                                        '> *': {
                                            margin: '5px',
                                        }
                                    }}
                                />
                            }
                            placement="left"
                        >
                            <span>
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <InfoIcon/>
                                </ListItemIcon>
                                {info.slice(0, 35)} {info.length > 35 ? "..." : null}
                            </MenuItem>
                                </span>
                        </Tooltip>

                        : null
                }
                {
                    showDeveloperId
                        ? <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <CodeIcon/>
                            </ListItemIcon>
                            Change Developer Id
                        </MenuItem>
                        : null
                }
                {
                    (!!clip_url && !!clip_timestamp) &&
                    <MenuItem onClick={(e) => {
                        handleClose()
                        openMediaPlayer()
                    }}>
                        <ListItemIcon>
                            <SmartDisplayIcon/>
                        </ListItemIcon>
                        Play video
                    </MenuItem>


                }
                {
                    /* Feature not active */
                    false && <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <ReportProblemIcon color={'error'}/>
                        </ListItemIcon>
                        Report Error
                    </MenuItem>
                }

            </Menu>

        </div>
    )
}

ThreeDotsMenu.propTypes = {}

ThreeDotsMenu.defaultProps = {}

export default ThreeDotsMenu;
