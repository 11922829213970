import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Popper,
    Button,
} from "@mui/material";
import Kdb from "../../Kbd/Kdb";
import LogosImageList from "./LogosImageList/LogosImageList";
import {useEffect, useRef, useState} from "react";

function SuggestionAutocomplete(
    {
        index,
        isSelected,
        setLogo,
        skipSuggestion,
        suggestedLogos,
    }
) {
    const cardHTML = document.getElementById(`LogoGenericCard-${index}`);
    const [anchorEl, setAnchorEl] = useState(isSelected ? cardHTML : null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (document.activeElement.tagName.toLowerCase() === 'input') {
                return
            }

            const {
                key,
            } = event;
            const open = Boolean(anchorEl);
            if (open) {
                const codeNumber =  parseInt(key, 10)

                if (!!key) {
                    if (!(isNaN(codeNumber) || key === ' ')) {
                        event.preventDefault();
                        if (key === "0") {
                            return skipSuggestion();
                        }

                        if (codeNumber <= suggestedLogos.length) {
                            setLogo(suggestedLogos[codeNumber - 1])
                        } else {

                        }
                    }
                }
            }
        }

        const cardHTML = document.getElementById(`LogoGenericCard-${index}`);

        if (isSelected) {
            setAnchorEl(cardHTML)
            window.addEventListener('keydown', handleKeyDown);
        } else {
            handleClose()
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isSelected, anchorEl, suggestedLogos])

    const popoverCoords = () => {
        switch (index) {
            case 0:
            case 1:
            case 6:
            case 7:
                return 'right-start'
            case 2:
            case 8:
                return 'left-start'
            case 3:
            case 4:
                return 'right'
            case 5:
                return 'left'
            default:
                return {
                    vertical: 'center',
                    horizontal: 'right',
                }
        }
    }

    const handleClick = () => {
        setAnchorEl(cardHTML)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'suggestionLogo-popover' : undefined;


    return (
        <>
            <Button
                aria-describedby={id}
                onClick={handleClick}
                fullWidth={true}
                variant={open ? 'contained' : 'text'}
            >
                {
                    open ? "Suggested logo version"
                        : `${suggestedLogos.length} suggestion`
                }
            </Button>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={popoverCoords()}
            >
                <Box
                    sx={{
                        background: 'white',
                        border: '1px solid #1DB8E0',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        ml: ([2, 5, 8].includes(index)) ? 0 : 1,
                        mr: !([2, 5, 8].includes(index)) ? 0 : 1,
                        position: "relative",
                        ":before": {
                            content: '""',
                            position: "absolute",
                            width: 0,
                            height: 0,
                            borderTop: "10px solid transparent",
                            borderBottom: "10px solid transparent",
                            borderRight: !([2, 5, 8].includes(index)) ? "10px solid #1DB8E0" : null,
                            borderLeft: ([2, 5, 8].includes(index)) ? "10px solid #1DB8E0" : null,
                            top: '50%',
                            left: !([2, 5, 8].includes(index)) ? "-10px" : null,
                            right: ([2, 5, 8].includes(index)) ? "-10px" : null,
                        }
                    }}
                >
                    <Button
                        onClick={() => skipSuggestion()}
                        variant={'outlined'}
                        sx={{m: 2}}
                    >
                        <Box> <Kdb>0</Kdb> Skip suggestion</Box>
                    </Button>
                    <Box
                        sx={{
                            mx: 2,
                        }}
                    >
                        <LogosImageList
                            logos={suggestedLogos}
                            onLogoClick={(e, id) => {
                                setLogo(id)
                            }}
                        />
                    </Box>

                </Box>

            </Popper>
        </>
    )
}

SuggestionAutocomplete.propTypes = {}

SuggestionAutocomplete.defaultProps = {}

export default SuggestionAutocomplete;
