
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import {
    ReviewApp,
    MainApp
}  from './routes'
import { REVIEW_TABLE_ENDPOINT } from "helpers/data/constants";
import ErrorPage from "./routes/ErrorPage";

const router = createBrowserRouter([
    {
      path: "/",
        errorElement: <ErrorPage />,
      element: <MainApp />,
    },
    {
      path: `/${REVIEW_TABLE_ENDPOINT}`,
        errorElement: <ErrorPage />,
      element: <ReviewApp />,
    },
  ], {
  basename:  process?.env?.REACT_APP_BASEURL ?? '/'
});


const App = () => {
    return <RouterProvider router={router} />
};

export default App;
