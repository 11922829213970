import * as React from 'react';
import {
    Box,
    Button,
    CardActions,
    IconButton,
    Tooltip
} from "@mui/material";
import {
    LOGO_FP_ID,
    MISSING_LABEL_ID,
    UNSURE_LABEL_ID,
} from "../../../../helpers/data/constants";
import {useEffect, useState} from "react";
import {useCacheStore} from "../../../../contexts";
import AutocompleteLogo from "../AutocompleteLogo";
import Kdb from "../../../Kbd/Kdb";
import LayersClearSharpIcon from "@mui/icons-material/LayersClearSharp";
import LayersIcon from "@mui/icons-material/Layers";
import PlacementSelector from "../../../Annotations/PlacementSelector";
import PopperLogoVersion from "../PopperLogoVersion";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SuggestionAutocomplete from "../SuggestionAutocomplete";
import ThreeDotsMenu from "../../ThreeDotsMenu/ThreeDotsMenu";
import UndoIcon from "@mui/icons-material/Undo";
import '../../../../styles/styles.scss';
function LogoGenericActions(
    {
        phase,
        setPhase,
        annotation,
        detection,
        updateAnnotations,
        index,
        isSelected,
        brandsInput,
        placementInput,
        placementOptions,
        resetLogo,
        openMediaPlayer,
        refreshedSpeedDial,
    }
) {
    const {
        brands,
        logos,
    } = useCacheStore();
    const [selectedBrand, setSelectedBrand] = useState(null);

    let initPlacementInputValue = '';
    let initPlacementOptions = {};

    if (!!annotation.contexts) {
        const optionSelected = placementOptions.find(el => el.id === annotation.contexts);
        if (!!optionSelected) {
            initPlacementInputValue = annotation.contexts.title;
            initPlacementOptions = optionSelected;
        }
    }


    const [placementText, setPlacementText] = useState(initPlacementInputValue);
    const [placementValue, setPlacementValue] = useState(initPlacementOptions);

    const isFalsePositive = !!annotation.logo && annotation.logo?.id === LOGO_FP_ID;
    const isLogoUnsure = !!annotation.logo && annotation.logo?.id === UNSURE_LABEL_ID;
    const isLogoMissing = !!annotation.logo && annotation.logo?.id === MISSING_LABEL_ID;

    const filteredBrands = brands.filter(brand => {
        if (!!annotation.detection_setting_id) {
            const {
                logoVersions
            } = brand;
            const logos = logoVersions.filter(logo => logo.detectionSettingIds?.includes(annotation.detection_setting_id))
            return logos.length > 0
        }
        return true;
    }).map(el => {
        return {
            logoVersions: el.logoVersions.filter(
                logo => !annotation.detection_setting_id || logo.detectionSettingIds?.includes(annotation.detection_setting_id)
            ),
            ...el,
        }
    })

    useEffect(() => {
        if (!!annotation.contexts) {
            const optionSelected = placementOptions.find(el => el.id === annotation.contexts);
            if (!!optionSelected) {
                setPlacementText(annotation.contexts.title)
                setPlacementValue(optionSelected)
            }
        }
    }, [annotation.contexts])

    const setLogoVersion = (logoVersion) => {
        updateAnnotations(index, 'logo', {
            label: logoVersion.label ?? selectedBrand.name,
            ...logoVersion,
        });
        setPhase('logoDone');
        if (placementInput.current) {
            if (!annotation.contexts) placementInput.current?.focus();
        }
    }

    const unsurePlacement = () => {
        const unsureLabel = placementOptions.find(el => el.title === 'unsure')
        if (unsureLabel) {
            setPlacementValue(unsureLabel)
            updateAnnotations(index, 'contexts', unsureLabel.id);
        }
    }

    const missingPlacement = () => {
        const missingLabel = placementOptions.find(el => el.title === 'missing')
        if (missingLabel) {
            setPlacementValue(missingLabel)
            updateAnnotations(index, 'contexts', missingLabel.id);
        }
    }

    const getSuggestedLogo = () => {
        if (!!refreshedSpeedDial &&
            refreshedSpeedDial?.data
                ?.classIdsSpeeddial?.length > 0
        ) {
            const temp = [];
            refreshedSpeedDial?.data?.classIdsSpeeddial.forEach(suggestion => {
                const el = logos.find(logo => logo.id === suggestion);
                if (el) temp.push(el);
            })
            return temp;
        }

        return logos.filter(logo =>
            !(!!annotation.classIdsSpeeddial && annotation.classIdsSpeeddial.length > 0)
            || annotation.classIdsSpeeddial.includes(logo.id)
        )
    }

    const getSuggestedPlacement = () => {
        if (!!refreshedSpeedDial &&
            refreshedSpeedDial?.data
                ?.classIdsSpeeddialPlacement?.length > 0
        ) {
            return refreshedSpeedDial.data.classIdsSpeeddialPlacement;
        }

        if(!!annotation.classIdsSpeeddialPlacement){
            return annotation.classIdsSpeeddialPlacement;
        }

        return []
    }

    return (
        <CardActions
            sx={{
                padding: "4px 0",
            }}
        >
            <Box
                className={`
                        LogoCardAction-container 
                        ${isFalsePositive && 'rejectedLogoActions'}
                        `
                }
            >
                {
                    isFalsePositive ?
                        <Button
                            color={'error'}
                            onClick={
                                () => updateAnnotations(index, 'logo', null, true)
                            }
                            className={'hoverContainer'}
                            fullWidth={true}
                        >
                            <Box
                                className="hoverShow centerFlexElements"
                                sx={{
                                    width: '100%',
                                    flexDirection: 'column',
                                }}
                            >
                                <p
                                    style={{margin: 0}}
                                >
                                    Undo Marking as False Positive
                                </p>
                                <Box>
                                    <Kdb>CTRL</Kdb>+<Kdb>Z</Kdb>
                                </Box>
                            </Box>
                            <Box
                                className="hoverHide centerFlexElements"
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <LayersClearSharpIcon color={'error'}/>
                                False Positive
                            </Box>
                        </Button>
                        :
                        <>
                            <Box
                                className={'input-logo-ctn'}
                                id={`input-logo-${index}`}
                                sx={{
                                    p: "4px",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    className={'input-logo'}
                                    sx={{
                                        p: 1,
                                    }}

                                >
                                    {
                                        phase === 'speedDial' &&
                                        <SuggestionAutocomplete
                                            index={index}
                                            isSelected={isSelected}
                                            suggestedLogos={
                                                getSuggestedLogo()
                                            }
                                            setLogo={setLogoVersion}
                                            skipSuggestion={() => {
                                                setPhase('brandPicking')
                                            }}
                                        />
                                    }

                                    {
                                        phase === 'brandPicking' &&
                                        <AutocompleteLogo
                                            index={index}
                                            selectedBrand={selectedBrand}
                                            setSelectedBrand={(val) => {
                                                if (!!val) {
                                                    setSelectedBrand(val);
                                                    setPhase('logoPicking')
                                                }
                                            }}
                                            backToSpeedDial={() => setPhase('speedDial')}
                                            brands={filteredBrands}
                                            inputRef={brandsInput}
                                            hasSpeedDial={
                                                (!!annotation.classIdsSpeeddial
                                                && annotation.classIdsSpeeddial.length > 0) ||
                                                (!!refreshedSpeedDial &&
                                                    refreshedSpeedDial?.data
                                                        ?.classIdsSpeeddial?.length > 0
                                                )
                                            }
                                        />
                                    }
                                    {
                                        phase === 'logoPicking' &&
                                        <PopperLogoVersion
                                            index={index}
                                            isSelected={isSelected}
                                            selectedBrand={selectedBrand}
                                            toBrandSelection={_ => setPhase('brandPicking')}
                                            setLogo={logo => setLogoVersion(logo)}
                                        />
                                    }
                                    {
                                        phase === 'logoDone' &&
                                        <>
                                            {
                                                isLogoUnsure &&
                                                <p className={'fillAllSpace'}>Unsure</p>
                                            }
                                            {
                                                isLogoMissing &&
                                                <p className={'fillAllSpace'}>Missing</p>
                                            }
                                            {
                                                !(isLogoUnsure || isLogoMissing) &&
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                        onClick={() => resetLogo()}
                                                    >
                                                        <Box
                                                            sx={{
                                                                p: '4px 0 !important',
                                                                '& img': {
                                                                    width: '30px',
                                                                    aspectRatio: '3/2',
                                                                    objectFit: 'contain',
                                                                    maxHeight: "100%",
                                                                }
                                                            }}
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    <Box
                                                                        sx={{
                                                                            background: `url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
                                                                            margin: '0 2px',
                                                                            display: 'inline-block',
                                                                            padding: '4px',
                                                                            '& img': {
                                                                                width: '100px',
                                                                                aspectRatio: '3/2',
                                                                                objectFit: 'contain',
                                                                                maxHeight: "100%",
                                                                            }
                                                                        }}
                                                                    >
                                                                        <img src={annotation.logo?.iconUrl} alt=""/>
                                                                    </Box>
                                                                }
                                                            >
                                                                <div
                                                                    style={{
                                                                        background: `url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
                                                                        margin: '0 2px',
                                                                        display: 'inline-block',
                                                                        padding: '4px',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={annotation.logo?.iconUrl}
                                                                        alt="Selected Logo"
                                                                    />
                                                                </div>
                                                            </Tooltip>
                                                        </Box>
                                                        {
                                                            annotation.logo?.label &&
                                                            <Box
                                                                sx={{
                                                                    ml: 2,
                                                                    width: '100%',
                                                                    "& b": {
                                                                        fontWeight: 'bold',
                                                                    }
                                                                }}
                                                                className={'hoverContainer'}
                                                            >
                                                                <Box className="hoverShow">
                                                                    Change Logo Version <Kdb>Ctrl</Kdb>+<Kdb>Z</Kdb>
                                                                </Box>
                                                                <b className="hoverHide">
                                                                    {annotation.logo?.label}
                                                                </b>
                                                            </Box>
                                                        }

                                                    </Box>

                                                </Box>
                                            }


                                        </>
                                    }

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    {
                                        (
                                            [
                                                UNSURE_LABEL_ID,
                                                MISSING_LABEL_ID,
                                            ].includes(annotation.logo?.id)
                                        ) ?
                                            <Tooltip title={<>
                                                Undo <br/>(<Kdb>Ctrl</Kdb>+<Kdb>Z</Kdb>)
                                            </>}>
                                                <IconButton
                                                    size={'small'}
                                                    onClick={
                                                        () => resetLogo()
                                                    }
                                                >
                                                    <UndoIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            <>
                                                <Tooltip
                                                    title={
                                                        <>
                                                            Mark this brand as missing (not in the
                                                            list) <br/>(<Kdb>Ctrl</Kdb>+<Kdb>M</Kdb>)
                                                        </>
                                                    }
                                                >
                                                    <IconButton
                                                        color={'warning'}
                                                        size={'small'}
                                                        onClick={_ => {
                                                            updateAnnotations(index, 'logo', {id: MISSING_LABEL_ID});
                                                            setPhase('logoDone');
                                                        }}
                                                    >
                                                        <SearchOffIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title={
                                                        <>
                                                            Mark as unsure (cannot tell which brand this
                                                            is) <br/>(<Kdb>Ctrl</Kdb>+<Kdb>U</Kdb>)
                                                        </>
                                                    }
                                                >
                                                    <IconButton
                                                        color={'warning'}
                                                        size={'small'}
                                                        onClick={_ => {
                                                            updateAnnotations(index, 'logo', {id: UNSURE_LABEL_ID});
                                                            setPhase('logoDone');
                                                        }}
                                                    >
                                                        <PsychologyAltIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                    }

                                </Box>
                            </Box>
                            <Box
                                className={'input-placement'}
                                sx={{
                                    borderTop: '1px solid #ccc',
                                    p: "4px",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <PlacementSelector
                                    index={index}
                                    value={placementValue}
                                    onChange={(e, value) => {
                                        setPlacementValue(value);
                                        updateAnnotations(index, 'contexts', value.id);
                                        if (placementInput.current) {
                                            placementInput.current.blur()
                                        }
                                    }}
                                    options={placementOptions}
                                    placementRef={placementInput}
                                    inputText={placementText}
                                    setInputText={setPlacementText}
                                    suggestedPlacements={getSuggestedPlacement()}
                                />
                                <Tooltip
                                    title={
                                        <>
                                            Mark this placement as missing (not in the
                                            list) <br/>(<Kdb>Alt</Kdb>+<Kdb>M</Kdb>)
                                        </>
                                    }
                                >
                                    <IconButton
                                        color={'warning'}
                                        size={'small'}
                                        onClick={_ => {
                                            missingPlacement();
                                        }}
                                    >
                                        <SearchOffIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        <>
                                            Mark as Unsure (cannot positively identify placement) <br/>(<Kdb>Alt</Kdb>+<Kdb>U</Kdb>)
                                        </>
                                    }
                                >
                                    <IconButton
                                        color={'warning'}
                                        size={'small'}
                                        onClick={_ => {
                                            unsurePlacement()
                                        }}
                                    >
                                        <PsychologyAltIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </>

                }
                <Box
                    sx={{
                        textAlign: 'center',
                        mr: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        borderLeft: "1px solid #ccc",
                        justifyContent: "space-around",
                    }}
                    className={'Options'}
                >
                    <Box className={'input-logo-reject'} sx={{
                        ml: 1,
                    }}>
                        <Tooltip
                            title={
                                <>
                                    {annotation.logo?.id === LOGO_FP_ID ? <Box>Undo Marking as false positive</Box>
                                        :
                                        <Box>Mark this card as false positive <br/>(<Kdb>Ctrl</Kdb>+<Kdb>Z</Kdb>)</Box>
                                    }
                                </>
                            }
                        >

                            <Button
                                variant={'contained'}
                                color={annotation.logo?.id === LOGO_FP_ID ? 'warning' : 'error'}
                                size={'small'}
                                onClick={_ => {
                                    if (annotation.logo?.id !== LOGO_FP_ID) {
                                        updateAnnotations(index, 'logo', {id: -1});
                                    } else {
                                        updateAnnotations(index, 'logo', null, true);
                                    }

                                }}
                            >
                                {
                                    isFalsePositive
                                        ? <LayersIcon/>
                                        : <LayersClearSharpIcon/>

                                }
                            </Button>
                        </Tooltip>
                    </Box>
                    <Box>
                        <ThreeDotsMenu
                            cardId={detection.id}
                            openMediaPlayer={openMediaPlayer}
                            {...annotation}
                        />
                    </Box>
                </Box>

            </Box>

        </CardActions>
    )
}

LogoGenericActions.propTypes = {}

LogoGenericActions.defaultProps = {}

export default LogoGenericActions;
