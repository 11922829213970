export const extractLastAnnotation = (data) => {
    const { annotations, mediaDetectionId } = data;

    /* 
        This will select the last annotation. 
        
        This rely on the API sending annotations in antichronological order
    */
    const last = annotations[0];
    const { logoVersionError: logoError, placementError,  } = last;
    return { logoError, placementError, iann_id: mediaDetectionId }

}