import { toast } from 'react-toastify';
import { username, password } from './http-common'
import { ANNOTATION_TYPE_ANNOTATION, ANNOTATION_TYPE_PROPAGATION, CHECKED_RATE_ROW, CHECKED_TITLE_ROW, ERROR_RATE_ROW,  PLACEMENT_ROW_TITLE,  QUERY_HASH_ID_SUB_STRING, ROW_IANN_ID_SUB_STRING, SAMPLED_TITLE_ROW, TYPE_TITLE_ROW } from 'helpers/data/constants';
import { CORRECTION_TITLE_ROW,  PLACEMENT_ID_ROW_TITLE } from './helpers/data/constants';
import { isToBeFixed } from './components/ReviewApp/ReviewAppErrorInsights';

// TODO: should be re-written using axios
// TODO: should be moved to services/

// TODO: code smell
export let brandsUrl = '';

const apiUrl = process.env.REACT_APP_API_URL;
const mainUrl = process.env.REACT_APP_MAIN_URL
const graphApiUrl = process.env.REACT_APP_GRAPH_URL;
const annotationsApiUrl = process.env.REACT_APP_ANNOTATIONS_API_URL;

const headers = new Headers();

headers.set('Authorization', 'Basic ' + window.btoa(username + ":" + password));

const requestOptions = {
    credentials: 'include',
    headers: process?.env?.NODE_ENV === 'development' ? headers : new Headers(),
};

const requestOptionsEncoded = {
    credentials: 'include',
    headers: process?.env?.NODE_ENV === 'development' ? headers : new Headers(),
};

requestOptionsEncoded.headers.set('Content-Type', 'application/x-www-form-urlencoded');

const version = 2;

export const getBrandsCache = () =>
    fetch(brandsUrl)
        .catch((message) => {
            throw new Error('XHR Failed for Brands caching', message);
        });

export const getMe = () =>
    fetch(
        `${mainUrl}/me?` + new URLSearchParams({
            product: 'annotate',
        }),
        requestOptions
    )
        .then((response) => {
            if (response.status) {
                const {status} = response;
                if (status >= 400 && status < 500) {
                    window.location.replace("https://my.visua.com/backoffice/login");
                }
            }
            return response.json()
        })
        .then(res => {
            const {data} = res;
            brandsUrl = data.brandsLibraryUrl;
            return data
        })
        .catch((message) => {
            throw new Error('XHR Failed for getMe', message);
        });

export const getQueueSize = (queueName) =>
    fetch(
        `${apiUrl}/annotations/size?` + new URLSearchParams({queueName, version}),
        requestOptions
    )
        .then((response) => response.json())
        .catch((message) => {
            throw new Error('XHR Failed for getQueueSize', message);
        });

export const getAnnotations = (queueName, limit) => {
    const params = new URLSearchParams({limit, version});
    queueName.split(',').forEach((value) => params.append('queueName', value))

    return fetch(
        `${apiUrl}/annotations?` +
        params,
        requestOptions
    )
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            toast.error(
                "XHR Failed for getAnnotations",
                {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                }
            );

            return Promise.reject()
        });
}

export const getMediaDetections = ({id, frameID, frame_id}) => {
    const idFrame = frameID ?? frame_id;
    console.log(idFrame, typeof idFrame)
    let frameIdRequest = '';
    if (idFrame === 0) frameIdRequest =`.eq(frameId,0)`
    if (!!idFrame) frameIdRequest = `.eq(frameId,${idFrame})`


    return fetch(
        `${graphApiUrl}/${id}/mediadetection${frameIdRequest}?` +
        new URLSearchParams({
            version,
            fields: 'logoversion,medialabel,mediaDetectionMeta'
        }),
        requestOptions
    )
        .then((response) => response.json())
        .catch((message) => {
            throw new Error('XHR Failed for getMediaDetections', message);
        });
}

export const getVisualClassData = (ids) =>
    fetch(
        `${graphApiUrl}/visualClass.in(id,${ids.join()})?` +
        new URLSearchParams({
            version,
            fields: 'id,polytype,dataset,datasetClassCode,description,brand',
        }),
        requestOptions
    )
        .then((response) => response.json())
        .catch((message) => {
            throw new Error('XHR Failed for getMediaLabels', message);
        });

export const putNotSubmittedAnnotations = (annotations) => {
    const bodyData = new FormData();
    bodyData.append('annotationsMetas', JSON.stringify(annotations));

    return fetch(
        annotationsApiUrl,
        {
            ...requestOptions,
            body: bodyData,
            method: 'PUT',
        }
    )
        .then((response) => response.json())
        .catch((message) => {
            throw new Error('XHR Failed for putAnnotations', message);
        });
}

export const putDetections = (dets) => {
    const bodyData = new FormData();
    bodyData.append('annotationsMetas', JSON.stringify(dets));

    return fetch(
        annotationsApiUrl,
        {
            ...requestOptions,
            body: bodyData,
            method: 'PUT',
        }
    )
        .then((response) => response.json())
        .catch((message) => {
            return Promise.reject('XHR Failed for putDetections', message);
        });
}

export const putQuadrantAnnotation = (annotations) => {
    requestOptions.headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const bodyData = 'annotationsMetas=' + encodeURIComponent(JSON.stringify(annotations));

    return fetch(
        `${mainUrl}/annotations`,
        {
            ...requestOptions,
            body: bodyData,
            method: 'PUT',
        })
    .then((response) => {
        console.log({status: response.status})
        return response.json()
    })
        .catch(
            (message) => {
                return Promise.reject(message ?? 'XHR Failed for putDetections');
            }
        )
}


export const putAnnotations = (annotations) => {

    const bodyData = 'annotationMeta=' + encodeURIComponent(JSON.stringify(annotations));

    return fetch(
        apiUrl + '/annotations',
        {
            ...requestOptionsEncoded,
            method: 'PUT',
            body: bodyData,
        }
    )
        .then((response) => {
            console.log('response.status > ', response.status);
        })
        .catch((message) => {
            throw new Error('XHR Failed for putAnnotations', message);
        });
}

export const getIds = (ids, fields) =>
    fetch(
        `${graphApiUrl}/?` + new URLSearchParams({
            ids: ids.toString(),
            fields: fields ? fields.toString() : null,
            version,
        }),
        requestOptions
    )
        .then((response) => response.json())
        .catch((message) => {
            throw new Error('XHR Failed for getIds', message);
        });

export const getMediaLabelsById = (ids) =>
    fetch(
        graphApiUrl + '/MediaLabel.in(id,' + ids.join(',') + ')?' + new URLSearchParams({
            version,
        }),
        requestOptions
    )
        .then((response) => response.json())
        .catch((message) => {
            throw new Error('XHR Failed for getIds', message);
        });

export const getReviewCorrections = async (queueName) => {


    const url = process?.env.REACT_APP_ANNOTATION_TABLE_ENDPOINT?.replace(QUERY_HASH_ID_SUB_STRING, queueName)

    const response = await fetch(url,
        requestOptions,
    )

    const data = await response.json()
    return data.annotate.annotations;

}

export const getReviewTaskInfo = async (queueName) => {
    const url = process?.env.REACT_APP_REVIEW_TASK_INFO_ENDPOINT?.replace(QUERY_HASH_ID_SUB_STRING, queueName)
    console.log('url', url)
    const response = await fetch(url,
        requestOptions,
    )

    const data = await response.json()
    console.log('revieved data', data);
    return data;
}

export const addReviewCorrection = async (changeSet, queueName) => {
    const { iann_id, logoError: logoVersionError, placementError } = changeSet;
    const bodyData = 'annotation=' + encodeURIComponent(JSON.stringify({logoVersionError, placementError }));

    const url = process
        ?.env.REACT_APP_ANNOTATION_TABLE_PUT_ENDPOINT
        ?.replace(QUERY_HASH_ID_SUB_STRING, queueName)
        ?.replace(ROW_IANN_ID_SUB_STRING, iann_id)

    const fetchOptions = {
        ...requestOptionsEncoded,
        method: "PUT",
        body: bodyData
    }

    const response = await fetch(url,
        fetchOptions
    )

    const data = await response.json()
    if(!data?.annotate?.annotations) {
        throw Error('Could not add review correction')
    }
    return data?.annotate?.annotations;
}

export const postReviewCheckAggregate = async (queueName, aggregationsRaw) => {

    const placements = [...new Set(aggregationsRaw.map(aggregation => aggregation[PLACEMENT_ID_ROW_TITLE](aggregation[PLACEMENT_ROW_TITLE])))];
    const aggregations = placements.map( placementId => {
        const targetRows = aggregationsRaw.filter(
            aggregation =>
                aggregation[PLACEMENT_ID_ROW_TITLE](aggregation[PLACEMENT_ROW_TITLE]) === placementId
        );

        const annotationRow = targetRows.find(
            aggregation =>
                aggregation[TYPE_TITLE_ROW] === ANNOTATION_TYPE_ANNOTATION
        )

        const propagationRow = targetRows.find(
            aggregation =>
                aggregation[TYPE_TITLE_ROW] === ANNOTATION_TYPE_PROPAGATION
        );


        return {
            mediaLabelId: parseInt(placementId, 10),
            annotations: {
              sampled: annotationRow[SAMPLED_TITLE_ROW],
              checked: annotationRow[CHECKED_TITLE_ROW],
              error: annotationRow[CORRECTION_TITLE_ROW],
              checkedPct: annotationRow[CHECKED_RATE_ROW] || 0,
              errorRatePct: annotationRow[ERROR_RATE_ROW] || 0,
              toBeFixed: isToBeFixed(annotationRow),
            },
            propagations: {
              sampled: propagationRow[SAMPLED_TITLE_ROW],
              checked: propagationRow[CHECKED_TITLE_ROW],
              error: propagationRow[CORRECTION_TITLE_ROW],
              checkedPct: propagationRow[CHECKED_RATE_ROW] || 0,
              errorRatePct: propagationRow[ERROR_RATE_ROW] || 0,
              toBeFixed: isToBeFixed(propagationRow),
            }
        }
    });

    const bodyData = 'aggregations=' + encodeURIComponent(JSON.stringify(aggregations))
    const url = process?.env.REACT_APP_REVIEW_CHECK_AGGREGATE_ENDPOINT?.replace(QUERY_HASH_ID_SUB_STRING, queueName)
    const fetchOptions = {
        ...requestOptionsEncoded,
        method: "POST",
        body: bodyData
    }

    await fetch(url,
        fetchOptions
    )
    return { success: true }
}

export const postReviewComplete = async (queueName) => {
    const url = process?.env.REACT_APP_REVIEW_COMPLETE_ENDPOINT?.replace(QUERY_HASH_ID_SUB_STRING, queueName)
    const fetchOptions = {
        ...requestOptions,
        method: "POST",
    }
    const response = await fetch(url,
        fetchOptions
    )
    console.warn(response);
    return { success: true }
}

export const postReviewDone = async (queueName) => {
    const url = process?.env.REACT_APP_REVIEW_DONE_ENDPOINT?.replace(QUERY_HASH_ID_SUB_STRING, queueName)
    const fetchOptions = {
        ...requestOptions,
        method: "POST",
    }
    const response = await fetch(url,
        fetchOptions
    )
    return { success: true }
}
