import React from "react";
import {
    TextField,
    Autocomplete,
    Paper,
    Popper,
} from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';



const PlacementSelector = (
    {
        value,
        onChange,
        options,
        placementRef,
        inputText,
        setInputText,
        suggestedPlacements = [],
    }) => {

    const PopperMy = function (props) {
        return (<Popper {...props} />)
    }

    let renderOptions = options.filter(el => !suggestedPlacements.includes(parseInt(el.id)))
    const sortedSuggested = [];
     suggestedPlacements.forEach(suggestion => {
         const el = options.find(option => parseInt(option.id, 10) === parseInt(suggestion, 10));
         if (el) {
             sortedSuggested.push(el);
         }
     })
    renderOptions = renderOptions.sort((a, b) => (a.title > b.title) ? 1 : -1)
    renderOptions = [
        ...sortedSuggested,
        ...renderOptions
    ];

    return (
        <Autocomplete
            options={renderOptions}
            PopperComponent={PopperMy}
            getOptionLabel={(option) => !option.title ? '' : option.title}
            openOnFocus
            autoHighlight
            className="class-selector"
            renderInput={(params) =>(
                <TextField
                    {...params}
                    value={inputText}
                    onChange={e => {
                        setInputText(e.target.value)
                    }}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        classes: {
                            input: 'mouseFlow-input'
                        }
                    }}
                    placeholder={'Select Placement'}
                    inputRef={placementRef}
                    className="class-selector-input"
                    variant="filled"
                    hiddenLabel
                />
            )}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.title, inputValue, { insideWords: false });
                const parts = parse(option.title, matches);

                return (
                    <li
                        {...props}
                        key={option.id}
                        style={{
                            zIndex: 9999,

                        }}
                    >
                        <div
                            style={{
                                background:  suggestedPlacements.includes(parseInt(option.id, 10)) ?'#FF00FF' : '',
                                color: suggestedPlacements.includes(parseInt(option.id, 10)) ?'#FFF' : '#000',
                                padding: '2px',
                            }}
                        >
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 800 : 400,
                                        background: part.highlight ? 'yellow' : 'transparent',
                                        color: suggestedPlacements.includes(parseInt(option.id, 10)) ?'#000' : '',
                                        padding: '0 1px',
                                        zIndex: 10,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
            PaperComponent={({ children }) => (
                <Paper style={{ marginTop: 10, zIndex: 9999 }}>
                    {children}
                </Paper>
            )}
            value={value}
            onChange={onChange}
            fullWidth={true}
            filterSelectedOptions={false}
            disableListWrap={true}
            disableClearable
            freeSolo
            sx={{
                '& input': {
                    textAlign: 'center',
                    p: 1,
                },
                "& .MuiAutocomplete-popper": {
                    zIndex: 99999,
                }
            }}
            ListboxProps={{
                style: { maxHeight: 260 }
            }}
        />
    );
}

export default PlacementSelector;
