export const LOGO_FP_ID = -1;
export const MISSING_LABEL_ID = 195747;
export const UNSURE_LABEL_ID = 195746;
export const FALSE_POSITIVE_PLACEMENT_ID = 191843
export const FALSE_POSITIVE_LOGO_ID = 197335

/* 
    Row titles
*/
export const PLACEMENT_ROW_TITLE = "Placement";
export const PLACEMENT_ID_ROW_TITLE = "Placement ID";
export const LOGO_ROW_TITLE = "Logo";
export const NAME_ROW_TITLE = "Name";
export const SAMPLED_TITLE_ROW = "Sampled";
export const CHECKED_TITLE_ROW = "Checked";
export const CHECKED_RATE_ROW = "Checked (%)";
export const SNAPSHOT_TITLE_ROW = "Snapshot";
export const PROPAGATION_TITLE_ROW = "Propagation";
export const CLIP_TITLE_ROW = "Clip";
export const CORRECTION_TITLE_ROW = "Correction";
export const STATUS_TITLE_ROW = "Status";
export const CORRECTION_STATUS_TITLE_ROW = "Correction status";
export const TYPE_TITLE_ROW = "Annotation type"
export const ERROR_RATE_ROW = "Error rate (%)";
export const SAMPLED_RATE_ROW = "Sampled (%)";
export const PLACEMENT_ID_TITLE_ROW = "Placement ID";

/*
    Used by sampling algorythm
*/
export const SAMPLE_ALL_THRESHOLD = 10;
export const SAMPLE_THRESHOLD_PERCENTAGE_LOW = 0.15 
export const SAMPLE_THRESHOLD_PERCENTAGE_HIGH = 0.33 
export const SAMPLE_THESHOLD_PERCENTAGE_THRESHOLD = 12000
export const SAMPLE_VALUE_MISSING = 'Missing'
export const SAMPLE_VALUE_UNSURE = 'Unsure'

/*
    Replace this string by the hash ID in the target URL environment variable
*/
export const QUERY_HASH_ID_SUB_STRING = 'MEDIA_HASH_ID';
export const ROW_IANN_ID_SUB_STRING = 'ROW_IANN_ID'
export const QUEUE_NAME_URL_ARG = 'tableName'
export const REVIEW_TABLE_ENDPOINT = 'table'

/*
    Layout-related
*/
export const INSIGHT_TABLE_ROW_HEIGHT = 48;
export const REVIEW_ROW_HEIGHT = 128;
export const REVIEW_ROW_WIDTH = 256;
export const IMAGE_TOOLTIP_WIDTH = '60vw';

/*
    Column labels
*/
export const CORRECTION_FILTER_UNTOUCHED = 'Untouched';
export const CORRECTION_FILTER_CORRECT = 'Correct';
export const CORRECTION_FILTER_WRONG_PLACEMENT = 'Wrong placement';
export const CORRECTION_FILTER_WRONG_LOGO = 'Wrong logo';
export const CORRECTION_FILTER_BOTH = 'Wrong logo and placement';

export const STATUS_LABEL_CHECKED = "Correct"
export const STATUS_LABEL_UNTOUCHED = "Untouched"
export const STATUS_LABEL_CORRECTED = "Correction needed"

export const ANNOTATION_TYPE_ANNOTATION = "Annotation"
export const ANNOTATION_TYPE_PROPAGATION = "Propagation"
export const ANNOTATION_TYPE_TOTAL = "Total"

export const UNSURE_PLACEMENT_OBJ = {
    id: "195735",
    label: "195735",
    title: "unsure",
};

export const MISSING_PLACEMENT_OBJ = {
    id: "195733",
    label: "195733",
    title: "missing",
};

/*
    Initial state of table features
*/
export const INITIAL_COLUMNS_VISIBILITY_MODEL = {
    "Session ID": false,
    "Track ID": false,
    "Exposure ID": false,
    "Clip": false,
    "ID": false,
    "Class ID": false,
    "Ignored for sponsor clutter?": false,
    "Placement ID": false,
    "Exposure duration": false,
    "Exposure area": false,
    "Exposure confidence": false,
    "Exposure sponsor clutter": false,
    "Exposure total clutter": false,
    "Exposure time in": false,
    "Exposure time out": false,
    "Exposure placement confidence": false,
    "Track duration": false,
    "Track area": false,
    "Track confidence": false,
    "Track time in": false,
    "Track time out": false,
    "Track placement confidence": false,
    "Sport/League ID": false,
    "Season Stage": false,
    "Fixture Date": false,
    "Fixture Time": false,
    "Timezone": false,
    "Fixture Location": false,
    "Home Team": true,
    "Fixture Name": false,
    "Fixture Type": false,
    "annotator_id": false,
    "nn_id": false,
    "nn_score": false,
    "History": false

  }
 
 export const INITIAL_FILTER_MODEL = {
    items: [ 
        {
            id: 1,
            field: SAMPLED_TITLE_ROW,
            operator: 'is',
            value: "true"
        }
    ]
 } 

 export const INITIAL_SORT_MODEL = [
     
 ]

 export const INITIAL_PINNED_COLUMNS = {
    left: []
 }

 export const INITIAL_COLUMNS_WIDTH = {}

/*
    Review tabs title
*/ 
export const FINAL_CHECK_LABEL = "Final check"
export const PLACEMENT_CHECK_LABEL = "Placement error"
export const LOGO_CHECK_LABEL = "Logo error"
export const TYPE_CHECK_LABEL = "Annotation type"
export const ERROR_CHECK_LABEL = "Combined Error "

export const ERROR_RATE_THRESHOLD = 0.5;
export const ORANGE_THRESHOLD = 0.1;
export const RED_THRESHOLD = 0.8;
