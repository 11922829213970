import * as React from 'react';
import PropTypes from 'prop-types';
import {useState, useEffect, useRef} from "react";

import {
    Grid,
    Button,
    Tooltip,
    IconButton,
    Box,
    LinearProgress,
    Switch,
    Snackbar,
    Alert, CircularProgress,
} from '@mui/material';
import {
    putAnnotations,
    getIds,
    getMediaLabelsById,
    putQuadrantAnnotation,
} from "../../DataService";
import {ColorPicker} from 'mui-color';
import AppToolbar from "../../components/AppActions/AppActions";
import Kdb from "../../components/Kbd/Kdb";
import CropSquareIcon from '@mui/icons-material/CropSquare';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CardGrid from "./CardGrid";
import {useUserStore} from "../../contexts";
import {
    DEFAULTS_CARDS_CONFIG
} from "../../contexts/userContext";
import ImageIcon from '@mui/icons-material/Image';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import PreviewIcon from '@mui/icons-material/Preview';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {getUniqueElements} from "../../helpers/supportFunctions";
import DebugModal from "../../components/DebugModal/DebugModal";
import AdbIcon from '@mui/icons-material/Adb';
import {getSpeedDial} from "../../service/serviceAPI";

function GridContainer(
    {
        annotations = [],
        queueUrl,
        getAnnotations,
        queueName,
        queueInfos = {
            name: '',
            setQueue: _ => {},
            setQueueData: _ => {},
            data: {},
        },
        purgeQueue,
    }
) {

    const [placementOptions, setPlacementOptions] = useState([]);
    const [mode, setMode] = useState('');
    const [layout, setLayout] = useState(9);
    const [detections, setDetections] = useState([]);
    const [canDidBeChanged, setCanDidBeChanged] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showDebug, setShowDebug] = useState(false);
    const [refreshedSpeed, setRefreshedSpeed] = useState([]);
    const [isLoadingDetections, setIsLoadingDetections] = useState(false);

    const [toast, setToast] = useState({
        open: false,
        message: '',
    });

    const gridRef = useRef();

    const {
        boundingBoxColor,
        setBoundingBoxColor,
        cardBackground,
        setCardBackground,
        loopBgColors,
        showBB,
        setShowBB,
        isAdmin,
    } = useUserStore();


    const closeToast = _ => {
        setToast({
            open: false,
            message: '',
        })
    }

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    const submitAnnotations = async () => {
        setIsSubmitting(true);

        if (mode === 'ocr') {
            // The card text update the
            // data model in the Card container
            // every second for the OCR card
            // So we force the function to wait 1,2sec
            // to be sure all the cards are updated
            await timeout(1200)
        }
        const firstDetection = detections[0];
        const annotationsData = gridRef.current.getCardsState() ?? [];

        // Prevent App from crashing
        if (!firstDetection || annotationsData.length === 0) {
            setIsSubmitting(false);
            return setToast({
                open: true,
                message: 'Something went wrong with App Data Model. Try again maybe?',
            })
        }


        const isOCR = !!firstDetection.ocr;
        const isPlacement = !!annotationsData[0].class_ids;
        const isYNP = !isOCR && !isPlacement;
        const hasQuadrant = !!annotationsData[0].qdrant_id;
        /**
         *  Has every annotation been evaluated?
         */

        // YNP
        if (
            isYNP && annotationsData.some(
                (annotation) => {
                    return typeof annotation.approve === "undefined"
                        || annotation.approve === null
                }
            )
        ) {
            setIsSubmitting(false);
            return setToast({
                open: true,
                message: 'Approve all annotations before proceeding!',
            })
        }

        // Placement

        if (
            isPlacement
            && annotationsData.some(
                (annotation) => !annotation.contexts && annotation.logo?.id !== -1
            )
        ) {
            setIsSubmitting(false);
            return setToast({
                open: true,
                message: 'Set a placement for each annotations before proceeding!',
            })
        }

        if (isPlacement && annotationsData.some(
            (annotation, index) => detections[index].visualClassId === "viscla_175474" && !annotation.logo
        )) {
            setIsSubmitting(false);
            return setToast({
                open: true,
                message: 'Set a logo for each logo annotation before proceeding!',
            })
        }


        const cardsDataToSend = annotationsData.map((annotation, index) => {
            const isLogoGeneric = isPlacement &&
                (
                    detections[index].visualClassId === "viscla_175474"
                    || !!annotation.class_id_selected
                );
            const isRejectLogo = isLogoGeneric && annotation.logo.id === -1;
            let OcrApprove;
            if (isOCR && !isPlacement) {
                if (annotation.text === detections[index].mediaDetectionMeta[0].meta.text) {
                    OcrApprove = 1;
                } else if (!annotation.text.length && annotation.text !== detections[index].mediaDetectionMeta[0].meta.text) {
                    OcrApprove = 0;
                } else {
                    OcrApprove = 0.5;
                }
            }

            const newData = {
                info: annotation.info,
                media_detection_meta_id: annotation.media_detection_meta_id,
                receiptHandle: annotation.receiptHandle,
                mediaAnnotateUrl: annotation.mediaAnnotateUrl,
                visualClassId: annotation.visualClassId,
                approved: annotation.approve,
                ...(
                    !!annotation?.payload && {
                        payload: {
                            ...annotation?.payload
                        }
                    }
                ),
                ...(
                    !!annotation?.media_detection_id_placement
                    && {mediaDetectionIdPlacement: annotation.media_detection_id_placement}
                ),
                ...(
                    typeof annotation.did !== 'undefined'
                    && {developerId: annotation.did}
                ),
                ...(isOCR && {
                    text: annotation.text,
                    approved: OcrApprove
                }),
                ...(isPlacement && {
                    class_ids: annotation.class_ids,
                    ...(
                        !isRejectLogo &&
                        {
                            ...(
                                hasQuadrant
                                    ? {
                                        placements: [
                                            {
                                                visualClassId: parseInt(annotation.contexts, 10)
                                            }
                                        ]
                                    }
                                    : {
                                        contexts: [
                                            {
                                                visualClassId: 'medlab_' + annotation.contexts
                                            }
                                        ]
                                    }
                            )
                        }
                    ),
                }),
            };

            if (isPlacement) {
                newData['approved'] = isRejectLogo ? 0 : 1;
            }

            if (hasQuadrant) {
                if (isLogoGeneric && !isRejectLogo) {
                    newData['visualClassIdSelected'] = annotation.logo.id;
                }
                newData['mediaDetectionId'] = annotation.id;
                newData['qdrantId'] = annotation.qdrant_id;
                newData['jobId'] = annotation.jobId;
                return newData;
            } else {
                newData['id'] = annotation.id;
            }

            return newData;
        })

        const annotationsApproves = {
            'queueUrl': queueUrl,
            ...(
                hasQuadrant ? {
                        'annotations': cardsDataToSend,
                    }
                    : {
                        'annotation': cardsDataToSend,
                    }
            ),
        };

        const putPromise = hasQuadrant ?
            putQuadrantAnnotation(annotationsApproves)
            : putAnnotations(annotationsApproves)

        putPromise
            .then(_ => {
                return getAnnotations(queueName, 9);
            })
            .catch(err => {
                console.error(err);
                return setToast({
                    open: true,
                    message: "Error in the server call",
                })
            })
            .finally(_ => {
                setIsSubmitting(false);
            })

    }

    // Get Detections
    useEffect(() => {
        if (!annotations || annotations.length === 0) return;
        const meddets = annotations.map((annotation) => annotation.id);
        const fields =
            'mediaDetectionMeta(meta),visualClass(thumbUrl,dataset,datasetClassCode,description,brand(name,industry(description)),visualStructMember(name,meta,visualClassId)),media(mediaAnnotateUrl,meta,id)';

        setIsLoadingDetections(true);
        getIds(meddets, fields)
            .then((resp) => {
                const {
                    mediaDetection
                } = resp;
                if (!!mediaDetection) {
                    setDetections(mediaDetection);
                    return mediaDetection
                } else {
                    throw 'Empty media Detections'
                }
            })
            .then((dets) => {
                const hasQuadrant = !!annotations[0].qdrant_id;
                if (hasQuadrant) {
                    const ids = dets
                        .map(
                            (det) => parseInt(det.id.split('_')[1], 10)
                        )

                    Promise.all(
                        ids.map(
                            id => getSpeedDial(id)
                                .then((resp) => {
                                    return {
                                        id: id,
                                        data: resp.data.annotate
                                    }
                                })
                        )
                    )
                        .then(resp => {
                            console.log(resp)
                            setRefreshedSpeed(resp)
                        })
                }
            })
            .finally(() => setIsLoadingDetections(false))
            .catch(err => {
                console.error(`getIds`, err);
                return setToast({
                    open: true,
                    message: 'Error in Graph APIs',
                })
            });
    }, []);

    // Keydown Effect
    useEffect(() => {
        const handleKeyDown = (event) => {
            const {
                code,
                ctrlKey,
                shiftKey,
            } = event;

            if (code === "F1") {
                setBoundingBoxColor(
                    boundingBoxColor === DEFAULTS_CARDS_CONFIG.BB_COLORS[0]
                        ? DEFAULTS_CARDS_CONFIG.BB_COLORS[1]
                        : DEFAULTS_CARDS_CONFIG.BB_COLORS[0]
                )
            }

            if (code === 'F2') {
                loopBgColors()
            }

            if (code === 'F3') {
                event.preventDefault();
                setLayout(
                    layout === 9
                        ? 1
                        : 9
                )
            }
            if (code === 'F4') {
                event.preventDefault();
                setShowBB(
                    !showBB
                )
            }

            if (document.activeElement.tagName.toLowerCase() === 'input') {
                if (document.activeElement.id === 'changeQueueInput') return;
            }


            if (
                (event.keyCode === 13 && ctrlKey)
                || (event.keyCode === 13 && shiftKey)
            ) {
                return submitAnnotations()
            }

        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };

    }, [layout, boundingBoxColor, cardBackground, detections, mode, showBB])

    // Set Mode
    useEffect(_ => {
        if (detections.length === 0) return;
        const firstAnnotation = annotations[0];
        const isOCR = !!detections[0].ocr;
        const isPlacement = !!firstAnnotation.class_ids || detections[0].visualClassId === "viscla_175474";

        if (isPlacement) {
            const classIdsAnnotations = annotations.map(el => el.class_ids).filter(el => typeof el !== 'undefined')
            const uniqueIds = getUniqueElements(
                classIdsAnnotations
            )
            if (uniqueIds.length === 0) {
                setMode('placement')
                return;
            }

            return getMediaLabelsById(uniqueIds)
                .then((mediaLabelsData) => {
                    setPlacementOptions(mediaLabelsData.mediaLabel.map(
                        (mediaLabel) => ({
                            label: mediaLabel.id.split('_')[1],
                            title: !!mediaLabel.description ? mediaLabel.description : mediaLabel.datasetClassCode,
                            id: mediaLabel.id.split('_')[1],
                        })
                    ));
                    setMode('placement')
                });
        }

        if (isOCR) return setMode('ocr');
        else {
            setMode('ynp');
            const {
                approved,
                media_detection_meta_id
            } = firstAnnotation;

            if (
                typeof approved !== 'undefined'
                && typeof media_detection_meta_id !== 'undefined'
            ) {
                setCanDidBeChanged(true);
            }
        }

    }, [detections])

    return (
        <Box>
            <AppToolbar
                purgeQueue={purgeQueue}
                queueData={queueInfos.data}
                queueName={queueInfos.name}
                setQueueData={queueInfos.setQueueData}
                setQueueName={queueInfos.setQueue}
                Actions={
                    <>
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Tooltip title={
                                <>
                                    Press <Kdb>F1</Kdb> to cycle bounding box colors. <br/>
                                    Click the icon to go back to default
                                </>
                            }>
                                <IconButton
                                    onClick={
                                        _ => {
                                            setBoundingBoxColor(
                                                boundingBoxColor === DEFAULTS_CARDS_CONFIG.BB_COLORS[0]
                                                    ? DEFAULTS_CARDS_CONFIG.BB_COLORS[1]
                                                    : DEFAULTS_CARDS_CONFIG.BB_COLORS[0]
                                            )
                                        }
                                    }
                                >
                                    <CropSquareIcon/>
                                </IconButton>
                            </Tooltip>
                            <ColorPicker
                                value={boundingBoxColor}
                                hideTextfield={true}
                                onChange={color => {
                                    if (!!color) {
                                        const {css} = color;
                                        if (!!css) {
                                            const {backgroundColor} = css;
                                            if (!!backgroundColor) {
                                                setBoundingBoxColor(backgroundColor)
                                            }
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Tooltip title={
                                <>
                                    Press <Kdb>F2</Kdb> to change background colors <br/>
                                    Click the icon to go back to default
                                </>
                            }>
                                <IconButton
                                    onClick={
                                        _ => {
                                            loopBgColors();
                                        }
                                    }
                                >

                                    <CropOriginalIcon/>
                                </IconButton>
                            </Tooltip>
                            <ColorPicker
                                value={cardBackground}
                                hideTextfield={true}
                                onChange={color => {
                                    if (!!color) {
                                        const {css} = color;
                                        if (!!css) {
                                            const {backgroundColor} = css;
                                            if (!!backgroundColor) {
                                                setCardBackground(backgroundColor)
                                            }
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Tooltip title={
                            <>
                                Press <Kdb>F3</Kdb> to change the layout<br/>
                            </>
                        }>
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >

                                <Grid3x3Icon
                                    sx={{
                                        color: layout === 9 ? 'primary.main' : 'currentColor',
                                    }}
                                />
                                <Switch
                                    checked={layout === 1}
                                    onChange={(evt, value) => {
                                        if (value) setLayout(1)
                                        else setLayout(9)
                                    }}
                                />
                                <ImageIcon
                                    sx={{
                                        color: layout !== 9 ? 'primary.main' : 'currentColor',
                                    }}
                                />
                            </Grid>
                        </Tooltip>
                        <Tooltip title={
                            <>
                                Press <Kdb>F4</Kdb> to toggle bounding box visibility<br/>
                            </>
                        }>
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >

                                <VisibilityOffIcon
                                    sx={{
                                        color: !showBB ? 'primary.main' : 'currentColor',
                                    }}
                                />
                                <Switch
                                    checked={showBB}
                                    onChange={(evt, value) => {
                                        setShowBB(value)
                                    }}
                                />
                                <PreviewIcon
                                    sx={{
                                        color: showBB ? 'primary.main' : 'currentColor',
                                    }}
                                />
                            </Grid>
                        </Tooltip>
                        <Grid
                            xs={2}
                            item
                            sx={{
                                ml: 'auto'
                            }}
                        >
                            <Button
                                fullWidth={true}
                                variant={'contained'}
                                color={'primary'}
                                onClick={_ => submitAnnotations()}
                                disabled={isSubmitting}
                                sx={{
                                    height: '37px'
                                }}
                            >
                                {
                                    isSubmitting
                                        ? <CircularProgress size={20}/>
                                        : <Box className={'hoverContainer'}>
                                            <p className="hoverHide">
                                                Submit
                                            </p>
                                            <Box className={'hoverShow'}>
                                                <div style={{minHeight: '1rem'}}>
                                                    <Kdb>
                                                        Ctrl
                                                    </Kdb>
                                                    <span style={{margin: '0 .5rem'}}>
                                                            +
                                                        </span>
                                                    <Kdb>
                                                        Enter
                                                    </Kdb>
                                                </div>
                                            </Box>
                                        </Box>
                                }

                            </Button>

                        </Grid>
                    </>
                }
            />
            <Snackbar
                open={toast.open}
                autoHideDuration={6000}
                onClose={closeToast}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Alert onClose={closeToast} severity="error" sx={{width: '100%'}}>
                    {toast.message}
                </Alert>
            </Snackbar>
            {
                (detections.length > 0 && !isLoadingDetections)
                    ? <>

                        <CardGrid
                            annotations={annotations}
                            detections={detections}
                            layout={layout}
                            ref={gridRef}
                            showChangeDid={canDidBeChanged}
                            mode={mode}
                            placementOptions={placementOptions}
                            refreshedSpeed={refreshedSpeed}
                        />


                    </>

                    :
                    <LinearProgress/>
            }

            <DebugModal

                annotations={annotations}
                detections={detections}
                open={showDebug}
                setOpen={setShowDebug}
            />
        </Box>
    )
}

GridContainer.propTypes = {
    annotations: PropTypes.array,

}


export default GridContainer;
