import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Container,
    Typography,
    Link,
} from '@mui/material';

function Copyright() {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            sx={{
                pl: 4,
            }}
        >
            {'Copyright © '}
            <Link color="inherit" href="https://visua.com/">
                Visua
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



function Footer(
    {}
) {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                textAlign: 'left',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
            }}
        >
                <Copyright />
        </Box>
    )
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer;
