import React, {useState} from 'react';
import {
    Skeleton,
    Tooltip,
    Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import {useCacheStore} from "../../../contexts";

const DEFAULT_DARK_BG = '#565656';

function AnnotationCardInfo(
    {
        detection = {},
        bgColor,
    }
) {
    function textToBrandId(text) {
        // Use a regular expression to match the brand ID and text
        const match = text.match(/(.+) \(brand_id=(\d+)\)$/);

        // Check if the match was successful
        if (!match) {
            return [null, text];
        }

        try {
            // Extract the brand ID and the text before the parentheses
            const [, extractedText, brandId] = match;
            return [parseInt(brandId, 10), extractedText];
        } catch (e) {
            return [null, text];
        }
    }

    const [imageHasLoaded, setImageHasLoaded] = useState(false);
    const {
        brands,
    } = useCacheStore();

    const {
        logoVersion,
        visualClassId,
        detectionSettingId,
        visualStruct,
        mediaLabel,
        mediaDetectionMeta,
    } = detection;

    let thumbImgUrl = '',
        tooltipFirstRow = '',
        tooltipSecondRow = '',
        tooltipThirdRow = '',
        metaText = '',
        mediaLabelContent;

    const isLogo = !!logoVersion


    if (isLogo) {
        const {
            thumbUrl,
            brand
        } = logoVersion;

        thumbImgUrl = thumbUrl ?? ''
        if (brand) {
            const {name, industry} = brand;
            tooltipFirstRow = name ?? '';
            if (industry) {
                const {description} = industry;
                tooltipSecondRow = description ?? '';
            }
            if (!!visualClassId && !!detectionSettingId) {
                tooltipThirdRow = `${visualClassId}, ${detectionSettingId}`;
            }
        }

    } else {
        if (visualStruct) thumbImgUrl = visualStruct.thumbUrl ?? ''
        if (mediaLabel) {
            const {
                dataset,
                datasetClassCode,
                description,
            } = mediaLabel;

            mediaLabelContent = <div>
                <p className={'infoMedia-par'}>
                    <b>Dataset</b> <br/>
                    {dataset}
                </p>
                <p className={'infoMedia-par'}>
                    <b>DatasetClassCode</b> <br/>
                    {datasetClassCode}
                </p>
                <p className={'infoMedia-par'}>
                    <b>Description</b> <br/>
                    {description}
                </p>
            </div>
            tooltipFirstRow = mediaLabel.id ?? '';

        }
        if (detectionSettingId) tooltipSecondRow = detectionSettingId;
        if (mediaDetectionMeta && !!mediaDetectionMeta[0]?.meta?.text) {
            metaText = mediaDetectionMeta[0]?.meta?.text ?? '';
            const [brandId, text] = textToBrandId(metaText)
            const brandEl = brands.find(el => el.id === brandId);
            metaText = (<div style={{textAlign: 'left'}}>
                <b style={{fontWeight: 'bold'}}>Text detected: </b> <br/>
                <span style={{wordBreak: "break-all"}}>
                   {text}
                </span> <br/>
                {
                    !!brandEl && (<div style={{marginTop: '5px'}}>
                        <b style={{fontWeight: 'bold'}}>Brand: </b> <br/>
                            <span style={{wordBreak: "break-all"}}>
                                {brandEl.name}
                            </span>
                    </div>)
                }
            </div>);
        }
    }

    return (
        <Tooltip
            title={
                <div>
                    {tooltipFirstRow.length > 0 && <div>{tooltipFirstRow}</div>}
                    {tooltipSecondRow.length > 0 && <div>{tooltipSecondRow}</div>}
                    {tooltipThirdRow.length > 0 && <div>{tooltipThirdRow}</div>}

                </div>
            }
        >
            <div style={{
                height: "200px",
                padding: '0.2em',
                boxSizing: 'border-box',
                overflow: 'hidden',
                ...(imageHasLoaded ?
                    {
                        background: bgColor,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    } :
                    {})
            }}>
                {
                    !!logoVersion ?

                        <>
                            <Skeleton
                                height={200}
                                animation={'wave'}
                                variant={'rectangular'}
                                sx={{
                                    display: (imageHasLoaded || thumbImgUrl === '') ? 'none' : 'block',
                                }}
                            />
                            <img
                                id={detection.id}
                                src={thumbImgUrl}
                                alt={detection.id}
                                onLoad={() => {
                                    setImageHasLoaded(true);
                                }}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    visibility: (imageHasLoaded || thumbImgUrl === '') ? "initial" : "hidden"
                                }}
                            />


                        </>
                        :
                        <div
                            style={{
                                color: bgColor === DEFAULT_DARK_BG ? '#fff' : '#000'
                            }}
                        >
                            {mediaLabelContent ??
                                <>
                                    <div style={{marginTop: '10px'}}>
                                        This is a text detection <br/>
                                        Do NOT mark as FP <br/>
                                        Select the correct placement
                                    </div>
                                    {
                                        !!metaText && <>
                                            <hr style={{margin: '15px'}}/>
                                            <div>
                                                <p
                                                    style={{
                                                        fontSize: "14px",
                                                        wordBreak: "break-all",
                                                    }}
                                                >
                                                    {metaText}
                                                </p>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                }


            </div>
        </Tooltip>
    )
}

AnnotationCardInfo.propTypes = {
    detection: PropTypes.object,
    bgColor: PropTypes.string,
}

export default AnnotationCardInfo
