import {
    PLACEMENT_ROW_TITLE,
    NAME_ROW_TITLE,
    SAMPLED_TITLE_ROW,
    SAMPLE_ALL_THRESHOLD,
    SAMPLE_THRESHOLD_PERCENTAGE_HIGH,
    SAMPLE_THRESHOLD_PERCENTAGE_LOW,
    SAMPLE_THESHOLD_PERCENTAGE_THRESHOLD,
    SAMPLE_VALUE_UNSURE,
    SAMPLE_VALUE_MISSING,
} from '../data/constants'


/*
    This will compute the key. 

    It's crossing placement and name
*/
const computeKey = row => `${row[PLACEMENT_ROW_TITLE]}${row[NAME_ROW_TITLE]}` 

/*
    This decides if a row should be sampled or not

    A row should be sampled if its index is between 0 and thresold.
    Threshold is the minimum or either the floor (SAMPLE_ALL_THRESHOLD) or 
        a proportion of the total (SAMPLE_THREESHOLD_PERCENTAGE)
       
    In addition, the sample should be spread. For example, with 
        total = 30 and SAMPLE_ALL_PROPORTION = 10, it should 
        select one out of three
*/
const shouldBeSampled = (n, total, sampled) =>  {
    return (total < sampled) ||
    (n % Math.floor(total / sampled)) === 0;
}

const exclusionList = ['unsure', 'missing', 'Missing', 'Unsure']
const forcedExclusion = row => {
    const exclusion =  (
        exclusionList.indexOf(row[PLACEMENT_ROW_TITLE]) !== -1 ||   
        exclusionList.indexOf(row[NAME_ROW_TITLE]) !== -1
    )
    return exclusion;
}

/*
    This is the sampling algorithm
    
    This will select row for review in /review page. It will 
    cross Placement and Name and sample X items for each combinaison
*/
export const sample = data => {
    const total = data.reduce(
        (counts, row) => {
            const key = computeKey(row);
            return {
                ...counts,
                [key]: (counts?.[key] || 0) + 1
            }
        },
        {}  
    )
    const { rows } = data.reduce(
        ( ({ rows, counts } , row) => {
            const key = computeKey(row) 
            const totalOf = total[key];
            const percentageThreshold = data?.length > SAMPLE_THESHOLD_PERCENTAGE_THRESHOLD ? 
                SAMPLE_THRESHOLD_PERCENTAGE_LOW : 
                SAMPLE_THRESHOLD_PERCENTAGE_HIGH;
            const percentageThresholdAbsolute = totalOf * percentageThreshold;  
            const sampled = percentageThresholdAbsolute > SAMPLE_ALL_THRESHOLD? percentageThresholdAbsolute : SAMPLE_ALL_THRESHOLD 
            return {
                counts: { ...counts, [key]: (counts?.[key] || 0) + 1},
                rows: [...rows, {
                    ...row,
                    [SAMPLED_TITLE_ROW]: !forcedExclusion(row) && shouldBeSampled(counts?.[key] || 0, totalOf, sampled )
                }]
            } 
        })
    , { counts: {}, rows: []})
    return rows
}