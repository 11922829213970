import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Box,
    CardActions,
    TextField,
} from '@mui/material';
import AnnotationBBimage from '../AnnotationBBimage/AnnotationBBimage'
import {useUserStore} from "../../../contexts";
import ThreeDotsMenu from "../ThreeDotsMenu/ThreeDotsMenu";

function OcrCard(
    {
        annotation,
        detection,
        index,
        isSelected,
        openFullSize,
        setSelectedCard,
        updateAnnotations,
        setPreview,
        openFullImage,
    }
) {
    const ocrInput = useRef();
    const [
        txtValue, setTxtValue
    ] = useState(annotation.text??  detection.mediaDetectionMeta[0].meta.text)
    const {
        boundingBoxColor,
        cardBackground,
    } = useUserStore();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            updateAnnotations(index, 'text',txtValue)
            // Send Axios request here
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [txtValue])

    useEffect(_ => {
        if (isSelected) {
            if (ocrInput.current) ocrInput.current.focus();
        }
    }, [isSelected])

    useEffect(_ => {
        if (typeof annotation.text === "undefined") {
            updateAnnotations(index, 'text', detection.mediaDetectionMeta[0].meta.text);
        }
    }, [
        updateAnnotations,
        detection.mediaDetectionMeta,
        annotation,
        index,
    ])

    return (
        <Card
            raised={true}
            sx={{
                border: "2px solid",
                borderColor: isSelected ? 'primary.main' : 'transparent',
            }}
            onClick={_ => {
                setSelectedCard(index);
            }}
        >
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    height: "200px",
                }}
            >
                <AnnotationBBimage
                    media={annotation}
                    boxCoordinates={detection.meta.dets_bb || detection.meta.dets_bb_src}
                    index={index}
                    bgColor={cardBackground}
                    boxColor={boundingBoxColor}
                    openImageInModal={openFullSize}
                    setPreview={setPreview}
                    openFullImage={openFullImage}
                />
            </Box>
            <CardActions
                sx={{
                    display: "grid",
                    gridTemplateColumns: "9fr 1fr",
                    gridTemplateRows: "1fr",
                    gridColumnGap: "10px",
                }}
            >
                <Box>
                    <TextField
                        hiddenLabel
                        fullWidth
                        autoFocus={index === 0}
                        inputRef={ocrInput}
                        id={`ocr-input-${index}`}
                        className="ocr-input"
                        value={ txtValue }
                        variant="standard"
                        onChange={(event) => setTxtValue(event.target.value)}
                        placeholder={'No text inside the box'}
                    />
                </Box>
                <Box>
                    <ThreeDotsMenu
                        cardId={detection.id}
                        info={annotation.info}
                        />
                </Box>
            </CardActions>
        </Card>
    )
}

OcrCard.propTypes = {}

OcrCard.defaultProps = {}

export default OcrCard;
