import React, { useState, useEffect, useMemo } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { ReviewApp as ReviewAppComponent } from '../components/ReviewApp';
import { ReviewAppErrorInsights } from '../components/ReviewApp';
import { ReviewAppTypeCheck } from '../components/ReviewApp/ReviewAppTypeCheck';
 
import {
    FINAL_CHECK_LABEL,
    PLACEMENT_CHECK_LABEL,
    LOGO_CHECK_LABEL,
    TYPE_CHECK_LABEL, 
    ERROR_CHECK_LABEL,
} from '../helpers/data/constants'
import { ReviewTableProvider } from '../contexts/reviewTableContext';
import { QueueNameProvider } from '../contexts/queueNameContext';

const tabs = [FINAL_CHECK_LABEL, ERROR_CHECK_LABEL, PLACEMENT_CHECK_LABEL, LOGO_CHECK_LABEL, TYPE_CHECK_LABEL]

const ReviewAppRaw = () => {
    const [currentTab, setCurrentTab] = useState(0) 
    return <ReviewTableProvider>
        <Tabs value={currentTab} onChange={(e, v) => setCurrentTab(v)} centered>
            {tabs.map(tab => <Tab label={tab} />)}
        </Tabs> 
        {   tabs[currentTab] === FINAL_CHECK_LABEL ? 
                <ReviewAppComponent />:
            tabs[currentTab] === TYPE_CHECK_LABEL ? 
                <ReviewAppTypeCheck />:
                <ReviewAppErrorInsights currentTab={tabs[currentTab]} />
        }

    </ReviewTableProvider>  
}

const ReviewApp = (...props) => <QueueNameProvider>
    <ReviewAppRaw {...props} />
</QueueNameProvider>

export { ReviewApp }; 