import * as React from 'react';
import {
    Autocomplete,
    TextField,
    createFilterOptions,
    Box,
    Popper, Chip,
    Tooltip, styled, tooltipClasses,
} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogosImageList from "./LogosImageList/LogosImageList";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'transparent',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: 'none',
        fontSize: 11,
    },
}));


function AutocompleteLogo(
    {
        backToSpeedDial,
        selectedBrand,
        setSelectedBrand,
        brands,
        inputRef,
        index,
        hasSpeedDial = false,
    }
) {
    const PopperMy = React.useCallback((props) => {
        const anchorEl = document.getElementById(`input-logo-${index}`);   // Or any other element
        return <Popper
            {...props}
            anchorEl={anchorEl}
            style={{
                width: !!anchorEl? anchorEl.parentElement?.clientWidth : null,
            }}
            placement='bottom'
        />;
    }, []);

    return (
        <Autocomplete
            filterSelectedOptions={false}
            disableListWrap={true}
            limitTags={3}
            PopperComponent={PopperMy}
            filterOptions={createFilterOptions({ limit: 25 })}
            disablePortal
            openOnFocus
            value={selectedBrand}
            onChange={(event, newValue) => {
                if (newValue?.id === -1) backToSpeedDial()
                else setSelectedBrand(newValue);
            }}
            options={[
                ...(hasSpeedDial)? [{
                    id: -1,
                    name: 'Show suggestions',
                }] : [],
                ...brands,
            ]}
            getOptionLabel={
                (option) => {
                    return `${option.name} ${option.aliases?.join(' ')}`;
                }
            }
            fullWidth={true}
            renderOption={(props, option) => {
                const summaryLogosLength = option?.logoVersions?.length ?? 0 - 2;
                const summaryPopperColumn = Math.ceil(summaryLogosLength / 3)
                const summaryPopperHeight = 155 * summaryPopperColumn;

                if (option.id === -1) {
                    return (
                        <Box
                            component="li"
                            sx={{
                                m: 0,
                                p: '0 4px !important',
                                '& > *': {
                                    width: '50%',
                                }
                            }}
                            {...props}
                        >
                            <Box
                                sx={{
                                    background: '#FF00FF',
                                    color: '#fff',
                                }}
                            >
                                <b>
                                    Show suggestions
                                </b>
                            </Box>
                            <Box
                                sx={{
                                    ml: 'auto',
                                    textAlign: 'right',
                                    p: '4px 0 !important',
                                }}
                            >
                                <SettingsBackupRestoreIcon />
                            </Box>
                        </Box>
                    )
                }

                return (
                    <Box
                        component="li"
                        sx={{
                            m: 0,
                            p: '0 4px !important',
                            '& > *': {
                                width: '50%',
                            }
                        }}
                        {...props}
                    >
                        <Box>
                            {option.name}
                        </Box>

                        <Box
                            sx={{
                                ml: 'auto',
                                textAlign: 'right',
                                p: '4px 0 !important',
                                '&  img': {
                                    width: '50px',
                                    aspectRatio: '3/2',
                                    objectFit: 'contain',
                                    maxHeight: "100%",
                                },
                            }}
                        >
                            {
                                option.logoVersions.slice(0,2).map( (logo, i) =>
                                    <Tooltip
                                        placement={'right'}
                                        title={
                                        <div
                                            style={{
                                                background: `url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
                                                margin: '0 2px',
                                                display: 'inline-block',
                                                padding: '4px',
                                            }}
                                        >
                                            <img key={i} src={logo.iconUrl} alt=""/>
                                        </div>
                                    }
                                             key={i}
                                    >

                                        <div
                                            style={{
                                                background: `url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
                                                margin: '0 2px',
                                                display: 'inline-block',
                                                padding: '4px',
                                            }}
                                        >
                                            <img  src={`${logo.iconUrl}`} alt=""/>
                                        </div>
                                    </Tooltip>
                                )
                            }
                            <Box
                                sx={{
                                    margin: '0 2px',
                                    display: 'inline-block',
                                    padding: '4px',
                                }}
                            >
                                {
                                    option.logoVersions.length > 2 ?
                                        <LightTooltip
                                            placement={'right'}
                                            PopperProps={{
                                                sx: {
                                                    width: 500,
                                                    height: summaryPopperHeight,
                                                }
                                            }}
                                            title={
                                                <Box
                                                    sx={{
                                                        width: 500,
                                                        height: summaryPopperHeight,
                                                        background: 'white',
                                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                                        p: 1,
                                                    }}
                                                >
                                                    <LogosImageList
                                                        interactive={false}
                                                        logos={
                                                            option.logoVersions.slice(2)
                                                        }
                                                    />
                                                </Box>
                                            }
                                        >
                                            <Chip
                                                label={`${option.logoVersions.length - 2 > 9 ? '+' : ''}
                                        ${Math.min( option.logoVersions.length - 2, 9)}
                                        `}
                                            />
                                        </LightTooltip>
                                        :
                                        <ChevronRightIcon />
                                }

                            </Box>

                        </Box>

                    </Box>
                )
            }}
            renderInput={(params) =>
                <Box>
                    <TextField
                        {...params}
                        className="class-selector-input mouseFlow-input"
                        variant="filled"
                        size="small"
                        hiddenLabel
                        placeholder={'Select brand'}
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: 'mouseFlow-input'
                            }
                        }}
                        inputRef={inputRef}
                    />
                </Box>

            }
        />
    );
}


AutocompleteLogo.propTypes = {}

AutocompleteLogo.defaultProps = {}

export default AutocompleteLogo;
