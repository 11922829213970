import * as React from 'react';
import PropTypes from 'prop-types';
import {useRef, useState, useEffect} from "react";
import FullSizeImage from "../../components/Annotations/FullScreenMode/FullSizeImage";
import FullSizeSideBar from "../../components/Annotations/FullScreenMode/FullSizeSizeBar";
import FullSizeImageTools from "../../components/Annotations/FullScreenMode/FullSizeImageTools";

import {
 Box,
} from "@mui/material";
import {useUserStore} from "../../contexts";

function SingleCardGrid(
    {
        annotation = {},
        detection = {},
        mode,
        index = 0,
        datasetOptions = [],
        updateAnnotations,
        totals = 9,
    }
) {
    const [control, setControl] = useState('move');
    const [highlight, setHighlight] = useState(true);
    const [rotation, setRotation] = useState(0);
    const [imgPosition, setImgPosition] = useState({
        x: 0,
        y: 0,
    });
    const [zoomLevel, setZoomLevel] = useState(1);
    const contentModalRef = useRef(null);
    const {
        boundingBoxColor,
        cardBackground,
    } = useUserStore();

    let coordinates = [];
    if (!!detection) {
        const {
            meta
        } = detection;
        if (!!meta) {
            coordinates = meta.dets_bb || meta.dets_bb_src;
        }
    }

    useEffect(_ => {
        setControl('move');
        setHighlight(true);
        setRotation(0);
        setZoomLevel(1);
        setImgPosition({
            x: 0,
            y: 0,
        })
    }, [annotation])


    const cursorType = _ => {
        switch (control) {
            case 'move':
                return 'grab';
            case 'zoomIn':
                return 'zoom-in';
            case 'zoomOut':
                return 'zoom-out';
            case 'reset':
            case 'rotate':
                return 'alias';
        }
    }

    return (
        <Box
            container={true}
            sx={{
                height: '100%',
                display: 'flex',
                flexWrap: 'wrap'
            }}
        >
            <Box
                item
                sx={{
                    width: '6%',
                    background: 'white'
                }}
            >
                <FullSizeImageTools
                    control={control}
                    setControl={setControl}
                    highlight={highlight}
                    setHighlight={setHighlight}

                />
            </Box>
            <Box
                sx={{
                    cursor: cursorType(),
                    height: "calc(100vh - 200px)",
                    width: '70%',
                    border: '1px solid black'
                }}
                ref={contentModalRef}
            >
                    <FullSizeImage
                        activeDetection={highlight}
                        background={ cardBackground}
                        boxColor={boundingBoxColor}
                        control={control}
                        coordinates={coordinates}
                        imagePosition={imgPosition}
                        parentRef={contentModalRef}
                        rotation={rotation}
                        setImagePosition={setImgPosition}
                        setRotation={setRotation}
                        setZoomLevel={setZoomLevel}
                        src={annotation.mediaAnnotateUrl ?? ''}
                        zoomLevel={zoomLevel}
                    />

            </Box>
            <Box
                sx={{
                    width: '24%',
                }}
            >
                <FullSizeSideBar
                    annotation={annotation}
                    bgColor={cardBackground}
                    datasetOptions={datasetOptions}
                    detection={detection}
                    index={index}
                    mode={mode}
                    totals={totals}
                    updateAnnotations={updateAnnotations}
                />
            </Box>
        </Box>
    )
}

SingleCardGrid.propTypes = {}

SingleCardGrid.defaultProps = {}

export default SingleCardGrid;
