import axios from "axios";

export const username= process?.env?.REACT_APP_AUTO_LOGIN_USERNAME;
export const password = process?.env?.REACT_APP_AUTO_LOGIN_PASSWORD;

export const http = process?.env?.NODE_ENV === 'development' ?
    axios.create({
        auth: {
            username: process.env.REACT_APP_AUTO_LOGIN_USERNAME,
            password: process.env.REACT_APP_AUTO_LOGIN_PASSWORD,
        },
        withCredentials: true,
    }):
    axios.create({
        baseURL: "https://api.visua.com/",
        headers: {
            "Content-type": "application/json",
        },
        withCredentials: true,
    });

