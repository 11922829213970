import React, {useMemo, useCallback} from 'react';

import { ToastContainer, toast } from 'react-toastify'

import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';

import { CORRECTION_FILTER_CORRECT, CORRECTION_FILTER_WRONG_PLACEMENT, PLACEMENT_ROW_TITLE, PROPAGATION_TITLE_ROW, STATUS_LABEL_CHECKED, STATUS_TITLE_ROW } from 'helpers/data/constants';
import { useQueryString, useLoadAnnotations } from '../../hooks';

import { useLocalStorage } from 'usehooks-ts'

import { DataGridPremium } from '@mui/x-data-grid-premium';

import {
    Box,
} from "@mui/material";


import {
   SAMPLED_TITLE_ROW,
   CORRECTION_STATUS_TITLE_ROW,
   STATUS_LABEL_UNTOUCHED,
   STATUS_LABEL_CORRECTED,
   CHECKED_TITLE_ROW,
   CORRECTION_FILTER_BOTH,
   CORRECTION_TITLE_ROW,
   PLACEMENT_ID_TITLE_ROW,
   INSIGHT_TABLE_ROW_HEIGHT,
   TYPE_TITLE_ROW,
   NAME_ROW_TITLE,
   ANNOTATION_TYPE_ANNOTATION,
   ANNOTATION_TYPE_PROPAGATION,
   ANNOTATION_TYPE_TOTAL ,
   PLACEMENT_CHECK_LABEL,
   CORRECTION_FILTER_WRONG_LOGO,
   QUEUE_NAME_URL_ARG,
   SAMPLED_RATE_ROW,
   CHECKED_RATE_ROW,
   ERROR_RATE_ROW,
   ERROR_CHECK_LABEL,
} from '../../helpers/data/constants'


export const isToBeFixed = row => row[SAMPLED_TITLE_ROW] > 50 ? row[ERROR_RATE_ROW] > 0.045: row[ERROR_RATE_ROW] > 0.09;

const TOTAL_ROW_TITLE = "Total";
const CORRECT_ROW_TITLE = "Correct";
const UNTOUCHED_TITLE_ROW = "Untouched";

const INITIAL_COLUMNS_VISIBILITY_MODEL = {
   "Placement": true,
   "Sampled": true,
   "Untouched": false,
   "Correction": false,
   "Correct": false,
   "Sampled (%)": false
 } 

const INITIAL_FILTER_MODEL = {
   items: []
} 

const INITIAL_SORT_MODEL = [
]


export const RateRenderer = ({ value, color }) => {
   return <span>{`${value * 100}`.slice(0, 5)}%</span>
}

const AnnotationTypeRenderer = ({ value }) => {
    if(value === ANNOTATION_TYPE_TOTAL) {
        return <strong style={{ fontWeight: 'bold'}}>{ANNOTATION_TYPE_TOTAL}</strong>
    } else {
        return <>{value}</>
    }
}

const PlacementIdRenderer = ({ value }) => {
   const onClick = async () => {
      const id = value(); 
      await navigator.clipboard.writeText(id);
      toast.info(`Placement ID ${id} copied to clipboard!`);
   }
   return <div onClick={onClick} style={{ cursor: 'pointer' }}><ContentPasteGoIcon /></div>
}

const getCriteria = (currentTab) => {
   const criteriaTitle = currentTab === PLACEMENT_CHECK_LABEL?
      PLACEMENT_ROW_TITLE: 
   currentTab === ERROR_CHECK_LABEL? 
      PLACEMENT_ROW_TITLE: 
      NAME_ROW_TITLE
     
   const wrongCriteriaTitle = currentTab === PLACEMENT_CHECK_LABEL?
      CORRECTION_FILTER_WRONG_PLACEMENT:
   currentTab === ERROR_CHECK_LABEL?
      CORRECTION_FILTER_BOTH:
      CORRECTION_FILTER_WRONG_LOGO
   console.log({ wrongCriteriaTitle, criteriaTitle, currentTab })

  return  { criteriaTitle, wrongCriteriaTitle }
}

const computeRow = (currentTab, rows, criteria, type) => {
   

   const { criteriaTitle, wrongCriteriaTitle } = getCriteria(currentTab);

   if(!type) {

         const allRows = [
            computeRow(currentTab, rows, criteria, ANNOTATION_TYPE_ANNOTATION),
            computeRow(currentTab, rows, criteria, ANNOTATION_TYPE_PROPAGATION),
            computeRow(currentTab, rows, criteria, ANNOTATION_TYPE_TOTAL)
         ]     

         return allRows

      } 
     
      const isPropagation = row => !row?.annotator_email

      const fromCriteria = rows
            ?.filter(row => row[criteriaTitle] === criteria)

      const filtered = type === ANNOTATION_TYPE_TOTAL ? 
            fromCriteria : 
         type === ANNOTATION_TYPE_PROPAGATION? 
            fromCriteria.filter(isPropagation):
         type === ANNOTATION_TYPE_ANNOTATION? 
            fromCriteria.filter(row => !isPropagation(row)) : 
            null;
   
      console.log('filtered', filtered);

      const total = filtered?.length;

      const wrongCriteriaRow = row => 
               row[CORRECTION_STATUS_TITLE_ROW] === wrongCriteriaTitle ||
               row[CORRECTION_STATUS_TITLE_ROW] === CORRECTION_FILTER_BOTH
   
      const wrongCriteriaRowCombined = row => 
         row[CORRECTION_STATUS_TITLE_ROW] === CORRECTION_FILTER_WRONG_LOGO || 
         row[CORRECTION_STATUS_TITLE_ROW] === CORRECTION_FILTER_WRONG_PLACEMENT || 
         row[CORRECTION_STATUS_TITLE_ROW] === CORRECTION_FILTER_BOTH;
   
      const { sampled, untouched, corrected, checked, corrects } = filtered.reduce(({ sampled, untouched, checked, corrected, corrects }, row) => ({
         sampled: sampled + (row[SAMPLED_TITLE_ROW] ? 1 : 0),
         untouched: untouched + (row[STATUS_TITLE_ROW] === STATUS_LABEL_UNTOUCHED ? 1 : 0),
         checked: checked + 
            (row[STATUS_TITLE_ROW] === STATUS_LABEL_CHECKED || row[STATUS_TITLE_ROW] === STATUS_LABEL_CORRECTED ? 1 : 0),
         corrected: corrected + 
            (row[STATUS_TITLE_ROW] === STATUS_LABEL_CORRECTED ? 1 : 0),
         corrects: corrects + 
            (row[CORRECTION_STATUS_TITLE_ROW] === CORRECTION_FILTER_CORRECT ? 1 : 0),
      }), { sampled: 0, untouched: 0, corrected: 0, checked: 0, corrects: 0 });


      const errors = filtered
            ?.filter(currentTab === ERROR_CHECK_LABEL ? wrongCriteriaRowCombined : wrongCriteriaRow)
            ?.length

      const NaNtoZero = n => isNaN(n) ? 0: n;
      const sampledRate  = NaNtoZero(sampled / total);
      const checkedRate = NaNtoZero(checked / sampled);
      const errorRate = NaNtoZero(errors / checked);

      const findPlacementId = placement => {
         // TODO: put back what's going on there
         const found = rows.find( row => row[PLACEMENT_ROW_TITLE] === placement)
         const placementId = found?.[PLACEMENT_ID_TITLE_ROW];
         return placementId;
      }

      return {
         id: `${criteria}-${type}`,
         [TYPE_TITLE_ROW]: type, 
         [criteriaTitle]: criteria,
         [PLACEMENT_ID_TITLE_ROW]: () => findPlacementId(criteria),
         [TOTAL_ROW_TITLE]: filtered?.length,
         [SAMPLED_TITLE_ROW]: sampled,
         [UNTOUCHED_TITLE_ROW]: untouched,
         [CHECKED_TITLE_ROW]: checked,
         [CORRECTION_TITLE_ROW]: corrected,
         [CORRECT_ROW_TITLE]: corrects,
         [wrongCriteriaTitle]: errors,
         [SAMPLED_RATE_ROW]: sampledRate, 
         [CHECKED_RATE_ROW]: checkedRate,
         [ERROR_RATE_ROW]: errorRate,
         
      }
}

const unique = array => [...new Set(array)]

export const computeAggregatedRows = (currentTab, rows) => {

   console.log('computeAggregatedRows', currentTab)
   const { criteriaTitle } = getCriteria(currentTab);

   const result =  
      rows && unique(
         rows
            ?.map(row => row[criteriaTitle])
            ?.filter(criteria => !!criteria)
      )
      ?.map(criteria => computeRow(currentTab, rows, criteria))
      ?.reduce( (array, that) => (
         [...array, ...that]   
      ), [])

   console.log('returning from computeAggregatedRows', result)
   return result;
}


export const ReviewAppErrorInsights = ({ currentTab }) => {

   const { criteriaTitle, wrongCriteriaTitle } =  getCriteria(currentTab);
   const [queueName] = useQueryString(QUEUE_NAME_URL_ARG)
   const { loading, error, rows } = useLoadAnnotations(queueName);
   
    const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage(
        `${currentTab}ColumnVisibilityModel`,
        INITIAL_COLUMNS_VISIBILITY_MODEL
    )
   
    const [filterModel, setFilterModel] = useLocalStorage(
        `${currentTab}FilterModel`,
        INITIAL_FILTER_MODEL 
    )

    const [sortModel, setSortModel] = useLocalStorage(
        `${currentTab}SortModel`,
        INITIAL_SORT_MODEL
    )

   const numberFields = [
         TOTAL_ROW_TITLE,
         SAMPLED_TITLE_ROW,
         UNTOUCHED_TITLE_ROW,
         CHECKED_TITLE_ROW,
         CORRECTION_TITLE_ROW,
         CORRECT_ROW_TITLE,
         wrongCriteriaTitle
   ].map(field => ({
         field: field,
         headerName: field,
         type: 'number',
         align: 'center',
         headerAlign: 'center'
   })) 
   
   const placementIdRow = (currentTab === PLACEMENT_CHECK_LABEL || currentTab === ERROR_CHECK_LABEL)? 
      [{
         field: PLACEMENT_ID_TITLE_ROW,
         headerName: PLACEMENT_ID_TITLE_ROW,
         type: 'number',
         align: 'center',
         headerAlign: 'center',
         renderCell: PlacementIdRenderer
      }]: []

   const columns = [
      {
         field: TYPE_TITLE_ROW,
         headerName: TYPE_TITLE_ROW,
         type: 'singleSelect',
         valueOptions: [
             ANNOTATION_TYPE_ANNOTATION,
             PROPAGATION_TITLE_ROW, 
             TOTAL_ROW_TITLE
         ],
         renderCell: AnnotationTypeRenderer ,

      },
       {
               field: criteriaTitle,
               headerName: criteriaTitle,
               type: 'string',
       },      
      ...placementIdRow,
      ...numberFields,
      { 
         field: SAMPLED_RATE_ROW , 
         headerName: SAMPLED_RATE_ROW, 
         renderCell: RateRenderer ,
         align: 'center',
         headerAlign: 'center'
      },
      { 
         field: CHECKED_RATE_ROW , 
         headerName: CHECKED_RATE_ROW, 
         renderCell: RateRenderer ,
         align: 'center',
         headerAlign: 'center'
      },
      { 
         field: ERROR_RATE_ROW , 
         headerName: ERROR_RATE_ROW, 
         renderCell: RateRenderer ,
         align: 'center',
         headerAlign: 'center'
      },
   ]
   
  
//   }, [rows, criteriaTitle, wrongCriteriaTitle, findPlacementId])
  
   const options = {
      getRowProps: row => {
         console.log('setRowProps')
         return {}   
      }
      /*
      setRowProps: row => {
         return {
            style: row[TYPE_TITLE_ROW] === ANNOTATION_TYPE_TOTAL? {
               backgroundColor: 'grey'
            } : {backgroundColor: 'green'}
         }
      } 
      */
   }

   const aggregatedRows = useMemo(() => computeAggregatedRows(currentTab, rows)  , [currentTab, rows])
   
   

   const getRowClassName = ({row}) => {
      if(row[TYPE_TITLE_ROW] === ANNOTATION_TYPE_TOTAL) {
         if(isToBeFixed(row)) {
            return 'row-highlight-total-error'
         }
         return 'row-highlight-total'
     }
      if(isToBeFixed(row)) {
         return 'row-highlight-error'
      }
      return ''
   }
   
   return <div>
      <ToastContainer />
      {loading? <em>Loading</em> : 
            error ? <em>{error}</em>:
            rows ? <Box sx={{ width: '100%' , height: 'calc(100vh - 64px)'}}>
               <DataGridPremium 
               columnVisibilityModel={columnVisibilityModel} 
               onColumnVisibilityModelChange={setColumnVisibilityModel}
               filterModel={filterModel}
               onFilterModelChange={setFilterModel} 
               sortModel={sortModel}
               onSortModelChange={setSortModel} 
               rows={aggregatedRows} 
               columns={columns} 
               pagination={false} 
               options={options}
               rowHeight={INSIGHT_TABLE_ROW_HEIGHT} 
               getRowClassName={getRowClassName}
            /></Box> : <em>Nothing to show</em>}

   </div> 
}