import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Popover,
    Button,
    Typography,
    TextField,
    ImageList,
    ImageListItem,
} from "@mui/material"
import Kdb from "../../../Kbd/Kdb";
function LogosImageList(
    {
        logos = [],
        interactive = true,
        onLogoClick,
    }
) {

    return (
        <ImageList
            sx={{
                width: 500,
                height: 155 * Math.ceil(logos.length / 3),
            }}
            cols={3}
            rowHeight={120}
        >
            {
                logos.map( (logo, index) => (
                    <ImageListItem
                        key={logo.id}
                        onClick={(event) => {
                            onLogoClick(event, logo)
                        }}
                        sx={{
                            background: `url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
                            p: 1,
                            m: "2px",
                            position: 'relative',
                            "> img": {
                                maxHeight: '100%',
                                objectFit: 'contain !important',
                                aspectRatio: '3/2',

                            },
                            ...(
                                interactive? {
                                    ":hover": {
                                        outline: '2px solid #1DB8E0',
                                    },
                                } : {}
                            )
                        }}
                    >
                        <img
                            src={logo.iconUrl}
                            alt={`Logo Brand ${index + 1}`}
                            loading={'lazy'}
                        />
                        {
                            index < 9 &&
                            <Box
                                position={'absolute'}
                                bottom={4}
                                right={4}
                            >
                                <Kdb>
                                    {index + 1}
                                </Kdb>
                            </Box>
                        }

                    </ImageListItem>
                ))
            }
        </ImageList>
    )
}

LogosImageList.propTypes = {}

export default LogosImageList
