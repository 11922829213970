
import { useEffect, useState, useCallback, useMemo, useContext } from 'react';

import { getReviewCorrections, addReviewCorrection } from 'DataService';
import { parse } from 'papaparse';

import {
    CORRECTION_TITLE_ROW,
    STATUS_TITLE_ROW,
    CORRECTION_STATUS_TITLE_ROW,
    QUERY_HASH_ID_SUB_STRING,
   STATUS_LABEL_CHECKED,
   STATUS_LABEL_UNTOUCHED,
   STATUS_LABEL_CORRECTED ,
   CORRECTION_FILTER_BOTH,
   CORRECTION_FILTER_CORRECT,
   CORRECTION_FILTER_UNTOUCHED,
   CORRECTION_FILTER_WRONG_LOGO,
   CORRECTION_FILTER_WRONG_PLACEMENT,
   TYPE_TITLE_ROW,
   ANNOTATION_TYPE_ANNOTATION,
   ANNOTATION_TYPE_PROPAGATION,
   NAME_ROW_TITLE, 
} from '../helpers/data/constants'

import { sample } from '../helpers/algos/sampling'
import { ReviewTableContext } from '../contexts/reviewTableContext';
import { extractLastAnnotation } from 'helpers/algos/extractLastAnnotation';

const targetURL = process?.env?.REACT_APP_REVIEW_CSV_FILE_URL;


/*
    This will be use in custom logic require to filter on "Correction" column
*/
const getCorrectionFilterValue = correction => {
   const { logoError, placementError } = correction || {}; 
   return !correction ? 
            CORRECTION_FILTER_UNTOUCHED : 
        logoError && placementError? 
            CORRECTION_FILTER_BOTH: 
        logoError? 
            CORRECTION_FILTER_WRONG_LOGO:
        placementError?
            CORRECTION_FILTER_WRONG_PLACEMENT:
            CORRECTION_FILTER_CORRECT 
}


export const useLoadAnnotations = (queueName) => {

    const  {
        rows, setRows,
        loading, setLoading,
        error, setError,
        columnsHeads, setColumnsHeads
    } = useContext(ReviewTableContext)


    const updateRow = useCallback((row, update) => {

        const history = [
            {
                annotatorType: update.adminMode? 1: 0,
                logoVersionError: update.logoError,
                placementError: update.placementError,

            },
            ...(row[CORRECTION_TITLE_ROW]?.history || []),
        ];

        const result= {
            ...row,
            [CORRECTION_TITLE_ROW]: {
                ...row[CORRECTION_TITLE_ROW], 
                correction: update,
                history
            },
            [STATUS_TITLE_ROW]: computeStatus(update),
            [CORRECTION_STATUS_TITLE_ROW]: getCorrectionFilterValue(update),
            "History": history
        }

        return result;
    }, []);
    /*
        This will apply to the correction to the data and call the API
    */
    const onCorrect = useCallback(async (update) => {
        
        await addReviewCorrection(update, queueName)

        setRows(rows => {
            return rows.map(row => `${row.iann_id}` === `${update.iann_id}` ? 
                updateRow(row, update): row)
            } 
        );

    }, [setRows, updateRow, queueName])


    const computeRows = useCallback((data, corrections, onCorrect, history) => {

        if(!data || !corrections) {
            return null
        }

        const columnsHeads = data?.[0] 

        // TODO rewrite using flowRight
        const findCorrection = (corrections, id) => corrections?.find( ({ iann_id }) => `${iann_id}` === `${id}`)
        const findHistory  = (history, iann_id) => history.filter( ({ mediaDetectionId}) => `${mediaDetectionId}` === `${iann_id}`).map(row => row.annotations)[0];

        const isPropagation = row => !row?.annotator_email

        return data && sample(data?.slice(1).map( row =>  
            columnsHeads?.reduce( (previous, column, i) => 
                ({ [column]: row[i], ...previous })
            , {})
        )
        .map( row => {  
            const targetCorrection = findCorrection(corrections, row.iann_id);
            const targetHistory = findHistory(history, row.iann_id);

            return {
                ...row, 
                id: row.iann_id,
                [CORRECTION_TITLE_ROW]: {
                    onCorrect,
                    correction: targetCorrection,
                    iann_id: row.iann_id,
                    history: targetHistory,
                    row 
                },
                [STATUS_TITLE_ROW]: computeStatus(targetCorrection),
                [CORRECTION_STATUS_TITLE_ROW]: getCorrectionFilterValue(targetCorrection),
                [TYPE_TITLE_ROW]: isPropagation(row) ? ANNOTATION_TYPE_PROPAGATION: ANNOTATION_TYPE_ANNOTATION,
                "History" : targetHistory
            }
         })
        .filter( ({ id }) => id))

    }, [])

    const [reloading, setReloading] = useState();
    /*
        This effects loads the CSV file from queuename
    */
    const reloadData = useCallback( (reload) => {
       
        ( async () => {
            if(queueName) {
                const setStatus = reload ? setReloading: setLoading;
                
                setStatus(true) 
    
                try { 
                    
                    const response = await fetch(targetURL.replace(QUERY_HASH_ID_SUB_STRING, queueName))  
                    const data = await response.text()
    
                    const { data: parsed } = await parse(data, {
                        columns: true,
                        skip_empty_lines: true
                    });

                    const history = await getReviewCorrections(queueName);

                    const corrections = history.map(extractLastAnnotation)
                    setRows(computeRows(parsed, corrections, onCorrect, history))                    

                    const columnsHeads = parsed?.[0] 
                    setColumnsHeads(columnsHeads) 

                }
                catch (error) {
                    setError(error)   
                } 
                finally {
                    setStatus(false)
                }
    
            }

        })()

    }, [queueName, setRows, setLoading, computeRows, onCorrect, setError, setColumnsHeads])
    
    useEffect(() => {
        reloadData();
        /*
            * This enable pooling
            
            const id = setInterval(reloadData, 60 * 1000);
            return () => clearInterval(id);
        */
    }, [reloadData])

    /*
        This will render the status bar
    */
    const computeStatus = correction => {
        const { logoError, placementError } = correction || {};
        return correction === undefined? 
                STATUS_LABEL_UNTOUCHED:
            logoError || placementError?
                STATUS_LABEL_CORRECTED:
                STATUS_LABEL_CHECKED;
    }
        
    
    return { loading, reloading, error, rows, onCorrect, columnsHeads }

}