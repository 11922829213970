import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Grid,
    Tooltip,
    TextField,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    Box,
    Typography,
    Tabs, Tab,
} from "@mui/material";
import Kdb from "../../Kbd/Kdb";
import AnnoCardInfo from "../AnnoCard/AnnoCardInfo";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {toast} from "react-toastify";
import PlacementSelector from "../PlacementSelector";


function Info(
    {
        title,
        index,
        mode,
        detection,
        bgColor,
        totals,
    }
) {

    const [tabValue, setTabValue] = useState(0)
    const {
        logoVersion,
        visualClassId,
        detectionSettingId,
        visualStruct,
        mediaLabel,
    } = detection;

    let thumbImgUrl = '',
        detectionName = '',
        detectionDescription = '',
        detectionGroup = '',
        mediaLabelContent;

    if (!!detection) {
        if (!!logoVersion) {
            const {thumbUrl, brand} = logoVersion;
            thumbImgUrl = thumbUrl ?? ''
            if (brand) {
                const {name, industry} = brand;
                detectionName = name ?? '';
                if (industry) {
                    const {description} = industry;
                    detectionDescription = description ?? '';
                }
                if (!!visualClassId && !!detectionSettingId) {
                    detectionGroup = `${visualClassId}, ${detectionSettingId}`;
                }
            }
        } else {
            if (visualStruct) thumbImgUrl = visualStruct.thumbUrl ?? ''
            if (mediaLabel) {
                const {
                    dataset,
                    datasetClassCode,
                    description,
                } = mediaLabel;

                mediaLabelContent = <div>
                    <p className={'infoMedia-par'}>
                        <b>Dataset</b> <br/>
                        {dataset}
                    </p>
                    <p className={'infoMedia-par'}>
                        <b>DatasetClassCode</b> <br/>
                        {datasetClassCode}
                    </p>
                    <p className={'infoMedia-par'}>
                        <b>Description</b> <br/>
                        {description}
                    </p>
                </div>
                detectionName = mediaLabel.id ?? '';

            }
            if (detectionSettingId) detectionDescription = detectionSettingId;
        }
    }
    const copyIdToClipboard = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText(title).then(function () {
            toast.success(
                "Id copied to clipboard!",
                {
                    position: "bottom-right",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                })
        });
    }



    return (
        <Card>
            <CardHeader
                sx={{
                    pb: 0,
                    "& .MuiCardHeader-subheader": {
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        lineHeight: 1.43,
                        letterSpacing: "0.01071em",
                    }
                }}
                title={
                    <Button
                        onClick={(e) => {
                            copyIdToClipboard(e)
                        }}
                        variant={'text'}
                        sx={{
                            textTransform: 'none',
                        }}
                    >
                        <ContentCopyIcon
                            fontSize={'small'}
                            sx={{
                                mr: 1,
                            }}
                        />
                        {title}
                    </Button>
                }
                subheader={`n°${index + 1} of ${totals}`}
            />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue}
                      onChange={(event, newValue) => {
                          setTabValue(newValue);
                      }}
                      aria-label="Information Detection Page">
                    <Tab label="Logo"  />
                    <Tab label="Details" />
                    {!!detection.info &&
                        <Tab label="Infos"/>
                    }
                </Tabs>
            </Box>
            {
                tabValue === 0 &&
                <Box
                    sx={
                        {
                            height: "200px",
                            padding: '5px',
                            background: bgColor,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }
                    }
                >
                    <img
                        id={detection.id}
                        src={thumbImgUrl}
                        alt={detection.id}
                        title={detection.id}
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                        }}
                    />
                </Box>
            }
            {
                tabValue === 1 &&
                <Box
                    sx={{
                        m: 1,
                        '> *': {
                            m: 1,
                        },
                        'b' : {
                            fontWeight: 'bold',
                        }
                }}
                >
                        {
                            detectionName.length > 0 &&
                            <Box

                            >
                                <b>Name</b>: {detectionName}
                            </Box>
                        }
                        {detectionDescription.length > 0 &&
                            <Box>
                                <b>Description</b>: {detectionDescription}
                            </Box>
                        }
                        {detectionGroup.length > 0 &&
                            <Box>
                                <b>Groups</b>: {detectionGroup}
                            </Box>
                        }
                </Box>
            }
            {
                tabValue === 2 &&
                 <Box
                    sx={{
                        m: 2,
                    }}
                 >
                     {detection.info}
                 </Box>
            }
        </Card>
    )
}


function Actions(
    {
        annotation,
        datasetOptions,
        detection,
        index,
        mode,
        updateAnnotations,
    }
) {
    let initialInputValue =  '';
    let optionInitialValue = {};

    if (!!annotation.contexts) {
        const optionSelected = datasetOptions.find(el => el.id === annotation.contexts);
        if (!!optionSelected) {
            initialInputValue = annotation.contexts.title;
            optionInitialValue = optionSelected;
        }
    }

    const [placementText, setPlacementText] = useState(initialInputValue);
    const [placementValue, setPlacementValue] = useState(optionInitialValue);
    const [tabValue, setTabValue] = useState(0);

    const ocrInput = useRef();
    const placementInput = useRef();

    const initOcrValue = mode !== 'ocr'
        ? ''
        : annotation.text??  detection.mediaDetectionMeta[0].meta.text;

    const [ocrTextValue, setOcrTextValue] = useState(initOcrValue);

    useEffect(_ => {
        if (mode === 'ocr') {
            const OcrValue = annotation.text??  detection.mediaDetectionMeta[0].meta.text;
            setOcrTextValue(OcrValue);
            if (ocrInput.current) ocrInput.current.focus();
        }

        if (mode === 'placement') {
            if (!!annotation.contexts) {
                const optionSelected = datasetOptions.find(el => el.id === annotation.contexts);
                setPlacementText(annotation.contexts.title)
                setPlacementValue(optionSelected)
            } else {
                setPlacementText('')
                setPlacementValue({})
            }

            if (placementInput.current) placementInput.current.focus();
        }

    }, [annotation])


    switch (mode) {
        case 'ynp':
            return (
                <Card
                    sx={{
                        mt: 2,
                    }}
                >
                    <CardContent>
                    <Grid
                        container={true}
                    >
                        <Grid item={true} xs={12}>
                            <Typography
                                variant={'h6'}
                            >
                                Approve
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={4}>
                            <Tooltip
                                title={
                                    <div style={{minHeight: '1rem'}}>
                                        <Kdb>
                                            Y
                                        </Kdb>
                                    </div>
                                }
                            >
                                <Button
                                    fullWidth
                                    onClick={() => updateAnnotations(index, 'approve', 1)}
                                    color="success"
                                    variant={annotation.approve === 1 ? "contained" : "text"}
                                >
                                    Yes
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item={true} xs={4}>
                            <Tooltip
                                title={
                                    <div style={{minHeight: '1rem'}}>
                                        <Kdb>
                                            P
                                        </Kdb>
                                    </div>
                                }
                            >
                                <Button
                                    fullWidth
                                    onClick={() => updateAnnotations(index, 'approve', 0.5)}
                                    color="warning"
                                    variant={annotation.approve === 0.5 ? "contained" : "text"}
                                >
                                    Perhaps
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item={true} xs={4}>
                            <Tooltip
                                title={
                                    <div style={{minHeight: '1rem'}}>
                                        <Kdb>
                                            N
                                        </Kdb>
                                    </div>
                                }
                            >
                                <Button
                                    fullWidth
                                    onClick={() => updateAnnotations(index, 'approve', 0)}
                                    color="error"
                                    variant={annotation.approve === 0 ? "contained" : "text"}
                                >
                                    No
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
            )
        case 'ocr':
            return (
                <TextField
                    sx={{
                        mt: 2,
                    }}
                    inputRef={ocrInput}
                    hiddenLabel
                    fullWidth
                    autoFocus={true}
                    id={`ocr-input-${index}`}
                    className="ocr-input"
                    value={ ocrTextValue }
                    variant="standard"
                    onChange={
                        (event) => {
                            setOcrTextValue(event.target.value);
                            updateAnnotations(index, 'text', event.target.value);
                        }
                    }
                    placeholder={'No text inside the box'}
                />
            )
        case 'placement':
            return (
                <Card
                    sx={{
                        mt: 2,
                    }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue}
                              onChange={(event, newValue) => {
                                  setTabValue(newValue);
                              }}
                              aria-label="Placement Selections Tabs"
                        >
                            <Tab label="Placements"  />
                            <Tab label="Logo" />
                        </Tabs>
                    </Box>
                    <CardContent>
                    <PlacementSelector
                        index={index}
                        value={placementValue}
                        onChange={(e, value) => {
                            setPlacementValue(value);
                            updateAnnotations(index, 'contexts', value.id);
                        }}
                        options={datasetOptions}
                        placementRef={placementInput}
                        inputText={placementText}
                        setInputText={setPlacementText}
                    />
                    </CardContent>
                </Card>
            )
        default:
            return (
                <Box sx={{
                    my: 4,
                }}>
                    The actions for this use case are not ready yet in full screen mode.
                </Box>
            )
        break;
    }
}


function FullSizeSideBar(
    {
        annotation,
        bgColor,
        datasetOptions,
        detection,
        index = 0,
        mode,
        totals,
        updateAnnotations,
    }
) {

    return (
        <Box
            sx={{
                margin: '1rem',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Info
                title={detection.id}

                mode={mode}
                detection={detection}
                bgColor={bgColor}
                index={index}
                totals={totals}
            />
            <Actions
                annotation={annotation}
                datasetOptions={datasetOptions}
                detection={detection}
                index={index}
                mode={mode}
                updateAnnotations={updateAnnotations}
            />
        </Box>
    )

}

FullSizeSideBar.propTypes = {}

export default FullSizeSideBar;
