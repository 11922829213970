import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';

import ClearIcon from '@mui/icons-material/Clear';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import { green, red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';

const ColoredSwitch = styled(Switch)(({ theme, active }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color:  green[600],
      '&:hover': {
        backgroundColor: active ? green[600] : red[600]
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: green[600],
    },
    '& .MuiSwitch-track': {
      backgroundColor: red[600]
    },
    '& .MuiButtonBase-root': {
      color: red[600]
    },
  }));
/* 
    This will render the correction bar
*/
  
export const CorrectionToolbar = ({ value, editable }) => {
  const [loading, setLoading] = useState(false);
  /* 
      The row has been corrected if there's a value
      This will show the buttons if it's touched or if there's no correction yet
  */ 
  const { correction, onCorrect, iann_id, row } = value || {}; 
  const { logoError, placementError } = correction || {}; 
  const corrected = !!correction;

  const textDetection = row['Class ID'] === 104022;
  
  const [logoErrorValue, setLogoErrorValue] = useState(!logoError);
  const logoErrorTouched = logoErrorValue !== !logoError;

  const [placementErrorValue, setPlacementErrorValue] = useState(!placementError);
  const placementErrorTouched =  placementErrorValue !== !placementError;

  useEffect(() => {
      setLogoErrorValue(!logoError)
      setPlacementErrorValue(!placementError) 
  }, [logoError, placementError])

  const touched = placementErrorTouched || logoErrorTouched 
  const showValidButton = touched || !corrected;
  const showResetButton = touched

  const reset = () => {
      setLogoErrorValue(!logoError)
      setPlacementErrorValue(!placementError)
  }

  const correct = async () => {
      setLoading(true)

      await onCorrect({
          iann_id,
          logoError: logoErrorValue? 0: 1,
          placementError: placementErrorValue? 0: 1,
          reviewed: true
      }) 
      
     setLoading(false)
  }
  
  if(loading) {
      return <div><em>Loading...</em></div>
  }

  return <div> 
      <FormGroup row={true}>
          <FormControlLabel control={
              <ColoredSwitch 
                  active={logoErrorValue}
                  checked={logoErrorValue}
                  
                  onChange={e => {
                      setLogoErrorValue(!logoErrorValue)
                  } 
                  }
                  inputProps={{ 'aria-label': 'controlled' }} 
                  disabled={!editable && !textDetection}
              />
          } label="Logo" />
          <FormControlLabel control={
              <ColoredSwitch 
                  active={placementErrorValue}
                  checked={placementErrorValue}
                  onChange={e => {
                      setPlacementErrorValue(!placementErrorValue)
                  }}
                  inputProps={{ 'aria-label': 'controlled' }} 
                  disabled={!editable}
              />
          } label="Placement" />
          {
              showValidButton? 
                  <IconButton onClick={correct}>
                      <DoneIcon /> 
                  </IconButton>
              :
              null
          } 
          {
             showResetButton ?
              <IconButton onClick={reset}>
                  <ClearIcon /> 
              </IconButton> : null
          }           
      </FormGroup>

  </div>  
} 

