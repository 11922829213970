const hyperlinkCorrect = value => {
  return `${value}`.indexOf('HYPERLINK') !== -1?
    value.replaceAll('"', '""'): 
    value;
}

export const useToCSV = () => (rows, headers) => {
  console.log('useToCSV', rows[0], headers)
    const csvContent = [
        headers.join(','),
        ...rows.map(row =>
          headers.map(header => {
            const value = typeof row[header] === 'object' ? "" : row[header]
            return `"${hyperlinkCorrect(value)}"`
          }
          ).join(',')
        )
    ].join('\n');

  console.log('csvContent', csvContent)
    return csvContent;    
}
