import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    IconButton,
    Tooltip,
} from "@mui/material";

function DrawingToolAction(
    {
        actionName,
        Icon,
        tooltipText,
        setControl,
        control,
        onClick,
        disabled,
    }
) {
    return (
        <Tooltip
            title={tooltipText}
            placement={'right'}
        >
            <div
                style={{
                    width: '100%'
                }}
            >
            <Button
                disabled={disabled}
                fullWidth={true}
                onClick={ (e) => {
                    if (!!onClick){
                        onClick(e, actionName)
                    }
                    else {
                        setControl(actionName)
                    }
                }}
                sx={{
                    background: control === actionName ? '#1DB8E0' : '#8092A6'
                }}
                aria-label={tooltipText}
                variant={'contained'}
            >
                <Icon stroke={'#fff'} />
            </Button>
            </div>
        </Tooltip>
    )
}

DrawingToolAction.propTypes = {
    actionName: PropTypes.string,
    tooltipText: PropTypes.string,
    setControl: PropTypes.func,
    control: PropTypes.string,
    onClick: PropTypes.func,
}

DrawingToolAction.defaultProps = {
    actionName: '',
    tooltipText: 'Add a tooltip Text please',
    setControl: null,
    control: null,
    onClick: null,
}

export default DrawingToolAction;
