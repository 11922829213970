import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Autocomplete,
    TextField,
    Box,
    Card,
    CardContent,
    CardHeader,
    Stack,
    Grid,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

function DebugModal(
    {
        annotations,
        detections,
        open,
        setOpen,
    }
) {
    return (
        <Dialog
            open={open}
            onClose={(e) => {
                setOpen(false)
            }}
            maxWidth={false}
        >
            <DialogTitle>
                Debug Modal
            </DialogTitle>
            <DialogContent>
                <Grid container={true}>

                    {
                        annotations.map((el, index) => {
                            return (
                                <Grid
                                    xs={4}
                                    key={index}
                                    item
                                >
                                    <Card
                                        sx={{
                                            m: 1,
                                        }}
                                    >

                                        <CardHeader
                                            title={
                                                <Button
                                                    variant={'text'}
                                                    sx={{
                                                        fontSize: '0.8rem',
                                                        textTransform: 'none',
                                                    }}
                                                >
                                                    <ContentCopyIcon
                                                        fontSize={'small'}
                                                        sx={{
                                                            mr: 1,
                                                        }}
                                                    />
                                                    {el.id}
                                                </Button>
                                            }
                                        />
                                        <CardContent sx={{px: 2, py: 0, pb: '4px !important'}}>
                                            <Stack>
                                                {
                                                    !!el.visualClassId &&
                                                    <Grid container={true}>
                                                        <Grid xs={4} item>
                                                            <strong
                                                                style={{fontWeight: 'bold', marginRight: 8,}}
                                                            > visualClassId
                                                            </strong>
                                                        </Grid>
                                                        <Grid item>
                                                           <span>
                                                               {el.visualClassId}
                                                           </span>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {
                                                    !!el.receiptHandle &&
                                                    <Grid container={true}>
                                                        <Grid xs={4} item>
                                                            <strong
                                                                style={{fontWeight: 'bold', marginRight: 8,}}
                                                            >
                                                                receiptHandle
                                                            </strong>
                                                        </Grid>
                                                        <Grid>
                                                           <span>
                                                                {el.receiptHandle.slice(0,30)}...
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                <Box>
                                                    <Grid container={true}>
                                                        <Grid xs={4} item>
                                                            <strong
                                                                style={{fontWeight: 'bold', marginRight: 8,}}
                                                            >
                                                                mediaAnnotateUrl
                                                            </strong>
                                                        </Grid>
                                                        <Grid item>
                                                            {
                                                                !!el.mediaAnnotateUrl
                                                                    ?
                                                                    <a
                                                                        target={'_target'}
                                                                        href={el.mediaAnnotateUrl}
                                                                        style={{
                                                                            alignItems: "center",
                                                                            display: 'inline-flex',
                                                                        }}
                                                                    >
                                                                        <CheckIcon
                                                                            color={'success'}
                                                                            sx={{
                                                                                fontSize: '1rem',
                                                                                mr: 1,
                                                                            }}
                                                                        />
                                                                        Present
                                                                    </a>
                                                                    :
                                                                    <span>
                                                                   <CloseIcon color={'error'}/>
                                                                </span>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

DebugModal.propTypes = {}

DebugModal.defaultProps = {}

export default DebugModal;
